import React from 'react';
import PropTypes from 'prop-types';

import { LABEL_SIZE_XXLARGE, LABEL_SIZE_XLARGE, LABEL_SIZE_LARGE, LABEL_SIZE_REGULAR, LABEL_SIZE_SMALL } from 'utils/constants';

import FormLabel from 'components/FormLabel/FormLabel';

import { StyledFormItem } from './FormItem.styles';

const addRules = (requiredErrorMessage, extraRules) => {
  const rules = [];

  if (!!requiredErrorMessage) {
    rules.push({
      required: true,
      message: requiredErrorMessage
    });
  }

  return [...rules, ...extraRules];
};

const FormItem = ({
  className,
  labelClassName,
  noStyle,

  name,
  label,
  labelSize,
  requiredErrorMessage,
  extraRules,

  children,

  ...props
}) => {
  const rules = addRules(requiredErrorMessage, extraRules);

  return (
    <>
      {!!label && (
        <FormLabel labelSize={labelSize} className={labelClassName} hasRequiredErrorMessage={!!requiredErrorMessage} {...props}>
          {label}
        </FormLabel>
      )}
      <StyledFormItem className={className} noStyle={noStyle} name={name} colon={false} rules={rules}>
        {children}
      </StyledFormItem>
    </>
  );
};

FormItem.propTypes = {
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  noStyle: PropTypes.bool,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  labelSize: PropTypes.oneOf([LABEL_SIZE_XXLARGE, LABEL_SIZE_XLARGE, LABEL_SIZE_LARGE, LABEL_SIZE_REGULAR, LABEL_SIZE_SMALL, '']),
  requiredErrorMessage: PropTypes.string,
  extraRules: PropTypes.array,
  children: PropTypes.node.isRequired
};

FormItem.defaultProps = {
  className: '',
  labelClassName: '',
  noStyle: false,
  label: undefined,
  labelSize: LABEL_SIZE_REGULAR,
  requiredErrorMessage: '',
  extraRules: []
};

export default FormItem;
