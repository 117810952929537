import styled from '@emotion/styled';
import { Modal } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import BareImageCarousel from '../BareImageCarousel/BareImageCarousel';

export const StyledModal = styled(Modal)`
  width: 100vw !important;

  @media (min-width: 1024px) {
    width: 60vw !important;
  }
`;

export const StyledCloseButton = styled(CloseOutlined)`
  margin-left: 20px;
  cursor: pointer;
`;

export const StyledImageCarouselContainer = styled.div`
  @media (min-width: 768px) {
    height: 75vh;
  }
`;

export const StyledBareImageCarousel = styled(BareImageCarousel)`
  height: 40vh;

  img {
    height: auto;
  }

  @media (min-width: 768px) {
    height: 75vh;
  }

  @media (min-width: 1024px) {
    img {
      height: 100%;
      width: auto;
    }
  }
`;
