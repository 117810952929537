import styled from '@emotion/styled';
import { Button, Menu, Row } from 'antd';
import ClickableTextButton from 'components/ClickableTextButton/ClickableTextButton';

export const MainContainer = styled.div`
  width: 100%;
  height: ${props => props.sticky && '144px'};

  @media (min-width: 992px) {
    height: ${props => props.sticky && '188px'};
  }
`;

export const NavContainer = styled.div`
  height: 60px;
  padding: 4%;
  display: ${props => (props.sticky ? 'none' : 'flex')};
  align-items: center;
  justify-content: space-between;
  background-color: ${props => props.theme.color.white};

  @media (min-width: 768px) {
    padding: 0 4%;
  }
`;

export const NavButton = styled(ClickableTextButton)`
  margin-bottom: 0px;
  margin-right: ${props => props.theme.spacing.sm};
  color: ${props => props.theme.color.paragraphFontColor};
`;

export const StyledLogo = styled.img`
  max-height: 60px;
  width: 70%;
`;

export const StyledTab = styled.span`
  cursor: pointer;
  font-size: 16px;
  line-height: 32px;
`;

export const MenuItem = styled(Menu.Item)`
  padding: ${props => props.theme.spacing.regular};
`;

export const AvatarButton = styled(Button)`
  background-color: ${props => props.theme.color.primaryFontColor};
  border-radius: 50%;
  border: none;
  box-shadow: 0px 3px 8px ${props => props.theme.color.white}33;
  color: ${props => props.theme.color.white};
  font-size: 12px;
  font-weight: ${props => props.theme.fontWeight.bold};
  padding: 1px 7px 2px;
  height: 40px;
  width: 40px;
  cursor: pointer;
`;

export const NavButtonContainer = styled(Row)`
  align-items: center;
`;

export const MobileNavWrapper = styled(Row)`
  align-items: center;

  @media (min-width: 768px) {
    display: none;
  }

  ul {
    width: 100%;
    position: absolute;
    background-color: white;
    left: 0;
    top: ${props => (props.open === true ? '55px' : '-205px')};
    color: black;
    padding: 20px;
    z-index: 99;

    .ant-row {
      margin: 10px;
    }
  }
`;

export const DesktopNavWrapper = styled(Row)`
  align-items: center;
  display: none;

  @media (min-width: 768px) {
    display: flex;
  }
`;

export const StyledAnchor = styled.a`
  color: ${pros => pros.theme.color.paragraphFontColor};

  && :hover {
    color: ${pros => pros.theme.color.paragraphFontColor};
  }
`;
