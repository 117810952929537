import React from 'react';
import { Col, Row } from 'antd';

import Card from 'components/Card/Card';
import TextSecondary from 'components/Font/TextSecondary/TextSecondary';
import Image from 'components/Image/BareImage/BareImage';
import SectionDivider from 'components/SectionDivider/SectionDivider';
import Subtitle from 'components/Font/Subtitle/Subtitle';
import PropertyInfoTags from 'components/PropertyInfoTags/PropertyInfoTags';

import HostAStayLogo from 'images/hostastay-logo-with-white-background.png';
import DeRantauLogo from '../../../images/MD-De-Rantau.png';

import { AmenitiesContainer, ContentCol, ImageCarouselCol, StyledImageCarousel, StyledSubtitle } from './PropertyInfo.styles';

const Amenities = ({ propertyAmenities }) => {
  return (
    <>
      <AmenitiesContainer>
        <Subtitle type="md">Amenities</Subtitle>
      </AmenitiesContainer>
      <Card>
        <TextSecondary>{propertyAmenities.join(', ')}</TextSecondary>
      </Card>
    </>
  );
};

const PropertyInfo = ({ propertyDetail }) => {
  const {
    displayName,
    propertyAmenities,
    propertyType,
    address,
    hostImage,
    images,
    isAllowInstantBooking,
    isPremium,
    isHoliStayPromo,
    isPromo,
    isDerantauCertified
  } = propertyDetail;

  return (
    <Row type="flex" align="top" gutter={[0, 8]}>
      <ImageCarouselCol span={24} md={12} lg={10}>
        <StyledImageCarousel images={images} alt={displayName} hasGallery />
        {isDerantauCertified && (
          <Image image={DeRantauLogo} width={'60px'} height={'40px'} isAbsolute={true} top={'18px'} right={'18px'} alt="DE Rantau Badge" />
        )}
      </ImageCarouselCol>
      <ContentCol span={24} md={12} lg={14}>
        <Row gutter={[0, 8]}>
          <Col span={24}>
            <StyledSubtitle type="md">{displayName}</StyledSubtitle>
          </Col>
          <Col span={24}>
            <PropertyInfoTags
              propertyType={propertyType}
              isAllowInstantBooking={isAllowInstantBooking}
              isPremium={isPremium}
              isHoliStayPromo={isHoliStayPromo}
              isPromo={isPromo}
            />
          </Col>
          <Col span={24}>
            <TextSecondary type="s" newLine={true}>
              {address}
            </TextSecondary>
          </Col>
          {hostImage && (
            <Col span={24}>
              <Image image={hostImage || HostAStayLogo} alt="Host logo" height="80px" />
            </Col>
          )}
        </Row>
      </ContentCol>
      {propertyAmenities && propertyAmenities.length > 0 && (
        <Col span={24}>
          <SectionDivider />
          <Amenities propertyAmenities={propertyAmenities} />
        </Col>
      )}
    </Row>
  );
};

export default PropertyInfo;
