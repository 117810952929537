import styled from '@emotion/styled';
import bgImage from '../../images/background.png';
const LAYOUT_TYPE_ACCOUNT = 'memberAccountLayout';
const LAYOUT_TYPE_MDEC = 'mdecLayout';

export const StandardLayoutContainer = styled.div`
  background: ${props => (props.type === LAYOUT_TYPE_MDEC ? '#FAFAFA' : '#FFF')};
`;

export const StyledBody = styled.div`
  min-height: 80vh;
  width: 100%;
  padding: 0 ${props => (props.type === LAYOUT_TYPE_ACCOUNT || props.type === LAYOUT_TYPE_MDEC ? '0px' : props.theme.spacing.sm)};

  @media (min-width: 768px) {
    width: ${props => (props.type === LAYOUT_TYPE_ACCOUNT ? '100%' : '80%')};
    margin: 0 auto;
  }
`;

export const StyledFooter = styled.div`
  min-height: 8vh;
  display: flex;
  align-items: center;
  padding: 0 4%;
  background-color: ${props => props.theme.color.backgroundColor};
`;

export const StyledBgImage = styled.div`
  background-image: url(${bgImage});
  background-position: 13px 13px;
  background-size: 200px;
  background-repeat: no-repeat;
  background-color: ${props => props.theme.color.backgroundColor};

  @media (min-width: 768px) {
    background-position: 23px 23px;
    background-size: 25%;
  }
`;
