import styled from '@emotion/styled';
import MdecAboutBg from 'images/about-derantau-bg.png';

export const MdecAboutBackground = styled.div`
  background-image: url(${MdecAboutBg});
  background-size: cover;
  background-position-x: center;
  border-radius: 0px 120px 0px 0px;
  padding: 2rem 1rem;

  @media (min-width: 991px) {
    padding: 2rem;
  }

  @media (min-width: 1441px) {
    padding: 3rem;
  }
`;

export const StyledTitle = styled.h3`
  text-align: center;
  color: #0c20b3;
  font-weight: 700;
  font-size: 1.5rem;

  @media (min-width: 1441px) {
    font-size: 32px;
  }
`;

export const StyledTitleH4 = styled.h4`
  text-align: center;
  color: #0c20b3;
  font-weight: 700;
  font-size: 20px;

  @media (min-width: 1441px) {
    font-size: 28px;
  }
`;

export const LargePar = styled.p`
  font-size: 20px;
  text-align: left;

  @media (min-width: 991px) {
    text-align: center;
  }

  @media (min-width: 1441px) {
    font-size: 28px;
  }
`;

export const CasualPar = styled.p`
  font-size: 16px;
  text-align: left;

  @media (min-width: 991px) {
    text-align: center;
  }

  @media (min-width: 1441px) {
    font-size: 18px;
  }
`;

export const KeyAreaTitle = styled.p`
  font-size: 16px;
  text-align: center;
  font-weight: 700;

  @media (min-width: 1441px) {
    font-size: 18px;
  }
`;

export const KeyAreaImg = styled.img`
  height: 120px;

  @media (min-width: 1441px) {
    height: 260px;
  }
`;

export const NomadsContainer = styled.div`
  margin-top: 38px;

  @media (min-width: 1441px) {
    margin-top: 42px;
  }
`;

export const NomadCardContainer = styled.div`
  height: 100%;
  padding: 19px 20px;

  @media (min-width: 1441px) {
    padding: 21px 46px;
  }
`;

export const NomadCard = styled.div`
  background: #fff;
  border-radius: 50px;
  // filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.25));
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  display: inline-grid; /* or grid based on your need */
  grid-auto-rows: 1fr; /* equal height row */
  overflow: auto;
  width: 100%;
  height: 100%;
`;

export const NomadImg = styled.img`
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  height: 0;
  min-height: 100%;
  width: 100%;
  object-fit: cover;
`;

export const NomadContent = styled.div`
  padding: 18px;

  @media (min-width: 1441px) {
    padding: 32px;
  }
`;

export const NomadBtn = styled.button`
  background: #e62a32;
  border: 1px solid #e62a32;
  border-radius: 20px;
  padding: 17px 30px;
  color: white;
  font-weight: 800;
  font-size: 16px;
  cursor: pointer;

  @media (min-width: 1441px) {
    font-size: 20px;
  }
`;

export const FooterContainer = styled.div`
  background: #fff;
  width: 100vw;
  position: relative;
  left: calc(-50vw + 50%);
  padding: 50px 0;
`;

export const FooterBody = styled.div`
  width: 100%;

  @media (min-width: 768px) {
    width: 80%;
    margin: 0 auto;
  }
`;

export const InnerImage = styled.img`
  width: 100%;
  height: 232px;
  object-fit: cover;
  padding: 0.5rem 20px;
  border-radius: 50px;

  @media (min-width: 992px) {
    padding: 15px;
  }
`;

export const OuterImage = styled.img`
  width: 100%;
  height: 232px;
  object-fit: cover;
  padding: 0.5rem 20px;
  border-radius: 50px;

  @media (min-width: 992px) {
    height: 100%;
    padding: 15px;
  }
`;
