import React from 'react';
import { Row } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import { CloseButtonContainer, StyledButton, StyledCard } from './Card.styles';

const CloseButton = ({ onClick }) => (
  <StyledButton onClick={onClick}>
    <CloseButtonContainer>
      <CloseOutlined />
    </CloseButtonContainer>
  </StyledButton>
);

const Card = ({ children, onClose, title, ...props }) => (
  <StyledCard
    title={
      (title && onClose && (
        <Row type="flex" justify="space-between">
          <span>{title}</span>
          <CloseButton onClick={onClose} />
        </Row>
      )) ||
      title
    }
    {...props}
  >
    {!title && onClose && (
      <Row type="flex" justify="space-between">
        <CloseButton onClick={onClose} />
      </Row>
    )}
    {children}
  </StyledCard>
);

export default Card;
