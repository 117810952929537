import React from 'react';

import { MainContainer, LinkContainer, LinkText } from './Footer.styles';

const Footer = () => {
  return (
    <>
      <MainContainer>
        <span>HostAStay © {new Date().getFullYear()} All Rights Reserved</span>
        <LinkContainer>
          <LinkText to="/privacy-policy">
            <span>Privacy & Policy</span>
          </LinkText>
          ●
          <LinkText to="/terms-conditions">
            <span>Terms & Condition</span>
          </LinkText>
        </LinkContainer>
      </MainContainer>
    </>
  );
};

export default Footer;
