import styled from '@emotion/styled';
import { Row } from 'antd';

export const NavButton = styled(Row)`
  color: ${props => (props.visualVisible ? props.theme.color.secondary : props.theme.color.primary)};
  cursor: pointer;
  height: 32px;
  margin-bottom: ${props => props.theme.spacing.regular};
`;

export const IconContainer = styled.span`
  display: block;
  margin-right: ${props => props.theme.spacing.xs};
`;

export const Text = styled.span`
  ${props =>
    props.visualVisible
      ? `
    border-bottom: 1px solid ${props.theme.color.secondary};
    color: ${props.theme.color.secondary};
    font-weight: ${props.theme.fontWeight.bold};
  `
      : `
  &:hover {
    margin-bottom: -1px;
    border-bottom: 1px solid ${props.theme.color.primary};
  }
  `}
`;
