import styled from '@emotion/styled';
import { Col, Row } from 'antd';

export const PackageRow = styled(Row)`
  box-shadow: 0 1px 2px 0 #00000012, 0 2px 2px 0 #0000001a;
  border-radius: 6px;
  padding: ${props => props.theme.spacing.regular};
`;

export const PackageColForNameAndCode = styled(Col)`
  border-right: 1px solid #f0f0f0;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  padding-bottom: ${props => props.theme.spacing.xs};
`;

export const PackageColDivider = styled.div`
  background-color: #f0f0f0;
  height: 1px;
`;

export const LogoCol = styled(Col)`
  text-align: center;
`;

export const PackageNameLabel = styled.p`
  font-size: 20px;
  line-height: 24px;
  font-weight: ${props => props.theme.fontWeight.bold};
  color: ${props => props.theme.color.primary};
  margin-bottom: 0px;
`;

export const PackageCodeLabel = styled.p`
  font-size: 16px;
  line-height: 24px;
  font-weight: ${props => props.theme.fontWeight.bold};
  color: ${props => props.theme.color.secondary};
  margin-bottom: 0px;
`;

export const PackageDetailsRow = styled(Row)`
  border-top: 2px solid #f0f0f0;
`;

export const PackageInfoCol = styled(Col)`
  border-right: 1px solid #f0f0f0;
  padding-top: ${props => props.theme.spacing.xs};
`;

export const PackageNightLeftCol = styled(Col)`
  text-align: center;
  padding-top: ${props => props.theme.spacing.xs};
`;
