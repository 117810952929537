import api from './apiHelper';

const PACKAGE_ORDER_ROUTE = '/booking-engine/packageOrder';

/* ---------------------------------- GET ---------------------------------------- */
export const getPackageOrder = packageCode => {
  return api.get(`${PACKAGE_ORDER_ROUTE}/`, { params: { packageCode } });
};

export const getPackageCodeValidation = (packageOrder, propertyId, nightsRedeemed, endDate) => {
  return api.get(`${PACKAGE_ORDER_ROUTE}/${packageOrder}/validate`, { params: { propertyId, nightsRedeemed, endDate } });
};
