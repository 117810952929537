import React from 'react';

import { BannerContainer, Column, TextCol } from '../Terms.styles';
import Subtitle from 'components/Font/Subtitle/Subtitle';

const Content = () => {
  return (
    <BannerContainer justify="start">
      <ol>
        <li>
          <TextCol>
            Package(s) are valid as per the stated date. Package(s) not used within this time will be forfeited with no entitlement to refund or
            exchange. It is not possible to extend the voucher beyond the stated validity period.
          </TextCol>
        </li>
        <li>
          <TextCol>All booking must be made in advance.</TextCol>
        </li>
        <li>
          <TextCol>Booking made with vouchers is not refundable in cash.</TextCol>
        </li>
        <li>
          <TextCol>Vouchers are not refundable in cash or replaceable if lost, destroyed, or stolen.</TextCol>
        </li>
        <li>
          <TextCol>All vouchers must be presented by the bearer on arrival at the hotel and must be mentioned when booking.</TextCol>
        </li>
        <li>
          <TextCol>Any remaining amount is not exchangeable for cash or another voucher and will be automatically forfeited.</TextCol>
        </li>
        <li>
          <TextCol>Please check your email for confirmation once your purchase is completed.</TextCol>
        </li>
        <li>
          <TextCol>The company reserves the right to amend these terms and conditions without prior notice.</TextCol>
        </li>
        <li>
          <TextCol>For queries relating bookings please email derantau@hostastay.com</TextCol>
        </li>
        <li>
          <TextCol>Vouchers must be presented as an original copy. Any kind of image on any media is not an acceptable redeemable form.</TextCol>
        </li>
        <li>
          {' '}
          <TextCol>No voucher will be acceptable after the valid date indicated on it.</TextCol>
        </li>
        <li>
          <TextCol>You can redeem multiple rooms for different dates.</TextCol>
        </li>
      </ol>

      <Column>
        <Subtitle>Room Guarantee</Subtitle>
        <TextCol>
          Reservations are subject to availability, at our discretion. Please call the telephone number specified on our homepage to make your
          reservation. Purchase of a package is not a guarantee of a reservation.
        </TextCol>
      </Column>

      <Column>
        <Subtitle>Payment</Subtitle>
        <TextCol>
          You will be asked to guarantee your booking with a debit or credit card including your 3-digit security code. We accept American Express,
          MasterCard, Maestro, Visa & Visa Delta.
        </TextCol>
        <TextCol>
          Please note: When booking a room on an advance purchase rate, you are required to pay for this room at the time of booking. If pre-payment
          is not required, then payment is due on departure from the hotel.
        </TextCol>
      </Column>

      <Column>
        <Subtitle>Refund Policy</Subtitle>
        <TextCol>No refund would be made.</TextCol>
      </Column>

      <Column>
        <Subtitle>Cancellation Policy</Subtitle>
        <TextCol>
          Cancellation of purchase or reschedule of booking will not be accepted. The company cannot be held liable for any travel or accommodation
          costs in relation to a cancellation and that are no rebooked or exchanges for goods and service shall be forfeited.
        </TextCol>
      </Column>

      <Column>
        <Subtitle>Force Majeure</Subtitle>
        <TextCol>
          The DE Rantau cannot accept responsibility or pay any compensation where the performance or prompt performance of the contract is prevented
          or affected by reason of circumstances which amount to “force majeure”. Circumstances amounting to “force majeure” include any event which
          we could not, even with all due care, foresee or avoid. Such circumstances include the destruction or damage of your accommodation (which
          cannot reasonably be remedied to a satisfactory standard before the start of your stay) through fire, flood, explosion, storm or other
          weather damage, break-in, criminal damage, riots or civil strife, industrial action, natural or nuclear disaster, fire, adverse weather
          conditions, war or threat of war, actual or threatened terrorist activity, epidemic and all similar situations beyond our control.
        </TextCol>
      </Column>

      <Column>
        <Subtitle>Rates</Subtitle>
        <TextCol>All rates quoted are per room per night, are subject to availability and include Value Added Cleaning Charges (MYR50).</TextCol>
      </Column>

      <Column>
        <Subtitle>Car Parking</Subtitle>
        <TextCol>
          Car parking is available subject to availability DE Rantau. Your room booking does not guarantee a parking space and if you find the car
          park is full, you will be given directions to the nearest alternative site. Parking your car is entirely at your own risk and we cannot
          accept any responsibility whatsoever for theft or damage to your vehicle or other property howsoever caused.
        </TextCol>
      </Column>

      <Column>
        <Subtitle>Mobility</Subtitle>
        <TextCol>If you have special requirements, please inform us at the time of booking.</TextCol>
      </Column>

      <Column>
        <Subtitle>Check in and Check Out</Subtitle>
        <TextCol>Check in is from 3pm and check out is 11am. Late departures will be charged at the full daily rate.</TextCol>
      </Column>

      <Column>
        <Subtitle>Smoking Policy</Subtitle>
        <TextCol>
          The DE Rantau properties are all Non – Smoking. Designated areas are provided. Please ask the hotel teams for further details upon arrival.
          Anyone found smoking in a guest room will be politely asked to stop. They will also be charged for extra cleaning of the room for subsequent
          guests.
        </TextCol>
      </Column>

      <Column>
        <Subtitle>General Terms</Subtitle>
        <ul>
          <li>The package/booking cannot be combined with any other offers or exchanged for cash or any other products in kind.</li>
          <li>DE Rantau has the right to reject any claims that do not conform to these Terms and Conditions.</li>
          <li>
            In the event DE Rantau detects unusual, irregular, suspicious, unauthorized, fraudulent activity on the purchase or redemption, DE Rantau
            shall have the right to cancel/forfeit such package/booking purchased and subsequently cancel any booking made using such package without
            any liability on the part of DE Rantau to any guest.
          </li>
          <li>
            These Terms and Conditions will prevail over any inconsistent terms, conditions, provisions or representations contained in any other
            promotional materials regarding the package/booking. In the event of any inconsistency between the terms in these Terms and Conditions and
            the DE Rantau’s Terms & Conditions, these Terms and Conditions shall prevail.
          </li>
          <li>
            DE Rantau reserves the right at its absolute discretion to vary, delete or add to any of these Terms and Conditions and/or withdraw the
            package, or substitute or replace the package with another product or program of equivalent value from time to time without any prior
            notice.
          </li>
          <li>
            By purchasing and utilizing the package, the guest agrees to be bound by these Terms and Conditions as well as DE Rantau’s Terms &
            Conditions as may be amended from time to time.
          </li>
          <li>
            The guest agrees to hold harmless, defend and fully indemnify DE Rantau from and against all losses (including loss of opportunity and
            consequential loss arising therewith), damages, expenses and all third party actions, proceedings, claims, demands, costs (including
            without prejudice to the generality of this provision, the legal cost of each other, personal injuries, death, loss or destruction of
            property, rights of publicity or privacy, defamation), awards and damages that may arise as a result of the use purchase of this Pass.
          </li>
          <li>
            These Terms and Conditions are governed by laws of Malaysia and all disputes relating to the package/booking are subject to exclusive
            jurisdiction of courts in Kuala Lumpur.
          </li>
        </ul>
      </Column>

      <Column>
        <Subtitle>Feedback</Subtitle>
        <TextCol>
          If you want to share anything about your experience with us, then we would love to hear from you. Please email to derantau@hostastay.com
        </TextCol>
      </Column>
    </BannerContainer>
  );
};

export default Content;
