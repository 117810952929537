import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';

import FormItem from 'components/FormItem/FormItem';

import { StyledFormSelection } from './FormSelection.styles';

const { Option } = Select;

const FormSelection = ({
  noStyle,
  name,
  selections,
  placeholder,
  className,
  label,

  onChange,
  onSearch,
  filterOption,

  isMultiple,
  isAllowClear,
  isDisabled,

  requiredErrorMessage,
  ...props
}) => {
  const allowClear = isAllowClear && !requiredErrorMessage;
  const searchModeProps =
    onSearch || filterOption
      ? {
          showSearch: true,
          onSearch,
          filterOption
        }
      : {};

  return (
    <FormItem noStyle={noStyle} name={name} label={label} requiredErrorMessage={requiredErrorMessage} {...props}>
      <StyledFormSelection
        className={className}
        mode={isMultiple ? 'multiple' : 'default'}
        placeholder={placeholder}
        optionFilterProp="children"
        {...searchModeProps}
        onChange={onChange}
        allowClear={allowClear}
        disabled={isDisabled}
        {...props}
      >
        {selections.map(selection => (
          <Option key={selection.value} value={selection.value}>
            {selection.label || selection.value}
          </Option>
        ))}
      </StyledFormSelection>
    </FormItem>
  );
};

FormSelection.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  selections: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
  filterOption: PropTypes.func,
  isMultiple: PropTypes.bool,
  isAllowClear: PropTypes.bool,
  isDisabled: PropTypes.bool,
  requiredErrorMessage: PropTypes.string
};

FormSelection.defaultProps = {
  placeholder: '',
  className: '',
  onChange: () => {},
  onSearch: () => {},
  filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0,
  isMultiple: false,
  isAllowClear: true,
  isDisabled: false,
  requiredErrorMessage: ''
};

export default FormSelection;
