import styled from '@emotion/styled';
import { css } from '@emotion/core';

import { LABEL_SIZE_XXLARGE, LABEL_SIZE_XLARGE, LABEL_SIZE_LARGE, LABEL_SIZE_REGULAR, LABEL_SIZE_SMALL } from 'utils/constants';

const getFontSize = props => {
  switch (props.labelSize) {
    case LABEL_SIZE_XXLARGE:
      return css`
        font-size: 40px;
        @media (min-width: 768px) {
          font-size: 40px;
        }
        @media (min-width: 1600px) {
          font-size: 64px;
        }
      `;
    case LABEL_SIZE_XLARGE:
      return css`
        font-size: 24px;
        @media (min-width: 768px) {
          font-size: 24px;
        }
        @media (min-width: 1600px) {
          font-size: 40px;
        }
      `;
    case LABEL_SIZE_LARGE:
      return css`
        font-size: 16px;
        @media (min-width: 768px) {
          font-size: 16px;
        }
        @media (min-width: 1600px) {
          font-size: 20px;
        }
      `;
    case LABEL_SIZE_REGULAR:
      return css`
        font-size: 12px;
        @media (min-width: 768px) {
          font-size: 12px;
        }
        @media (min-width: 1600px) {
          font-size: 16px;
        }
      `;
    case LABEL_SIZE_SMALL:
      return css`
        font-size: 10px;
        @media (min-width: 768px) {
          font-size: 10px;
        }
        @media (min-width: 1600px) {
          font-size: 14px;
        }
      `;
    default:
      return css`
        font-size: 12px;
        @media (min-width: 768px) {
          font-size: 12px;
        }
        @media (min-width: 1600px) {
          font-size: 16px;
        }
      `;
  }
};

export const Root = styled.p`
  margin-bottom: ${props => props.theme.spacing.xxs};
  text-align: ${props => props.align};
  font-weight: ${props => (props.bold ? 600 : 400)};
  ${getFontSize}
`;

export const RequiredAsterisk = styled.span`
  float: left;
  margin-right: ${props => props.theme.spacing.xxs};
  font-family: SimSun;
  color: ${props => props.theme.color.red};
`;
