import styled from '@emotion/styled';
import { Row, Col, Divider } from 'antd';

export const StyledCol = styled(Col)`
  padding: 7%;
  text-align: start;
`;

export const StyledDivider = styled(Divider)`
  width: 90vw;
  position: relative;
  left: calc(-30vw + 30%);
  right: calc(30vw + 30%);
`;

export const StyledRow = styled(Row)`
  display: flex;
  justify-content: flex-end;
  max-width: 400;

  div {
    width: 400px;
    max-width: 400px;
    display: flex;
    justify-content: space-between;
  }
`;

export const FooterWrapper = styled(Col)`
  margin-top: 80px;

  @media (min-width: 768px) {
    display: flex;
    justify-content: space-between;
    margin-top: 100px;
  }

  .ant-col {
    margin-top: 40px;
  }

  span {
    display: grid;
    grid-template-columns: 20px 1fr;
    gap: 10px;
    margin: 20px 0;
  }
`;

export const StyledLink = styled.p`
  font-size: 14;
  color: ${props => props.theme.color.paragraphFontColor};

  && :hover {
    color: ${props => props.theme.color.primaryFontColor};
  }

  && :first-child {
    margin-top: 20px;
  }
`;

export const StyledText = styled.p`
  font-size: 14;
  color: ${props => props.theme.color.paragraphFontColor};

  && :first-child {
    margin-top: 20px;
  }
`;
