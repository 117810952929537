import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Alert, Col, Form, Row } from 'antd';
import { KeyOutlined } from '@ant-design/icons';

import ClickableTextButton from 'components/ClickableTextButton/ClickableTextButton';
import FormInput from 'components/Input/FormInput/FormInput';

import { postResetPassword } from 'apis/auth';
import { buildBaseUri } from 'utils/routes';

import { MainContainer, TitleRow, TitleLabel, LoginRow, LoginContainer, LoginButton } from './ResetPassword.styles';

const { useForm } = Form;

const ResetPassword = () => {
  const [form] = useForm();
  const { token } = useParams();
  const history = useHistory();

  const [responseMessage, setResponseMessage] = useState('');
  const [isPasswordChanged, setIsPasswordChanged] = useState(false);
  const [isPasswordReset, setIsPasswordReset] = useState(false);

  const handleOnFormSubmitAndValidated = ({ password }) => {
    postResetPassword(token, password)
      .then(() => {
        setIsPasswordReset(true);
        setResponseMessage({ message: 'Your password has been reset! Please login with your new password in the future.', type: 'success' });
      })
      .catch(ex => {
        setResponseMessage({ message: ex.message, type: 'error' });
      });
  };

  const handleConfirmBlur = e => {
    const { value } = e.target;
    setIsPasswordChanged(isPasswordChanged || !!value);
  };

  const handleBackButtonClick = () => {
    history.push(buildBaseUri());
  };

  const compareToFirstPassword = (rule, value, callback) => {
    if (value && value !== form.getFieldValue('password')) {
      return callback('Both password are not the same');
    }
    callback();
  };

  const validateToNextPassword = (rule, value, callback) => {
    if (value && isPasswordChanged) {
      form.validateFields(['confirmPassword'], { force: true });
    }
    callback();
  };

  return (
    <MainContainer>
      <LoginRow type="flex" justify="center" align="center">
        <Col lg={10} span={20}>
          <LoginContainer>
            <TitleRow>
              <Col>
                <TitleLabel>Reset Password</TitleLabel>
              </Col>
            </TitleRow>
            <Form form={form} onFinish={handleOnFormSubmitAndValidated}>
              <FormInput
                label="New Password"
                prefix={<KeyOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="New password"
                type="password"
                name="password"
                requiredErrorMessage="Please enter your new password"
                extraRules={[{ validator: validateToNextPassword }]}
                isNewPassword
              />
              <FormInput
                label="Confirm New Password"
                prefix={<KeyOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="Confirm new password"
                type="password"
                name="confirmPassword"
                requiredErrorMessage="Please confirm your new password"
                onBlur={handleConfirmBlur}
                extraRules={[{ validator: compareToFirstPassword }]}
              />
              {responseMessage.message && <Alert message={responseMessage.message} type={responseMessage.type} />}
              <Row justify="center">
                <LoginButton htmlType="submit" type="primary" disabled={isPasswordReset}>
                  Reset my password
                </LoginButton>
                <ClickableTextButton text="Back to home page" onClick={handleBackButtonClick} />
              </Row>
            </Form>
          </LoginContainer>
        </Col>
      </LoginRow>
    </MainContainer>
  );
};

export default ResetPassword;
