import styled from '@emotion/styled';
import { Carousel as antdCarousel } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

export const StyledCarousel = styled(antdCarousel)`
  cursor: ${props => (props.hasCursor ? 'pointer' : 'default')};
`;

const StyledArrowsContainer = styled.div`
  /* to position the arrow in the middle */
  position: absolute;
  top: 50%;
  z-index: 1;
  width: 64px;
  height: 64px;
  margin-top: -32px; /* margin-top need to be 50% of the height to able to center the arrow */
  /* to position the arrow in the middle */

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background: ${props => props.theme.color.black} !important;
  opacity: 0.2;
  cursor: pointer;

  :hover {
    opacity: 0.5;
  }
`;

export const StyledLeftArrowContainer = styled(StyledArrowsContainer)`
  left: ${props => props.theme.spacing.xxs};
`;

export const StyledRightArrowContainer = styled(StyledArrowsContainer)`
  right: ${props => props.theme.spacing.xxs};
`;

export const StyledLeftArrow = styled(LeftOutlined)`
  font-size: 32px !important;
  color: ${props => props.theme.color.white} !important;
`;

export const StyledRightArrow = styled(RightOutlined)`
  font-size: 32px !important;
  color: ${props => props.theme.color.white} !important;
`;

export const StyledImageContainer = styled.div`
  background-color: ${props => props.theme.color.black};
  display: flex !important;
  align-items: center;
  justify-content: center;
`;
