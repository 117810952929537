import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { ReactQueryConfigProvider } from 'react-query';

import { AppContextProvider } from 'contexts/AppContext/AppContext';
import { UserContextProvider } from 'contexts/UserContext/UserContext';
import { TrackingContextProvider } from 'contexts/TrackingContext/TrackingContext';
import { UniversalModalContextProvider } from 'contexts/UniversalModalContext/UniversalModalContext';

import StandardLayout from 'layouts/StandardLayout/StandardLayout';

import NotFound404 from 'pages/404/NotFound404';
import ThankYou from 'pages/ThankYou/ThankYou';
import MyAccount from 'pages/MyAccount/MyAccount';
import TermsAndCondition from 'pages/TermsAndCondition/TermsAndCondition';
import PrivacyAndPolicy from 'pages/Privacy/PrivacyAndPolicy';
import AboutUs from 'pages/AboutUs/AboutUs';
import LandingPage from 'pages/LandingPage/LandingPage';
import PropertyDetail from 'pages/PropertyDetail/PropertyDetail';
import PropertyListing from 'pages/PropertyListing/PropertyListing';
import ResetPassword from 'pages/ResetPassword/ResetPassword';
import Promotion from 'pages/Promotion/Promotion';
import PromotionBanner from 'pages/Promotion/components/Banner/Banner';
import StripePayment from 'pages/StripePayment/StripePayment';

import ScrollToTop from 'components/ScrollToTop/ScrollToTop';

import {
  InnerRoute,
  build404Uri,
  buildBaseUri,
  buildMyAccountUri,
  buildThankYouUri,
  buildPropertyDetailUri,
  buildResetPasswordUri,
  buildPromotionUri,
  buildStripPaymentUri,
  buildTermsUri,
  buildPrivacyUri,
  buildAboutUri,
  buildPropertyUri,
  buildMemberActivationUri,
  buildAboutDeRantauUri
} from 'utils/routes';

import * as serviceWorker from './serviceWorker';
import './index.css';
import MemberActivation from 'pages/MemberActivation/MemberActivation';
import AboutDeRantau from 'pages/AboutDeRantau/AboutDeRantau';

const queryConfig = {
  queries: {
    retry: false,
    refetchOnWindowFocus: false,
    cacheTime: 0
  }
};

const App = () => {
  return (
    <UserContextProvider>
      <Switch>
        <InnerRoute title="Thank you for your payment" exact path={buildStripPaymentUri()}>
          <StripePayment />
        </InnerRoute>
        <InnerRoute title="Thank you for booking with us" exact path={buildThankYouUri()}>
          <StandardLayout>
            <ThankYou />
          </StandardLayout>
        </InnerRoute>
        <Route path={buildMyAccountUri()}>
          <StandardLayout type="memberAccountLayout">
            <MyAccount />
          </StandardLayout>
        </Route>
        <Route title="Terms and Condition" exact path={buildTermsUri()}>
          <StandardLayout type="memberAccountLayout">
            <TermsAndCondition />
          </StandardLayout>
        </Route>
        <Route title="Privacy and Policy" exact path={buildPrivacyUri()}>
          <StandardLayout type="memberAccountLayout">
            <PrivacyAndPolicy />
          </StandardLayout>
        </Route>
        <Route title="About Us" exact path={buildAboutUri()}>
          <StandardLayout type="memberAccountLayout">
            <AboutUs />
          </StandardLayout>
        </Route>
        <Route title="About DE Rantau" exact path={buildAboutDeRantauUri()}>
          <StandardLayout type="mdecLayout" hasMdecBanner>
            <AboutDeRantau />
          </StandardLayout>
        </Route>
        <InnerRoute title="Reset Password" exact path={buildResetPasswordUri()}>
          <ResetPassword />
        </InnerRoute>
        <InnerRoute title="Member Activation" exact path={buildMemberActivationUri()}>
          <MemberActivation />
        </InnerRoute>
        <InnerRoute title="Deals from RM 119.80 per Room Night" exact path={buildPromotionUri()}>
          <StandardLayout banner={<PromotionBanner />} hasStickyHeader>
            <Promotion />
          </StandardLayout>
        </InnerRoute>
        <StandardLayout hasStickyHeader>
          <Switch>
            <Route exact path={buildBaseUri()}>
              <LandingPage />
            </Route>
            <InnerRoute title="Search and Book with us" exact path={buildPropertyUri()}>
              <PropertyListing />
            </InnerRoute>
            <InnerRoute title="Property Information" exact path={buildPropertyDetailUri()}>
              <PropertyDetail />
            </InnerRoute>

            <Redirect from="*" to={build404Uri()} />
          </Switch>
        </StandardLayout>
      </Switch>
    </UserContextProvider>
  );
};

ReactDOM.render(
  <ReactQueryConfigProvider config={queryConfig}>
    <Router>
      <ScrollToTop />
      <AppContextProvider>
        <TrackingContextProvider>
          <UniversalModalContextProvider>
            <Switch>
              <InnerRoute title="404 - Page not found" exact path={build404Uri()}>
                <NotFound404 />
              </InnerRoute>
              <Route path={buildBaseUri()}>
                <App />
              </Route>
              <Redirect from="*" to={build404Uri()} />
            </Switch>
          </UniversalModalContextProvider>
        </TrackingContextProvider>
      </AppContextProvider>
    </Router>
  </ReactQueryConfigProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
