import qs from 'query-string';

/* ------------------------------------------------General function------------------------------------------------- */
export const checkIsObjectEmpty = object => {
  return Object.keys(object).length === 0;
};

export const checkIsStringTrue = booleanString => {
  return booleanString === true || booleanString === 'true';
};

export const checkHasValue = value => {
  return value !== undefined && value !== null;
};

export const constructDisplayNumber = (number, decimal = 2) => {
  const numberWithDecimal = number.toFixed(decimal);
  const numberWithDecimalAndComma = numberWithDecimal.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return numberWithDecimalAndComma;
};

export const getSingularOrPluralLabel = (amount = 0, singularLabel, { pluralLabel, labelOnly } = {}) => {
  const returnLabel = Number(amount) > 1 ? pluralLabel || `${singularLabel}s` : singularLabel;
  const returnAmount = labelOnly ? '' : amount;

  return `${returnAmount} ${returnLabel}`.trim();
};

export const getLabelOfConstant = (selectedConstant, constants, constantField = 'value') => {
  const foundConstant = constants.find(constant => constant[constantField] === selectedConstant);
  return foundConstant && foundConstant.label;
};

export const guard = (callback, fallbackValue) => {
  try {
    const value = callback();
    if (value === undefined || value === null) {
      return fallbackValue;
    }

    return value;
  } catch {
    return fallbackValue;
  }
};

export const constructQueryAsObject = queryString => {
  return qs.parse(queryString);
};

export const constructQueryString = queryObject => {
  return qs.stringify(queryObject);
};

export const rounds = (value, decimals) => {
  return Number(`${Math.round(`${Number(value).toFixed(20)}e${decimals}`)}e-${decimals}`);
};

/* ------------------------------------------------Specific function------------------------------------------------- */
// Rate
export const constructDisplayPrice = (feeAmount, hasPrefix = true) => {
  const displayFee = String(constructDisplayNumber(feeAmount));
  const displayFeeWithPrefix = hasPrefix ? `RM ${displayFee}` : displayFee;

  return displayFeeWithPrefix;
};
