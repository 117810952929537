import React from 'react';

import { StyledBareImageCarousel, StyledCloseButton, StyledImageCarouselContainer, StyledModal } from './ImageGallery.styles';

const ImageGallery = ({ images, alt, initialImageIndex, onClose }) => (
  <StyledModal visible centered closeIcon={<StyledCloseButton onClick={onClose} />} onCancel={onClose} footer={null}>
    <StyledImageCarouselContainer>
      <StyledBareImageCarousel images={images} alt={alt} initialImageIndex={initialImageIndex} />
    </StyledImageCarouselContainer>
  </StyledModal>
);

export default ImageGallery;
