import React, { useState } from 'react';
import { DoubleRightOutlined } from '@ant-design/icons';
import { Col, Empty, Popover, Row, Select } from 'antd';

import ListingCard from 'components/Card/ListingCard/ListingCard';
import Subtitle from 'components/Font/Subtitle/Subtitle';
import TextSecondary from 'components/Font/TextSecondary/TextSecondary';
import Title from 'components/Font/Title/Title';
import env from 'config/env.js';

import { withUserContext } from 'contexts/UserContext/UserContext';

import { constructDisplayNumber, getSingularOrPluralLabel, guard } from 'utils/general';

import {
  AddedIcon,
  ListingCardDescContainer,
  PricingCol,
  PopOverContentContainer,
  RoomsAvailTitle,
  RoomAmenitiesText,
  StyledAddToBookingButton,
  StyledEmpty,
  StyledListingCardFooter,
  ViewMoreButton
} from './RoomTypeListing.styles.js';

const { Option } = Select;

const RoomCapacity = ({ adult, child, bathroom, livingroom, bedroomCount }) => {
  return (
    <Row gutter={[16, 8]}>
      {adult > 0 && (
        <Col span={12} lg={6}>
          <TextSecondary>{getSingularOrPluralLabel(adult, 'Adult')}</TextSecondary>
        </Col>
      )}
      {child > 0 && (
        <Col span={12} lg={6}>
          <TextSecondary>{getSingularOrPluralLabel(child, 'Child', { pluralLabel: 'Children' })}</TextSecondary>
        </Col>
      )}
      {bathroom > 0 && (
        <Col span={24} md={12} lg={6}>
          <TextSecondary>{getSingularOrPluralLabel(bathroom, 'Bathroom')}</TextSecondary>
        </Col>
      )}
      {bedroomCount > 0 && (
        <Col span={24} md={12} lg={6}>
          <TextSecondary>{getSingularOrPluralLabel(bedroomCount, 'Bedroom')}</TextSecondary>
        </Col>
      )}
      {livingroom > 0 && (
        <Col span={24} md={12} lg={6}>
          <TextSecondary>{getSingularOrPluralLabel(livingroom, 'Living Room')}</TextSecondary>
        </Col>
      )}
    </Row>
  );
};

const SleepingArrangement = ({ bedrooms }) => {
  return (
    <>
      <Subtitle type="s">Sleep Arrangement</Subtitle>
      <Row gutter={16}>
        {bedrooms.map(bedroom =>
          bedroom.beds.map((bed, index) => (
            <Col key={index}>
              <TextSecondary>{`${bed.count} ${bed.type}`}</TextSecondary>
            </Col>
          ))
        )}
      </Row>
    </>
  );
};

const RoomAmenities = ({ amenities }) => {
  return (
    <>
      <Subtitle type="s">Amenities</Subtitle>
      <Row type="flex">
        <RoomAmenitiesText>
          <TextSecondary>{amenities.join(', ')}</TextSecondary>
        </RoomAmenitiesText>
        {amenities.length > 8 && (
          <Popover
            placement="right"
            content={
              <PopOverContentContainer>
                <TextSecondary type="s">{amenities.join(', ')}</TextSecondary>
              </PopOverContentContainer>
            }
            title="Amenities"
            trigger="click"
          >
            <ViewMoreButton icon={<DoubleRightOutlined />} text="View more amenities" />
          </Popover>
        )}
      </Row>
    </>
  );
};

const ListingCardDescription = ({ adult, child, bathroom, livingroom, bedrooms, bedroomCount, amenities }) => {
  return (
    <ListingCardDescContainer gutter={[0, 16]}>
      <Col span={24}>
        <RoomCapacity adult={adult} child={child} bathroom={bathroom} livingroom={livingroom} bedroomCount={bedroomCount} />
      </Col>
      <Col span={24}>
        <SleepingArrangement bedrooms={bedrooms} />
      </Col>
      {amenities && amenities.length > 0 && (
        <Col span={24}>
          <RoomAmenities amenities={amenities} />
        </Col>
      )}
    </ListingCardDescContainer>
  );
};

const ListingCardFooter = ({ addedToBooking, inventory, roomCount, pricePerNight, onChangeRoomCount, onClickAddToBooking, onViewMyBookingClick }) => {
  const generateInvOptions = inventory => {
    let options = [];
    for (let i = 1; i <= inventory; i++) {
      options.push(i);
    }
    return options;
  };

  return (
    <StyledListingCardFooter gutter={[24, { xs: 8, sm: 8, md: 0 }]} align="middle">
      <PricingCol span={24} lg={addedToBooking ? 16 : 8}>
        <Title type="s" newLine={false}>
          {constructDisplayNumber(pricePerNight, 0)}
        </Title>
        <b> MYR / night</b>
      </PricingCol>
      <Col span={24} lg={addedToBooking ? 8 : 16}>
        {addedToBooking ? (
          <StyledAddToBookingButton type="primary" onClick={onViewMyBookingClick}>
            View My Booking
          </StyledAddToBookingButton>
        ) : (
          <Row gutter={[16, { xs: 8, sm: 8, lg: 0 }]}>
            <Col span={24} md={10}>
              <Row type="flex" align="middle">
                <Col flex="40px">
                  <span>Qty: </span>
                </Col>
                <Col flex="auto">
                  <Select value={roomCount} onChange={onChangeRoomCount} style={{ width: '100%' }}>
                    {inventory && generateInvOptions(inventory).map(inv => <Option value={inv}>{inv}</Option>)}
                  </Select>
                </Col>
              </Row>
            </Col>
            <Col span={24} md={14}>
              {JSON.parse(env.IS_BOOKING_ENABLE) && (
                <StyledAddToBookingButton type="primary" onClick={onClickAddToBooking}>
                  Add to Booking
                </StyledAddToBookingButton>
              )}
            </Col>
          </Row>
        )}
      </Col>
    </StyledListingCardFooter>
  );
};

const RoomTypeListing = ({ roomTypes, bookingRoomIds, checkInOutDates, onAddToBooking, drawer }) => {
  const [checkInDate, checkOutDate] = checkInOutDates;

  const [roomTypesRoomCount, setRoomTypesRoomCount] = useState({});

  const handleOnClickAddToBooking = selectedRoomType => () => {
    const selectedRoomCount = roomTypesRoomCount[selectedRoomType._id] || 1;
    onAddToBooking({
      startDate: checkInDate,
      endDate: checkOutDate,
      room: { ...selectedRoomType, roomCount: selectedRoomCount }
    });
  };

  const handleOnChangeRoomCount = roomTypeId => roomCount => {
    setRoomTypesRoomCount({ ...roomTypesRoomCount, [roomTypeId]: roomCount });
  };

  const formatRoomTypeData = (roomType = {}) => {
    const roomTypeId = roomType._id;
    const roomTypeImages = roomType.images;
    const roomTypeName = roomType.displayName;
    const pricePerNight = roomType.pricePerNight;
    const roomTypeAdultPax = guard(() => roomType.capacity.adult, 1);
    const roomTypeChildPax = guard(() => roomType.capacity.children, 0);
    const roomTypeBathroomCount = roomType.bathrooms;
    const roomTypeLivingRoomCount = roomType.livingrooms;
    const roomTypeBedroomCount = roomType.bedroomCount;
    const roomTypeBedrooms = roomType.bedrooms;
    const roomTypeAmenities = roomType.amenities;
    const roomTypeInventory = roomType.inventory;

    const roomCount = guard(() => roomTypesRoomCount[roomTypeId], 1);
    const addedToBooking = bookingRoomIds.includes(roomTypeId);
    const imageMaxHeight = '360px'; //roomTypeAmenities.length > 15 ? '374px' : roomTypeAmenities.length > 10 ? '349px' : '326px';

    return {
      roomTypeId,
      roomTypeImages,
      roomTypeName,
      pricePerNight,
      roomTypeAdultPax,
      roomTypeChildPax,
      roomTypeBathroomCount,
      roomTypeBedroomCount,
      roomTypeLivingRoomCount,
      roomTypeBedrooms,
      roomTypeAmenities,
      roomTypeInventory,
      roomCount,
      imageMaxHeight,
      addedToBooking
    };
  };

  return (
    <div>
      <RoomsAvailTitle>Rooms Available</RoomsAvailTitle>
      {roomTypes.length > 0 ? (
        <Row>
          {roomTypes.map(roomType => {
            const {
              roomTypeId,
              roomTypeImages,
              roomTypeName,
              pricePerNight,
              roomTypeAdultPax,
              roomTypeChildPax,
              roomTypeBathroomCount,
              roomTypeBedroomCount,
              roomTypeLivingRoomCount,
              roomTypeBedrooms,
              roomTypeAmenities,
              roomTypeInventory,
              roomCount,
              imageMaxHeight,
              addedToBooking
            } = formatRoomTypeData(roomType);

            return (
              <ListingCard
                key={roomTypeId}
                image={roomTypeImages[0]}
                images={roomTypeImages}
                imageMaxHeight={imageMaxHeight}
                name={roomTypeName}
                tag={
                  addedToBooking && (
                    <>
                      <AddedIcon /> Added in My Booking
                    </>
                  )
                }
                bodyDescription={
                  <ListingCardDescription
                    adult={roomTypeAdultPax}
                    child={roomTypeChildPax}
                    bathroom={roomTypeBathroomCount}
                    bedroomCount={roomTypeBedroomCount}
                    livingroom={roomTypeLivingRoomCount}
                    bedrooms={roomTypeBedrooms}
                    amenities={roomTypeAmenities}
                  />
                }
                footer={
                  <ListingCardFooter
                    addedToBooking={addedToBooking}
                    inventory={roomTypeInventory}
                    roomCount={roomCount}
                    pricePerNight={pricePerNight}
                    onChangeRoomCount={handleOnChangeRoomCount(roomTypeId)}
                    onClickAddToBooking={handleOnClickAddToBooking(roomType)}
                    onViewMyBookingClick={drawer.openDrawer}
                  />
                }
              />
            );
          })}
        </Row>
      ) : (
        <StyledEmpty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No room available based on your search" />
      )}
    </div>
  );
};

export default withUserContext(RoomTypeListing);
