import React, { useMemo } from 'react';
import { Button, Col, Empty, Row, Skeleton, Tooltip } from 'antd';
import { RocketOutlined, HomeOutlined, CrownOutlined, FireOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

import { withUserContext } from 'contexts/UserContext/UserContext';

import { useGetPropertyListingsByQuery } from 'apis/property';

import ClickableTextButton from 'components/ClickableTextButton/ClickableTextButton';
import TextSecondary from 'components/Font/TextSecondary/TextSecondary';
import Title from 'components/Font/Title/Title';
import ListingCard from 'components/Card/ListingCard/ListingCard';

import HostAStayLogo from 'images/hostastay-logo-with-white-background.png';

import { BLANK_IMAGE } from 'utils/constants';
import { formatToDateString } from 'utils/date';
import { constructDisplayNumber, guard } from 'utils/general';
import { buildBaseUri, buildPropertyDetailUri, buildPromotionUri } from 'utils/routes';

import { StyledBody, BodyContainer, ListingMainTitle, StyledEmpty, StyledTag, ListingCardFooterCol } from './Promotion.styles';

const useFetchPropertyListings = ({ state, checkInDate, checkOutDate, adultPax, childPax, sortingCriteria }) => {
  const query = {
    state,
    checkInDate: formatToDateString(checkInDate),
    checkOutDate: formatToDateString(checkOutDate),
    adultPax,
    childPax,
    sortingCriteria,
    isHoliStayPromo: true
  };

  const { isLoading: isLoadingProperties, data } = useGetPropertyListingsByQuery(query);

  const properties = data.properties || [];
  const totalNumberOfProperties = properties.length;

  const sortedProperties = useMemo(
    () =>
      sortingCriteria === 'priceAscending'
        ? properties.sort((current, next) => current.pricePerNight - next.pricePerNight)
        : properties.sort((current, next) => next.pricePerNight - current.pricePerNight),
    [sortingCriteria, properties]
  );

  const propertiesWithTotal = {
    total: totalNumberOfProperties,
    properties: sortedProperties.map(property => ({
      ...property,
      images: guard(() => (property.images.length > 0 ? property.images.map(image => image.imageUrl) : [BLANK_IMAGE]), [BLANK_IMAGE]),
      propertyType: guard(() => property.propertyTypes.label),
      host: {
        ...property.host,
        image: property.host.image || HostAStayLogo
      },
      isAllowInstantBooking: property.isAllowInstantBooking,
      isPremium: property.isPremium,
      isHoliStayPromo: property.isHoliStayPromo
    }))
  };

  return { isLoadingProperties, propertiesWithTotal };
};

const ListingCardSubtitle = ({ propertyType, isAllowInstantBooking, isPremium, isHoliStayPromo }) => {
  return (
    <Row gutter={[8, 4]}>
      <Col>
        <Tooltip title="Property's type">
          <StyledTag icon={<HomeOutlined />} color="orange">
            {propertyType}
          </StyledTag>
        </Tooltip>
      </Col>
      {isAllowInstantBooking && (
        <Col>
          <Tooltip title="Booking will be confirm instantly">
            <StyledTag icon={<RocketOutlined />} color="green">
              Instant Booking
            </StyledTag>
          </Tooltip>
        </Col>
      )}
      {isPremium && (
        <Col>
          <Tooltip title="Premium property">
            <StyledTag icon={<CrownOutlined />} color="gold">
              Premium
            </StyledTag>
          </Tooltip>
        </Col>
      )}
      {isHoliStayPromo && (
        <Col>
          <Tooltip title="HoliStay Promo">
            <StyledTag icon={<FireOutlined />} color="volcano">
              HoliStay
            </StyledTag>
          </Tooltip>
        </Col>
      )}
    </Row>
  );
};

const ListingCardDescription = ({ address }) => {
  return (
    <Row>
      <Col span={24}>
        <TextSecondary type="s">{address}</TextSecondary>
      </Col>
    </Row>
  );
};

const ListingCardFooter = ({ pricePerNight, onListClick }) => {
  return (
    <Row type="flex" align="middle" style={{ padding: '0 24px' }}>
      <Col span={12} md={20}>
        <Row>
          <Col span={24} md={0}>
            <span>From </span>
            <Title type="s" newLine={false}>
              {constructDisplayNumber(pricePerNight, 0)}
            </Title>
          </Col>
          <Col span={24} md={0}>
            <b> MYR / night</b>
          </Col>
          <Col span={0} md={24}>
            <span>From </span>
            <Title type="s" newLine={false}>
              {constructDisplayNumber(pricePerNight, 0)}
            </Title>
            <b> MYR / night</b>
          </Col>
        </Row>
      </Col>
      <ListingCardFooterCol span={12} md={4}>
        <Button type="primary" onClick={onListClick}>
          View more
        </Button>
      </ListingCardFooterCol>
    </Row>
  );
};

const Promotion = ({ searchAndFilter }) => {
  const history = useHistory();

  const { isLoadingProperties, propertiesWithTotal } = useFetchPropertyListings(searchAndFilter);

  const handleOnListClick = propertyId => () => {
    history.push(buildPropertyDetailUri(propertyId), { from: buildPromotionUri() });
  };

  return (
    <>
      <Skeleton active loading={isLoadingProperties}>
        <StyledBody loading={false}>
          <BodyContainer>
            <Col span={24}>
              <br />
              <ClickableTextButton text="View Other Stays Options" url={buildBaseUri()} />
            </Col>
            {propertiesWithTotal.properties.length > 0 ? (
              <>
                <ListingMainTitle>
                  <Col span={24}>
                    <Title type="s">Participating Stays</Title>
                  </Col>
                  <Col span={24}>
                    <TextSecondary>
                      <b>{propertiesWithTotal.total}</b> Properties found based on your search
                    </TextSecondary>
                  </Col>
                </ListingMainTitle>
                <Row>
                  {propertiesWithTotal.properties.map(property => {
                    const { _id, name, propertyType, images, address, pricePerNight, isAllowInstantBooking, isPremium, isHoliStayPromo } = property;
                    return (
                      <ListingCard
                        key={_id}
                        image={images[0]}
                        images={images}
                        imageMaxHeight="216px"
                        name={name}
                        subtitle={
                          <ListingCardSubtitle
                            propertyType={propertyType}
                            isAllowInstantBooking={isAllowInstantBooking}
                            isPremium={isPremium}
                            isHoliStayPromo={isHoliStayPromo}
                          />
                        }
                        bodyDescription={<ListingCardDescription address={address} />}
                        footer={<ListingCardFooter pricePerNight={pricePerNight} onListClick={handleOnListClick(_id)} />}
                      />
                    );
                  })}
                </Row>
              </>
            ) : (
              <StyledEmpty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No properties available based on your search" />
            )}
          </BodyContainer>
        </StyledBody>
      </Skeleton>
    </>
  );
};

export default withUserContext(Promotion);
