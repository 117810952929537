import styled from '@emotion/styled';
import { Col } from 'antd';

export const StyledCol = styled(Col)`
  padding-top: 50px;
  text-align: center;

  @media (min-width: 768px) {
    padding-top: 7%;
  }
`;

export const StyledSecondaryTitle = styled.p`
  font-size: 18px;
  color: ${props => props.theme.color.primary};
`;

export const StyledContainer = styled.div`
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  width: 100vw;
  position: relative;
  left: calc(-50vw + 50%);
  padding: 35px 0;
  margin-top: 30px;
`;

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 80%;
  text-align: start;

  @media (min-width: 1024px) {
    padding: 0 24px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-height: 524px;
    flex-grow: 1;
    justify-content: space-between;
  }
`;
export const DestinationWrapperColumn = styled(Col)`
  break-before: column;
  flex-basis: 50%;
  margin-bottom: 15px;
`;
