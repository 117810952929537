import { KeyOutlined } from '@ant-design/icons';
import { Alert, Col, Form, Row, Checkbox } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { postActivateMemberAccount } from 'apis/auth';
import ClickableTextButton from 'components/ClickableTextButton/ClickableTextButton';
import FormInput from 'components/Input/FormInput/FormInput';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { buildBaseUri } from 'utils/routes';
import { LoginButton, MainContainer, PasswordSetContainer, TitleLabel, TitleRow } from './MemberActivation.styles';

const MemberActivation = () => {
  const [form] = useForm();
  const { token } = useParams();
  const history = useHistory();
  const [isPasswordChanged, setIsPasswordChanged] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [isPasswordReset, setIsPasswordReset] = useState(false);
  const [isTncCheck, setTncCheckbox] = useState(false);

  const validateToNextPassword = (rule, value, callback) => {
    if (value && isPasswordChanged) {
      form.validateFields(['confirmPassword'], { force: true });
    }
    callback();
  };

  const compareToFirstPassword = (rule, value, callback) => {
    if (value && value !== form.getFieldValue('password')) {
      return callback('Both password are not the same');
    }
    callback();
  };

  const handleConfirmBlur = e => {
    const { value } = e.target;
    setIsPasswordChanged(isPasswordChanged || !!value);
  };

  const handleBackButtonClick = () => {
    history.push(buildBaseUri());
  };

  const handleOnFormSubmitAndValidated = ({ password }) => {
    postActivateMemberAccount(token, password)
      .then(() => {
        setIsPasswordReset(true);
        setResponseMessage({ message: 'Your account has been authenticated! Please login with your new password in the future.', type: 'success' });
      })
      .catch(ex => {
        setResponseMessage({ message: ex.message, type: 'error' });
      });
  };

  const toggleTncCheckbox = () => setTncCheckbox(!isTncCheck);

  return (
    <MainContainer>
      <Row type="flex" justify="center" align="center" style={{ width: '100%' }}>
        <Col lg={10} span={20}>
          <PasswordSetContainer>
            <TitleRow>
              <Col>
                <TitleLabel>Account Activation</TitleLabel>
              </Col>
            </TitleRow>
            <Form form={form} onFinish={handleOnFormSubmitAndValidated}>
              <FormInput
                label="New Password"
                prefix={<KeyOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="New password"
                type="password"
                name="password"
                requiredErrorMessage="Please enter your new password"
                extraRules={[{ validator: validateToNextPassword }]}
                isNewPassword
              />
              <FormInput
                label="Confirm New Password"
                prefix={<KeyOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="Confirm new password"
                type="password"
                name="confirmPassword"
                requiredErrorMessage="Please confirm your new password"
                onBlur={handleConfirmBlur}
                extraRules={[{ validator: compareToFirstPassword }]}
              />
              <Row gutter={16} justify="start">
                <Col span={2} md={2}>
                  <Checkbox onClick={() => toggleTncCheckbox()} value={isTncCheck} />
                </Col>
                <Col span={22} md={22}>
                  <p>
                    I acknowledge and confirm that I have read and understood the terms and conditions in the{' '}
                    <a target="_blank" href="https://mdec.my/footer-pages/personal-data-protection/" rel="noopener noreferrer">
                      {' '}
                      MDEC Personal Data Protection Act
                    </a>{' '}
                    and agree to abide by and be bound by the terms and conditions set forth.
                  </p>
                </Col>
              </Row>
              {responseMessage.message && <Alert message={responseMessage.message} type={responseMessage.type} />}
              <Row justify="center">
                <LoginButton htmlType="submit" type="primary" disabled={isPasswordReset || !isTncCheck}>
                  Activate
                </LoginButton>
                <ClickableTextButton text="Back to home page" onClick={handleBackButtonClick} />
              </Row>
            </Form>
          </PasswordSetContainer>
        </Col>
      </Row>
    </MainContainer>
  );
};

export default MemberActivation;
