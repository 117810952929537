import React from 'react';
import { Col, Row, Tooltip } from 'antd';
import { HomeOutlined, RocketOutlined, CrownOutlined, FireOutlined, PercentageOutlined } from '@ant-design/icons';

import { StyledTag } from './PropertyTags.styles';

const PropertyInfoTag = ({ label, icon, color, hoverText }) => {
  return (
    <Tooltip title={hoverText}>
      <StyledTag icon={icon} color={color}>
        {label}
      </StyledTag>
    </Tooltip>
  );
};

const PropertyInfoTags = ({ propertyType, isAllowInstantBooking, isPremium, isHoliStayPromo, isPromo }) => {
  return (
    <Row gutter={[8, 4]}>
      <Col>
        <PropertyInfoTag label={propertyType} icon={<HomeOutlined />} color="orange" hoverText="Property's type" />
      </Col>
      {isAllowInstantBooking && (
        <Col>
          <PropertyInfoTag label="Instant Booking" icon={<RocketOutlined />} color="green" hoverText="Booking will be confirm instantly" />
        </Col>
      )}
      {isPremium && (
        <Col>
          <PropertyInfoTag label="Premium" icon={<CrownOutlined />} color="gold" hoverText="Premium property" />
        </Col>
      )}
      {isHoliStayPromo && (
        <Col>
          <PropertyInfoTag label="HoliStay" icon={<FireOutlined />} color="volcano" hoverText="HoliStay Promo" />
        </Col>
      )}
      {isPromo && (
        <Col>
          <PropertyInfoTag label="Promo" icon={<PercentageOutlined />} color="volcano" hoverText="Promo code eligible" />
        </Col>
      )}
    </Row>
  );
};

export default PropertyInfoTags;
