import React, { useState } from 'react';
import { Row, Form, Modal } from 'antd';
import { MailOutlined } from '@ant-design/icons';

import { postForgetPassword } from 'apis/auth';
// import { SS_WP_BASE_URL } from 'utils/constants';

import FormInput from 'components/Input/FormInput/FormInput';
import { withAppContext } from 'contexts/AppContext/AppContext';

import { TitleRow, TitleLabel, SendLinkButton, StyledAlert } from './ForgetPasswordModal.styles';

const { useForm } = Form;

const LoginModal = ({ visible, isLoadingMember, onClose, isFirstTimeLogin }) => {
  const [form] = useForm();
  const [responseMessage, setResponseMessage] = useState({});
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const handleOnFormSubmitAndValidated = ({ email }) => {
    setResponseMessage({});
    postForgetPassword(email)
      .then(() => {
        setIsFormSubmitted(true);
        setResponseMessage({
          message: (
            <Row>
              <span>We have just sent you a link to reset your password. Please check your inbox and follow the instructions.</span>
              {/* <span>
                If you do not receive the email,{' '}
                <a href={`${SS_WP_BASE_URL}/contacts/`} target="_blank" rel="noopener noreferrer">
                  <b>please contact us</b>
                </a>
                .
              </span> */}
            </Row>
          ),
          type: 'success'
        });
      })
      .catch(ex => setResponseMessage({ message: ex.message, type: 'error' }));
  };

  const handleOnCancel = () => {
    setResponseMessage({});
    setIsFormSubmitted(false);
    onClose();
  };

  return (
    <Modal visible={visible} footer={null} onCancel={handleOnCancel} destroyOnClose>
      <Row>
        <TitleRow>
          <TitleLabel>{isFirstTimeLogin ? 'Setup your account password' : 'Forgot your password?'}</TitleLabel>
          <p>Don't worry! Just enter the email you registered with and we’ll email you a link to reset your password.</p>
          {/* <p>
            If you encounter any problem with signing in your account, please <a href={`${SS_WP_BASE_URL}/contacts/`}>contact us.</a>
          </p> */}
        </TitleRow>
        <Form form={form} onFinish={handleOnFormSubmitAndValidated} preserve={false}>
          <Row>
            <FormInput
              label="Email"
              prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Your email"
              name="email"
              requiredErrorMessage="Please enter your email"
              isEmail
            />
            {responseMessage.message && <StyledAlert message={responseMessage.message} type={responseMessage.type} />}
            <SendLinkButton htmlType="submit" type="primary" disabled={isLoadingMember || isFormSubmitted} loading={isLoadingMember}>
              Send reset password link
            </SendLinkButton>
          </Row>
        </Form>
      </Row>
    </Modal>
  );
};

export default withAppContext(LoginModal);
