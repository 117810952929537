import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { withAppContext } from 'contexts/AppContext/AppContext';
import { withUserContext } from 'contexts/UserContext/UserContext';
import { withUniversalModalContext } from 'contexts/UniversalModalContext/UniversalModalContext';

import { buildBaseUri } from 'utils/routes';
import { SS_WP_BASE_URL } from 'utils/constants';
import { checkHasValue, constructQueryAsObject, constructQueryString } from 'utils/general';

import HostAStayLogo from 'images/HostAStay-logo.png';

import BookingDrawer from './BookingDrawer/BookingDrawer';
import Header from './Header/Header';
import LoginModal from './LoginModal/LoginModal';
import ForgetPasswordModal from './ForgetPasswordModal/ForgetPasswordModal';
import SignupModal from './SignupModal/SignupModal';
import Footer from './Footer/Footer';
import LPFooter from './Footer/LPFooter';

import { StandardLayoutContainer, StyledBody, StyledFooter } from './StandardLayout.styles';
import { staticRoutes } from 'utils/routes';

export const StandardLayoutContext = React.createContext({});

const useTriggerPopup = (hasUserVisitBefore, isMemberLoggedIn, location, history, onSignupModalVisibleChange) => {
  const [hasPopupShown, setHasPopupShown] = useState(false);
  const queryStringObj = useMemo(() => constructQueryAsObject(location.search), [location.search]);

  const showPopup = useCallback(() => {
    onSignupModalVisibleChange(true);
    setHasPopupShown(true);
  }, [onSignupModalVisibleChange]);

  useEffect(() => {
    if (
      !isMemberLoggedIn &&
      checkHasValue(hasUserVisitBefore) &&
      !hasUserVisitBefore &&
      !hasPopupShown &&
      !staticRoutes.includes(location.pathname)
    ) {
      showPopup();
    }
  }, [isMemberLoggedIn, hasUserVisitBefore, showPopup, hasPopupShown, location.pathname]);

  useEffect(() => {
    if (queryStringObj.signup) {
      if (!isMemberLoggedIn) {
        showPopup();
      }

      delete queryStringObj.signup;
      const newQueryString = constructQueryString(queryStringObj);
      history.replace({ search: newQueryString });
    }
  }, [isMemberLoggedIn, queryStringObj, location, history, showPopup]);
};

const StandardLayout = ({
  banner,
  children,
  hasStickyHeader,
  hasMdecBanner,
  drawer,
  userBooking,
  hasUserVisitBefore,
  isMemberLoggedIn,
  type,
  loginModalProps: { isVisible: isLoginModalVisible, onModalVisibleChange: onLoginModalVisibleChange },
  signupModalProps: { isVisible: isSignupModalVisible, onModalVisibleChange: onSignupModalVisibleChange }
}) => {
  const history = useHistory();
  const location = useLocation();
  const currentPath = location.pathname;
  const homePageURL = currentPath === buildBaseUri() ? SS_WP_BASE_URL : buildBaseUri();

  const [forgetPasswordModalVisible, setForgetPasswordModalVisible] = useState(false);
  const [isFirstTimeLoginMode, setIsFirstTimeLoginMode] = useState(false);

  useTriggerPopup(hasUserVisitBefore, isMemberLoggedIn, location, history, onSignupModalVisibleChange);

  const showLoginModal = useCallback(() => {
    onLoginModalVisibleChange(true);
  }, [onLoginModalVisibleChange]);

  const hideLoginModal = useCallback(() => {
    onLoginModalVisibleChange(false);
  }, [onLoginModalVisibleChange]);

  const showSignupModal = useCallback(() => {
    hideLoginModal();
    onSignupModalVisibleChange(true);
  }, [onSignupModalVisibleChange, hideLoginModal]);

  const hideSignupModal = useCallback(() => {
    onSignupModalVisibleChange(false);
  }, [onSignupModalVisibleChange]);

  const showForgetPasswordModal = useCallback(() => {
    setForgetPasswordModalVisible(true);
    setIsFirstTimeLoginMode(false);
  }, [setForgetPasswordModalVisible]);

  const hideForgetPasswordModal = useCallback(() => {
    setForgetPasswordModalVisible(false);
    setIsFirstTimeLoginMode(false);
  }, [setForgetPasswordModalVisible]);

  const showForgetPasswordModalWithFirstTimeLogin = useCallback(() => {
    setForgetPasswordModalVisible(true);
    setIsFirstTimeLoginMode(true);
  }, [setForgetPasswordModalVisible, setIsFirstTimeLoginMode]);

  const handleOnLoginButtonClick = useCallback(() => {
    hideSignupModal();
    showLoginModal();
  }, [hideSignupModal, showLoginModal]);

  const showMyBookingDrawer = useCallback(
    e => {
      drawer.openDrawer(e);
    },
    [drawer]
  );

  const aboutUs = () => history.push('/about-us');

  return (
    <StandardLayoutContainer type={type}>
      <Header
        banner={banner}
        hasStickyHeader={hasStickyHeader}
        hasMdecBanner={hasMdecBanner}
        homePageURL={homePageURL}
        iconImage={HostAStayLogo}
        onOpenDrawer={showMyBookingDrawer}
        onLoginButtonClick={showLoginModal}
        numberOfBookings={userBooking && userBooking.rooms && userBooking.rooms.length}
        aboutUs={aboutUs}
      />
      <StyledBody type={type}>
        {hasMdecBanner ? children : <StandardLayoutContext.Provider value={showLoginModal}>{children}</StandardLayoutContext.Provider>}
      </StyledBody>
      <StyledFooter>{location.pathname === '/' || location.pathname === '/de-rantau' ? <LPFooter /> : <Footer />}</StyledFooter>
      {!hasMdecBanner && (
        <LoginModal
          visible={isLoginModalVisible}
          onClose={hideLoginModal}
          onRegisterButtonClick={showSignupModal}
          onForgetPasswordClick={showForgetPasswordModal}
          onFirstTimeLoginClick={showForgetPasswordModalWithFirstTimeLogin}
        />
      )}
      {!hasMdecBanner && <SignupModal visible={isSignupModalVisible} onClose={hideSignupModal} onLoginButtonClick={handleOnLoginButtonClick} />}
      <ForgetPasswordModal visible={forgetPasswordModalVisible} onClose={hideForgetPasswordModal} isFirstTimeLogin={isFirstTimeLoginMode} />
      <BookingDrawer visible={drawer.isOpen} onCloseDrawer={drawer.closeDrawer} onLoginButtonClicked={showLoginModal} />
    </StandardLayoutContainer>
  );
};

export default withAppContext(withUserContext(withUniversalModalContext(StandardLayout)));
