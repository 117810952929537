import styled from '@emotion/styled';
import { Col } from 'antd';

import ImageCarousel from 'components/Image/ImageCarousel/ImageCarousel';
import Subtitle from 'components/Font/Subtitle/Subtitle';

export const ImageCarouselCol = styled(Col)`
  background-color: ${props => props.theme.color.white};
  box-shadow: 2px 2px 5px ${props => props.theme.color.black}33;
`;

export const AmenitiesContainer = styled.div`
  margin-bottom: ${props => props.theme.spacing.xs};
`;

export const StyledImageCarousel = styled(ImageCarousel)`
  height: 280px;

  img {
    height: 100%;
  }

  @media (min-width: 768px) {
    height: 240px;
  }

  @media (min-width: 992px) {
    height: 160px;
  }

  @media (min-width: 1024px) {
    height: 240px;
  }
`;

export const StyledSubtitle = styled(Subtitle)`
  margin-bottom: ${props => props.theme.spacing.xs};
  margin-top: ${props => props.theme.spacing.regular};

  @media (min-width: 768px) {
    margin-top: 0px;
  }
`;

export const ContentCol = styled(Col)`
  @media (min-width: 768px) {
    padding-left: ${props => props.theme.spacing.sm};
  }
`;
