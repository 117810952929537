import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { CheckOutlined } from '@ant-design/icons';
import { Row, Col, Skeleton, Form, Button, Modal, message } from 'antd';

import { useGetMemberSelf, patchUpdateMemberSelf } from 'apis/member';

import FormInput from 'components/Input/FormInput/FormInput';
import FormSelection from 'components/FormSelection/FormSelection';
import FormGroupSelectionInput from 'components/FormGroupSelectionInput/FormGroupSelectionInput';
import SectionContainer from 'components/SectionContainer/SectionContainer';
import Title from 'components/Font/Title/Title';

import { useFetchConstant } from 'hooks/constants';

const { error } = Modal;

const useFetchConstants = () => {
  const { selection: identificationTypes, data: identificationTypesConst, isLoading: isIndetificationTypesLoading } = useFetchConstant(
    'identificafionTypes'
  );
  const { selection: countries, isLoading: isCountriesLoading } = useFetchConstant('countries', {
    query: { isFetchingAllCountries: true },
    valueKey: 'iso3',
    labelKey: 'name'
  });

  const isLoading = isCountriesLoading || isIndetificationTypesLoading;
  const selections = { identificationTypes, countries };
  const datas = { identificationTypesConst };

  return { isLoading, selections, datas };
};

const Profile = () => {
  const [selectedIdentificationType, setSelectedIdentificationType] = useState('');
  const [selectedIdentificationId, setSelectedIdentificationId] = useState('');
  const { isLoading: isMemberLoading, data: member } = useGetMemberSelf();

  const {
    isLoading: isConstantsLoading,
    selections: { identificationTypes, countries },
    datas: { identificationTypesConst }
  } = useFetchConstants();

  const isLoading = isConstantsLoading || isMemberLoading;
  const defaultIdentificationType = useMemo(() => identificationTypes && identificationTypes.find(type => type.isDefault), [identificationTypes]);

  useEffect(() => {
    if (member && member.identification) {
      setSelectedIdentificationType(member.identification.type);
      setSelectedIdentificationId(member.identification.id);
    } else if (defaultIdentificationType) {
      setSelectedIdentificationType(defaultIdentificationType.code);
    }
  }, [member, defaultIdentificationType]);

  const getIDLabel = useCallback(() => {
    const selectedIdType = identificationTypes.find(type => type.value === selectedIdentificationType);
    const selectedIdTypeLabel = (selectedIdType && selectedIdType.label) || defaultIdentificationType.label;

    return selectedIdTypeLabel + ' Number';
  }, [selectedIdentificationType, identificationTypes, defaultIdentificationType]);

  const handleOnSave = values => {
    if (values.identification && !values.identification.nationality) {
      values.identification = undefined;
    }
    patchUpdateMemberSelf(values)
      .then(() => {
        message.success('Your profile has been updated!');
      })
      .catch(ex => {
        error({
          title: ex.message
        });
      });
  };

  const handleOnSaveFailed = ({ errorFields }) => {
    errorFields.forEach(field => message.error(field.errors[0]));
  };

  return (
    <>
      {isLoading ? (
        <Skeleton />
      ) : (
        <Form initialValues={member} scrollToFirstError={true} style={{ width: '100%' }} onFinish={handleOnSave} onFinishFailed={handleOnSaveFailed}>
          <SectionContainer>
            <div style={{ marginBottom: '16px' }}>
              <Title type="s">Account Details</Title>
            </div>
            <Row gutter={16}>
              <Col span={24}>
                <FormInput label="Name" name="name" requiredErrorMessage="Please enter your name." />
              </Col>
              <Col span={24}>
                <FormInput
                  label="Email Address"
                  name="email"
                  type="email"
                  placeholder="example@mail.com"
                  requiredErrorMessage="Please enter your email address."
                  disabled={true}
                />
              </Col>
              <Col span={24}>
                <FormGroupSelectionInput
                  label="Contact Number"
                  inputType="number"
                  name="contact"
                  selectionName="countryCode"
                  inputName="contactNumber"
                  requiredErrorMessage="Please enter your contact number"
                  disabled={true}
                />
              </Col>
            </Row>
          </SectionContainer>
          <SectionContainer>
            <div style={{ marginBottom: '16px' }}>
              <Title type="s">Identification Details</Title>
            </div>
            <Row gutter={16}>
              <Col span={24}>
                <FormSelection label="Nationality" name={['identification', 'nationality']} placeholder="Select Nationality" selections={countries} />
              </Col>
              <Col span={24} md={12}>
                <FormSelection
                  label="ID Type"
                  name={['identification', 'type']}
                  placeholder="Select ID Type"
                  selections={identificationTypes}
                  onChange={setSelectedIdentificationType}
                  requiredErrorMessage={!!selectedIdentificationId ? 'Please select your identification type.' : ''}
                />
              </Col>
              <Col span={24} md={12}>
                <FormInput
                  label={getIDLabel()}
                  name={['identification', 'id']}
                  onChange={setSelectedIdentificationId}
                  requiredErrorMessage={!!selectedIdentificationType ? 'Please enter your identification number.' : ''}
                  isNRIC={selectedIdentificationType === identificationTypesConst.NRIC.code}
                />
              </Col>
            </Row>
          </SectionContainer>
          <Row gutter={8}>
            <Col>
              <Button htmlType="submit" type="primary" icon={<CheckOutlined />}>
                Update my profile
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </>
  );
};

export default Profile;
