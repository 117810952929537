import React from 'react';

import { BannerContainer, TextCol, Column } from '../Privacy.styles';
import Subtitle from 'components/Font/Subtitle/Subtitle';

const Content = () => {
  return (
    <BannerContainer justify="start">
      <Column>
        <Subtitle>Introduction</Subtitle>
        <TextCol>
          Booking.hostastay.com is owned and operated by HostAStay Sdn. Bhd. (“DE Rantau” or “we”), a company registered in Malaysia. The following
          Privacy Policy describes how we as a data controller collect, use, disclose, and otherwise process personally identifiable information that
          we collect about individuals (“Personal Data”) in connection with the services available from DE Rantau through the website located at
          Booking.hostastay.com (“Site”). References to the Site are deemed to include derivatives thereof, including but not necessarily limited to
          mobile websites and applications. By visiting the Site, you are consenting to the collection, use, disclosure and processing of your
          Personal Data as described in this Privacy Policy.
        </TextCol>
        <TextCol>
          On occasion, HostAStay may revise this Privacy Policy to reflect changes in the law, our Personal Data collection and use practices, the
          features of our Site, or advances in technology. If we make revisions that change the way we collect or use your Personal Data, those
          changes will be posted in this Privacy Policy and the effective date will be noted at the beginning of this Privacy Policy. Therefore, you
          should review this Privacy Policy periodically so that you are up to date on our most current policies and practices. HostAStay will also
          prominently post such material changes prior to implementing the change. If you do not agree with any changes or modifications to the
          Privacy Policy, please do not continue using the Site. You will be deemed to have consented to any modification of the Privacy Policy when
          you use the Site after the effective date of the modification.
        </TextCol>
      </Column>
      <Column>
        <Subtitle>Information We Collect</Subtitle>
        <TextCol>
          We collect Personal Data about you that you provide to us when using the Site. Personal Data may include your name, address, phone number,
          credit card details, e-mail address, accommodation name, accommodation location and/or length of stay. In addition to this, when using our
          Site, even if you do not make a reservation, we may collect other certain information, such as your IP address, geographic data, your
          nationality and password, search preferences related to specific searches, which browser you’re using, and information about your computer’s
          operating system, application version, language settings and pages that have been shown to you. If you are using a mobile device, we might
          also collect data that identifies your mobile device, device-specific settings and characteristics and latitude/longitude details, and if
          you participate in an DE Rantau program there may be limited additional data that we may collect from the accommodation about your stay, for
          example, a list of charges (anonymized) as part of the DE Rantau Reception program. When you make a reservation, our system registers
          through which means and from which websites you have made your reservation.
        </TextCol>
        <TextCol>
          Pre-reservation chat support: in certain circumstances we may provide DE Rantau customers with pre-reservation chat support. The purpose of
          this support is to help customers with any pre-reservation questions that they may have but should a customer decide to proceed with the
          reservation on the Site, the customer will be expected to follow the normal reservation procedure him or herself. As such, we will generally
          not ask customers for any Personal Data e.g. no credit card numbers or customer email addresses but in case any information a customer
          provides to DE Rantau is deemed by law to be Personal Data or if the customer decides to disclose Personal Data to DE Rantau or where such
          Personal Data is required to be collected in order to provide pre-reservation chat support, then this Privacy Policy will apply to such
          Personal Data collected.
        </TextCol>

        <TextCol>
          Personal information you give us about others: by choosing to participate in our referral program, you may disclose personal information of
          others to us such as their names and email addresses. Please note that it is your responsibility to ensure that the person or people you
          have provided personal information about are aware that you have done so, and have accepted how DE Rantau uses their information (as
          described in this Privacy Policy).
        </TextCol>
      </Column>
      <Column>
        <Subtitle>Protecting Your Personal Data</Subtitle>
        <TextCol>
          To prevent unauthorized access, we maintain reasonable physical, electronic and organizational procedures to protect Personal Data against
          accidental or unlawful destruction or accidental loss, alteration, or unauthorized disclosure or access. To protect credit card
          transactions, booking.hostastay.com currently uses Secure Socket Layer technology to encrypt credit card information during transit.
        </TextCol>
      </Column>
      <Column>
        <Subtitle>How We Use the Information We Collect– Credit Card on File</Subtitle>
        <ol type="i">
          <li>
            <TextCol>General Use: We use Personal Data and other information collected through the Site to</TextCol>
          </li>
          <ol type="a">
            <li>register you with the Site and create your user account</li>
            <li>
              provide you with the requested services (mainly to process and confirm your reservation with the accommodation provider or other
              supplier) after verifying your credentials
            </li>
            <li>
              provide you with information about our services on the Site or on other websites and to communicate with you (including by post-booking
              chat support) to the extent necessary to provide such requested services
            </li>
            <li>build features that will make the services available on the Site easier to use</li>
            <li>conduct surveys</li>
            <li>for an improved user experience, internal training and general optimization purposes</li>
            <li>
              for the detection and prevention of fraud and other illegal or unwanted activities or contact you in case there is any issue with your
              booking. This includes faster purchase requests, better customer support and timely notice of new services and special offers.
            </li>
          </ol>

          <li>
            <TextCol>
              Marketing Activities & Reminders: We also use your information for marketing activities, as permitted by law. For example:{' '}
            </TextCol>
          </li>
          <ol type="a">
            <li>
              When you make a reservation with us, set up a user account, complete or enter details into a booking form, or provide us with your
              contact details we may use your contact information to send you news of products and services relevant and useful for travelers. With
              your consent – if consent is required under local law – we may also send you other regular newsletters by email. You can opt out, or
              unsubscribe, from marketing communications at any time using the “Unsubscribe” link in each newsletter or communication, or you can
              administer your subscriptions through your account (if you’ve created one).
            </li>
            <li>
              Based on the information you share with us, individualized offers may be shown to you on the Site, in mobile apps or on third-party
              websites, including social media sites. These might be offers that you can book directly on the Site, or third-party offers or products
              we think you might find interesting.
            </li>
            <li>
              If you have not finalized a reservation online, we may send you a reminder to continue with your reservation. We believe that this
              additional service is useful to you because it allows you to carry on with a reservation without having to search for the accommodation
              again or fill in all the reservation details from scratch.
            </li>
            <li>
              When you participate in other promotional activities, including but not limited to our referral programs, relevant information will be
              used to administer these promotions.
            </li>
            <li>Please see the section below titled “Cookies, Page Tags and Data Use” on how we use this data for advertising purposes.</li>
            <li>
              Please note that the confirmation emails and text messages sent after your booking, as well as the guest review emails sent after your
              departure, are not marketing messages. These messages are part of your accommodation reservation process. Respectively, they contain
              information for you to check in at your booked accommodation, and tools to optionally rate that accommodation after your stay. You will
              continue to receive them, even if you have opted out of our marketing messages. Similarly, opting into receiving Price Alerts will not
              affect any other choices you have made regarding receiving communications from us. If you elect to receive Price Alerts, you will be
              able to opt out of receiving the same by clicking on the “turn off price alerts” or a similar message found in the Price Alert sent to
              you
            </li>
            <li>
              for the detection and prevention of fraud and other illegal or unwanted activities or contact you in case there is any issue with your
              booking. This includes faster purchase requests, better customer support and timely notice of new services and special offers.
            </li>
          </ol>

          <li>
            <TextCol>
              Credit Card on File: When logging in, you will have the ability to save credit card details to your personal account for faster future
              bookings and payments. Your card details are always secure using strong encryption and you can always log in to your personal account to
              delete, edit or add certain credit card details. For security reasons, the number cannot be edited (but the card details can be
              deleted). When viewing card details, only the last 4 digits of the number will be visible. Please note that we will store the last 4
              digits of your credit card securely upon any booking made (this is required to manage any refund requests for that booking).
            </TextCol>
          </li>
        </ol>
      </Column>
      <Column>
        <Subtitle>Sharing Your Personal Data</Subtitle>
        <TextCol>
          DE Rantau may share your Personal Data with suppliers such as accommodation properties, third party accommodation suppliers like Booking.com
          and other third parties we appoint to perform services on our behalf, such as third party loyalty programs, web hosting, data analysis,
          marketing (also on websites of such third parties), customer care, credit card and payment services and related services. If you are
          enrolled in the DE Rantau Reception program as detailed in Terms of Use, DE Rantau may share your credit card data that you may have on file
          with DE Rantau with the property you have booked at but only to the extent necessary to allow the functioning of the DE Rantau Reception
          program and the charging by the accommodation for charges incurred by you at the relevant property. All such third parties will be under an
          obligation to maintain the security and confidentiality of the Personal Data, and to process the Personal Data only in accordance with our
          instructions.
        </TextCol>
        <TextCol>
          As mentioned in our Terms of Use and in order for DE Rantau to offer the best in class inventory to customers, DE Rantau may source
          accommodation from third party accommodation suppliers and as a result DE Rantau may share your Personal Data with such third party
          accommodation suppliers which may include other entities within the Priceline Group of companies e.g. Booking.com but only to the extent
          necessary to ensure that your DE Rantau reservation is reserved with the relevant accommodation. Further, and for the same purposes, such
          third party accommodation suppliers may also share your Personal Data to the same extent with the relevant accommodation that it has
          contracted with directly. In such case DE Rantau will continue to provide you with reservation and other support services in respect of such
          accommodation that you have reserved on an DE Rantau platform.
        </TextCol>
        <TextCol>
          In some cases, DE Rantau may require that you transact directly with a third party to obtain services, or when you use the Site to reserve
          an accommodation room or obtain another service (such as points under a third-party loyalty program), we may provide your Personal Data to
          that accommodation property or other involved third party.
        </TextCol>
        <TextCol>
          DE Rantau may propose other services (typically “add-ons”) to help plan your trip, to offer certain benefits (e.g. get points for third
          party loyalty or mileage programs) or for another purpose that may interest you. These services are offered by third party providers. As
          stated above, relevant data about this service may be shared with such third party providers to ensure optimal service.
        </TextCol>
        <TextCol>
          Business partners: We work with many business partners around the world. Some of these business partners distribute or advertise our
          services, as well as help our other business partners distribute and advertise their own travel-related services. These business partners
          may also include the Priceline corporate group, a group which includes booking.hostastay.com. This may mean that their services are
          integrated into our website/apps, they have been enabled to show you a customized advertisement, or the booking.hostastay.com online
          reservation services are integrated in their websites and/or apps.
        </TextCol>
        <TextCol>
          When you make a reservation on one of our business partners’ websites or apps, certain personal data that you give them will be forwarded to
          us. If customer service is provided by the business partner, booking.hostastay.com will share details relevant to your reservation with the
          business partners (as and when needed) in order to provide you with appropriate and efficient support. When you make a reservation through
          one of our business partners’ websites, the business partners may receive certain parts of your personal data related to the specific
          reservation. This is for their own internal purposes (such as analytical purposes) and, if requested by you, for the administration of
          loyalty programs or marketing.
        </TextCol>
        <TextCol>
          When you make a reservation on a business partners’ website, please also take the time to read their privacy policies if you wish to
          understand how these business partners may process your personal data. When we offer you other travel-related products and/or services, your
          information may be shared with the offering business partners to handle or administer your request. For fraud detection and prevention
          purposes and as strictly necessary, we may also exchange information about our users with business partners.
        </TextCol>
        <TextCol>
          Save for disclosures mentioned in this Privacy Policy or as required or permitted by law in any relevant jurisdiction as mentioned below and
          the disclosure of your Personal Data and information required for completing your booking with the relevant accommodation of your choice and
          the sharing of certain Personal Data with selected partners where relevant (your details will be used for reporting and analysis purposes
          only), we will not disclose your Personal Data to third parties without your consent. However, we reserve the right to disclose your
          Personal Data to our affiliated (group) companies (in and outside your home country), including our affiliated (group) companies’ employees
          and our trusted agents and representatives who have access to this information with our permission and who need to know or have access to
          this information to perform the service requested by you (including customer services) and internal (audit/compliance) investigations.
        </TextCol>
        <TextCol>
          DE Rantau may, in compliance with applicable law, disclose Personal Data to protect ourselves against liability, to respond to subpoenas,
          judicial processes, legitimate requests, warrants or equivalent by law enforcement officials or authorities, to investigate fraud or other
          wrongdoing or as otherwise required or necessary in order to comply with applicable law, protect our legitimate interests or to the
          purchasers in connection with any sale, assignment, or other transfer of all or a part of our business or company. We may also, in
          compliance with applicable law, disclose Personal Data to enforce or apply the terms and conditions applicable to our services or to protect
          the rights, property or safety of DE Rantau, our users or others.
        </TextCol>
        <TextCol>
          In the situations described above, the recipient of the Personal Data may be located in a jurisdiction that may have different standards of
          data protection compared to the laws in your home jurisdiction.
        </TextCol>
      </Column>
      <Column>
        <Subtitle>Our Policy Concerning Cookies, Page Tags and others</Subtitle>
        <TextCol>
          By visiting our Site, you consent to the usage of cookies, tags, pixels, software development kits (“SDKs”) and application program
          interfaces (“APIs”) as described below. Our Site uses session and persistent cookies as well as page tags to collect and store certain
          information about you. A cookie is a small piece of data that is sent to your computer from the Site and stored on your computer’s hard
          drive. A page tag is a piece of code which reads and writes cookie data. Cookies or page tags may be from DE Rantau and/or third party
          vendors we have chosen to work with. Session cookies disappear from your computer when you close the browser. Persistent cookies remain on
          your computer after the browser has been closed, but can be deleted at any time through your browser settings. The use of these cookies and
          page tags may vary, and are described in the appropriate sections below.
        </TextCol>
      </Column>
      <Column>
        <Subtitle>Opting Out</Subtitle>
        <TextCol>
          If you want to prevent your browser from accepting cookies, if you want to be informed whenever a cookie is stored on your computer or if
          you want to delete cookies from your computer, please make the necessary changes in your Internet browser settings, typically under the
          sections “Help” or “Internet Options”. If you disable or delete cookies in your Internet browser settings, you might not be able to access
          or use important functions or features of this Site, and you will be required to re-enter your log-in details. Please also note that opting
          out of a third party cookie (see below) does not mean that you will no longer receive or be subject to online advertising or marketing
          analysis. It means that the network from which you opted out will no longer deliver ads tailored to your web preferences and usage patterns.
          Please also note that if you clear all cookies on your browser, or use a different browser or computer, you will need to complete the
          opt-out procedure again.
        </TextCol>
      </Column>
      <Column>
        <Subtitle>Informational and Opt Out Tools</Subtitle>
        <TextCol>
          If you would like more information about the information collection practices of third-party advertising companies and your choices about
          not having this information used by these companies, or you would like to opt out of online preference marketing, you have the following
          opt-out options:
        </TextCol>

        <Column>
          <TextCol>NAI behavioural advertising program:</TextCol>
          <a href="http://www.networkadvertising.org/consumer/opt_out.asp">http://www.networkadvertising.org/consumer/opt_out.asp</a>
        </Column>

        <Column>
          <TextCol>and with regard to our mobile apps, you can use the following opt-out options:</TextCol>
          <a href="http://www.google.com/ads/preferences/html/intl/en/mobile-about.html">
            http://www.google.com/ads/preferences/html/intl/en/mobile-about.html
          </a>
          <TextCol>
            by emailing appsflyer at: <a href="mailto:privacy@appsflyer.com">privacy@appsflyer.com</a>
          </TextCol>
        </Column>

        <Column>
          <TextCol>To opt out of analytics programs, you can use the following tools:</TextCol>
          <a href="https://tools.google.com/dlpage/gaoptout?hl=en">https://tools.google.com/dlpage/gaoptout?hl=en</a>
          <a href="http://www.microsoft.com/en-us/privacystatement/default.aspx">http://www.microsoft.com/en-us/privacystatement/default.aspx</a>
          <a href="https://help.yahoo.com/kb/SLN4525.html">https://help.yahoo.com/kb/SLN4525.html</a>
          <a href="http://help.yahoo.co.jp/common/sys/sys-26.html">http://help.yahoo.co.jp/common/sys/sys-26.html</a>
          <a href="http://www.baidu.com/duty/safe_control.html">http://www.baidu.com/duty/safe_control.html</a>
        </Column>

        <TextCol>DE Rantau may add other relevant links from time to time to support your privacy rights.</TextCol>
      </Column>
      <Column>
        <Subtitle>DE Rantau Cookies</Subtitle>
        <TextCol>DE Rantau uses a session cookie to keep you logged in throughout your visit to the Site.</TextCol>
        <TextCol>
          DE Rantau uses persistent cookies to “personalize” the Site for each user by remembering information about the user’s visit to and
          activities on the Site, e.g., the language you selected, or your log-in details so that you do not need to re-enter them at your next visit
          to the Site.
        </TextCol>
        <TextCol>
          DE Rantau also uses persistent cookies or page tags to analyze your surfing behavior and your visit to the Site enabling us to improve the
          Site and our services and to tailor our advertising (via email, on our Site, and on other websites) according to the interests of our users.
          Such cookies and page tags gather information about the pages you visited on the Site and the searches you performed.
        </TextCol>
      </Column>
      <Column>
        <Subtitle>Website analytics</Subtitle>
        <TextCol>
          Third party companies may use persistent cookies to analyze your surfing behavior on and your visit to this Site as well as other web sites
          enabling us to improve the Site and our services. Website analytics platforms may be used to collect unidentifiable information for reports
          such as impression reporting, demographic reporting and interest reporting. To opt out of collection of information by third parties for
          analytic purposes, please click on one or more of the third party links in the above “Opt Out Tools” section.
        </TextCol>
      </Column>
      <Column>
        <Subtitle>Third Party Cookies, Page Tags and Hashed Data</Subtitle>
        <TextCol>
          Our third party advertising companies may place cookies or page tags on some of the pages you visit on the Site. These cookies or page tags
          are designed to collect non-personally identifiable information in order to analyze your interests and searches when visiting our Site and
          to serve you advertisements specifically tailored to your interests and searches when you are visiting our Site or other websites or when
          using mobile applications. The non-personally identifiable information collected through cookies and page tags may be shared with other
          third parties for the purpose of managing and targeting advertisements and for market research analysis. In addition, we may share one-way
          hashed (undecipherable) data with third party vendors using advertisement targeting products. Such third party vendors typically use hashed
          device identifiers to link such identifiers to their own users and to serve custom ads based on users’ prior visits to the Site. As a
          consequence, third party vendors may show our and/or our partners’ ads on other websites or mobile apps.
        </TextCol>
      </Column>
      <Column>
        <Subtitle>Advertising</Subtitle>
        <TextCol>
          DE Rantau may use a combination of the above cookies and page tags to tailor your experience with our Site, our mobile application and our
          ads to your preferences. We use display and search remarketing to tailor ad content for a better experience. Where other advertising may not
          require the use of cookies or page tags, third party data is used to determine when an DE Rantau ads will be shown.
        </TextCol>
        <TextCol>
          DE Rantau oda takes reasonable efforts to not collect, and to prevent the use of, personally identifiable information in third party
          advertising solutions, unless you have authorized this. To opt out of collection of information by third parties for advertising purposes,
          please click on one or more of the third party links in the above “Opt Out Tools” section.
        </TextCol>
      </Column>
      <Column>
        <Subtitle>Advertising and content on other websites</Subtitle>
        <TextCol>
          DE Rantau uses 3rd party tools to distribute ads on a variety of websites. DE Rantau neither supports nor endorses the goals, causes or
          statements of the websites which display our advertisements.
        </TextCol>
      </Column>
      <Column>
        <Subtitle>Information from other partners</Subtitle>
        <TextCol>
          On occasions affiliated entities, business partners or other third party providers may share information with us. One example, is if you
          access or sign in to our Site through social media such as Facebook, we may collect information from you such as your user name, image, list
          of friends and other information made available to us via such services. In general, the ability to provide such information is through the
          service provider themselves and dependent upon using the service provider’s single sign in application or other similar service. In general,
          we may work with our partners to use this information to improve and personalize your use of our Site in accordance with this policy.
        </TextCol>
        <TextCol>
          We have partnered with Facebook to provide personalized content on DE Rantau for members of Facebook. If you are a Facebook and DE Rantau
          user and you have signed into DE Rantau via the Facebook single sign on feature, then our Site will be personalized for you to display
          information/stays of your Facebook friends at accommodations in the past and also future stays at accommodations bookable through the Site,
          that is unless you have opted out of the same (see more information below). The information that may be displayed include name, the name of
          the accommodation, the data of stay, the location of the accommodation, your review of past accommodation, the homestay room and price of
          the homestay room. (“Stay Information”), and such information may be shown and personalized to you on the search pages of the accommodation
          on the Site, and the search calendar amongst other places. Further, your Stay Information will also be displayed to your Facebook friends
          who are also DE Rantau customers and who have signed in through the Facebook single sign on feature on the Site, as displayed on their
          search pages described above within the Site and updated each time you make a booking through DE Rantau.
        </TextCol>

        <TextCol>
          How to opt out: DE Rantau has made it simple for you to opt out of this feature at any time by: toggling “no” on
          <ol type="i">
            <li>the thank you page after you make a booking</li>
            <li>in your manage my booking page on the Site</li>
          </ol>
          Further, if you need help to opt out you may contact our customer experience team at any time.
        </TextCol>
      </Column>
      <Column>
        <Subtitle>How You Can Access or Change Your Personally Identifiable Information</Subtitle>
        <TextCol>
          f you wish to update any Personal Data you have previously provided, or to exercise any right of access, correction, rectification,
          deletion, or opposition to the processing of your Personal Data that you may have under applicable law, please contact us via our Customer
          Support page. To protect your privacy and security, we will verify your identity before granting access or making changes to your Personal
          Data. Any request to access your Personal Data will be answered within a reasonable timeframe. You may also object to the processing of your
          Personal Data for purposes of advertising and market and opinion research. We reserve the right to charge a reasonable fee for such access
          in accordance with applicable laws.
        </TextCol>
      </Column>
      <Column>
        <Subtitle>Mobile Devices</Subtitle>
        <TextCol>
          We have free apps for a variety of mobile devices and use versions of our regular website that have been optimized for mobile and tablet
          browsing. These apps and mobile websites process the personal details you give us in much the same way as our website does – and they also
          allow you to use location services to find accommodations nearby. With your consent, we may send you push notifications with information
          about your reservation, potential future stays or as part of any DE Rantau programs that you participate in, including coupons. You may
          grant us access to your location data or contact details in order to provide services requested by you. When you upload a picture from your
          mobile device, your picture may also be tagged with your location information. Please read the instructions of your mobile device to
          understand how to c or the receipt of (or opt out of receiving) push notifications (including SDK and push token data). Different device
          operating systems may have different default settings, so please familiarize yourself with such settings governing push notifications.
        </TextCol>
      </Column>
      conditions
      <Column>
        <Subtitle>Cross Device Tracking</Subtitle>
        <TextCol>
          We may make use of something known as cross-device tracking in order to optimize our services and marketing activities. This may be done
          with or without the use of cookies. For more information about cookies and other similar technologies, please see our Cookie paragraph
          above. With cross-device tracking, DE Rantau is able to track user behaviour across multiple devices. As part of cross-device tracking, DE
          Rantau may combine data collected from a particular browser or mobile device with another computer or device linked to the computer or
          device from which the data was collected.
        </TextCol>
        <TextCol>
          In order to optimize the content of DE Rantau communications to you, DE Rantau combines the searches and reservations you have made from
          different computers and devices. If you do not want DE Rantau to combine such data for its optimization of communications to you please
          unsubscribe from the DE Rantau communications i.e. the newsletter.
        </TextCol>
        <TextCol>
          Personalized advertisements shown to you on other websites or in apps, can be offered based on your activities on linked computers and
          devices. By opting out of the NAI behavioural advertising program (see in our Cookie paragraph above), you can opt out of cross-device
          tracking for advertisement purposes. You should know that logging out of your user account will not actually mean that you opt out of
          personalized advertisements.
        </TextCol>
        <TextCol>
          We may also use cross device tracking to optimize the content you view on our websites and/or apps. By opting out of the NAI behavioural
          advertising program (see in our Cookie paragraph above) and by logging out of your device or computer, you can opt out of cross-device
          tracking for these purposes.
        </TextCol>
      </Column>
      <Column>
        <Subtitle>A Special Note About Minors</Subtitle>
        <TextCol>
          Persons under the age of 18 are not eligible to use the accommodation reservation services on our Site. Minors can normally accompany their
          parent(s) or legal custodian(s) who have booked an accommodation, except where indicated otherwise in the accommodation property policies.
        </TextCol>
      </Column>
      <Column>
        <Subtitle>Language</Subtitle>
        <TextCol>
          This Privacy Policy has originally been drawn up in the English language. Translations in other languages are available for your
          convenience. In case of conflict between the English language version and a translated version, the English language version shall prevail.
        </TextCol>
      </Column>
      <Column>
        <Subtitle>Terms of Use</Subtitle>
        <TextCol>
          Please consult the DE Rantau Terms of Use for more details on using our Site. This Privacy Policy forms an integral part of our Terms of
          Use.
        </TextCol>
      </Column>
    </BannerContainer>
  );
};

export default Content;
