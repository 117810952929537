const destinations = [
  {
    state: 'KUALA LUMPUR',
    destination: [
      'RAMADA SUITE KLCC',
      'ARTE PLUS KLCC',
      'EKO CHERAS',
      'ANGGUN RESIDENCES BUKIT BINTANG',
      'BINJAI 8',
      'PH SUITES BY PAVILLION',
      'EXPRESSIONZ',
      'PARKVIEW RESIDENCE',
      'ONE RESIDENCE',
      ' MAISON 101',
      'THE ROBERTSON',
      'CEYLONZ SUITES'
    ]
  },
  {
    state: 'PUTRAJAYA',
    destination: ['CONEZION RESIDENCE']
  },
  {
    state: 'SELANGOR',
    destination: ['SUNWAY RESORT SUITES', 'PACIFIC 63']
  },
  {
    state: 'PAHANG',
    destination: ['SELESA HILLHOMES', 'GEO38', 'WINDMILL UPON HILLS', 'TIMURBAY BEACHFRONT RESIDENCE']
  },
  {
    state: 'JOHOR',
    destination: [
      'KSL D`ESPLANANDE RESIDENCE',
      'COUNTRY GARDEN DANGA BAY',
      'D`PRISTINE MEDINI',
      'R&F PRINCESS COVE',
      'SUASANA SuITES',
      'PINNACLE TOWER',
      'ESCADIA DESARU'
    ]
  },
  {
    state: 'PENANG',
    destination: ['TROPICANA 218 MACALISTER', 'THE LANDMARK']
  },
  {
    state: 'KEDAH',
    destination: ['THE LANGKAWI CLUB HOTEL']
  },
  {
    state: 'PERAK',
    destination: ['OCATAGON HOMESTAY']
  }
];

export default destinations;
