import styled from '@emotion/styled';

export const Mask = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000;
  opacity: 45%;
  position: absolute;
  z-index: 2000;
  top: 0;
  width: 100%;
  height: 100vh;
`;
