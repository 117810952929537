import React from 'react';
import { Col, Empty, Row, Skeleton } from 'antd';

import { useGetMemberSelfPackageOrders } from 'apis/member';

import TextSecondary from 'components/Font/TextSecondary/TextSecondary';
import Title from 'components/Font/Title/Title';

import { useFetchConstant } from 'hooks/constants';
import HostAStayLogo from 'images/hostastay-logo-with-white-background.png';

import { formatToDateWithMonthString } from 'utils/date';
import { constructDisplayPrice, getLabelOfConstant } from 'utils/general';

import {
  LogoCol,
  PackageCodeLabel,
  PackageColForNameAndCode,
  PackageColDivider,
  PackageDetailsRow,
  PackageInfoCol,
  PackageNameLabel,
  PackageNightLeftCol,
  PackageRow
} from './Packages.styles';

const Packages = () => {
  const { isLoading: isPackageOrdersLoading, data: packageOrders } = useGetMemberSelfPackageOrders();
  const { selection: packages, isLoading: isPackagesLoading } = useFetchConstant('packages');
  const isLoading = isPackagesLoading || isPackageOrdersLoading;

  return isLoading ? (
    <Skeleton />
  ) : (
    <Row gutter={[16, 32]}>
      {packageOrders && packageOrders.length > 0 ? (
        packageOrders.map(packageOrder => (
          <Col span={24} xxl={12}>
            <PackageRow>
              <Col span={24}>
                <Row>
                  <PackageColForNameAndCode span={18}>
                    <PackageNameLabel newLine>{getLabelOfConstant(packageOrder.packagePurchased, packages)}</PackageNameLabel>
                    <PackageColDivider />
                    <PackageCodeLabel>{packageOrder.packageCode}</PackageCodeLabel>
                  </PackageColForNameAndCode>
                  <LogoCol span={6}>
                    <img src={HostAStayLogo} alt="HostAStay - Travel and Do Good!" height="auto" width="80px" />
                  </LogoCol>
                </Row>
              </Col>
              <Col span={24}>
                <PackageDetailsRow>
                  <PackageInfoCol span={18}>
                    <Row gutter={[0, 4]}>
                      <Col span={24}>
                        <TextSecondary type="s">
                          <b>Total Nights Credit :</b> {packageOrder.nightsCredit}
                        </TextSecondary>
                      </Col>
                      <Col span={24}>
                        <TextSecondary type="s">
                          <b>Total Amount Paid :</b> {constructDisplayPrice(packageOrder.priceDetails.amount)}
                        </TextSecondary>
                      </Col>
                      <Col span={24}>
                        <TextSecondary type="s">
                          <b>Date Purchased :</b> {formatToDateWithMonthString(packageOrder.datePurchased)}
                        </TextSecondary>
                      </Col>
                    </Row>
                  </PackageInfoCol>
                  <PackageNightLeftCol span={6}>
                    <TextSecondary type="s" newLine>
                      Nights Left
                    </TextSecondary>
                    <Title>{packageOrder.nightsLeft}</Title>
                  </PackageNightLeftCol>
                </PackageDetailsRow>
              </Col>
            </PackageRow>
          </Col>
        ))
      ) : (
        <Row display="flex" justify="center" style={{ width: '100%' }}>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>You have not bought any packages yet.</span>} />
        </Row>
      )}
    </Row>
  );
};

export default Packages;
