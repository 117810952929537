import styled from '@emotion/styled';
import { Row, Button } from 'antd';
import BackgroundImg from 'images/background.svg';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url(${BackgroundImg});
  background-size: cover;
  background-position-y: bottom;
  width: 100vw;
  height: 100vh;
  padding: 7.5vh 7.5vw;
`;

export const TitleRow = styled(Row)`
  margin-bottom: ${props => props.theme.spacing.regular};
`;

export const TitleLabel = styled.label`
  font-size: 28px;
`;

export const TitleNameLabel = styled.label`
  font-weight: ${props => props.theme.fontWeight.bold};
`;

export const LoginRow = styled(Row)`
  width: 100%;
`;

export const LoginContainer = styled.div`
  width: 100%;
  padding: ${props => props.theme.spacing.md};
  border-radius: ${props => props.theme.spacing.xs};
  background-color: ${props => props.theme.color.white};
`;

export const LoginButton = styled(Button)`
  margin: 10px 0;
  width: 100%;
`;
