import styled from '@emotion/styled';
import { Row, Col } from 'antd';

export const Root = styled.div`
  @media (min-width: 1200px) {
    background-color: #098ca3;
    background-size: cover;
    background-position-y: bottom;
    padding: ${props => props.theme.spacing.md} 0;
    min-height: 85vh;
  }
`;

export const MainContainer = styled.div`
  background-color: white;
  box-shadow: 0 3px 8px ${props => props.theme.color.white}33;
  border-radius: 24px;
  margin: 0 auto;
  padding: ${props => props.theme.spacing.regular};
  width: 100%;

  @media (min-width: 576px) {
    padding: ${props => props.theme.spacing.md};
  }

  @media (min-width: 1200px) {
    width: 70%;
    padding: ${props => props.theme.spacing.xl};
  }
`;

export const TitleContainer = styled.div`
  margin-bottom: ${props => props.theme.spacing.xxs};

  @media (min-width: 768px) {
    margin-bottom: ${props => props.theme.spacing.sm};
  }
`;

export const BannerContainer = styled(Row)`
  margin-top: 24px;
`;

export const TextCol = styled(Col)`
  padding: 10px;
  text-align: justify;
`;

export const Column = styled(Col)`
  margin-top: 10px;
`;
