import styled from '@emotion/styled';
import { Col, Row } from 'antd';
import { DeleteOutlined, FormOutlined } from '@ant-design/icons';

export const DrawerHeader = styled(Row)`
  padding: ${props => props.theme.spacing.regular} ${props => props.theme.spacing.md};
`;

export const DrawerTotalPriceRow = styled(Row)`
  padding: ${props => props.theme.spacing.regular} ${props => props.theme.spacing.md} 0px ${props => props.theme.spacing.md};
`;

export const DrawerTotalPriceValueCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
`;

export const TitleRow = styled(Row)`
  display: flex;
  padding: ${props => props.theme.spacing.md};
  border: 0.5px solid #f0f0f0;
  margin: 0 !important;
`;

export const BoldRow = styled(Row)`
  font-weight: bold;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const BookingRowContainer = styled(Row)`
  padding: ${props => props.theme.spacing.regular} ${props => props.theme.spacing.sm} ${props => props.theme.spacing.xs}
    ${props => props.theme.spacing.regular};
`;

export const BookingRowPricingCol = styled(Col)`
  display: flex;
  justifycontent: flex-end;
`;

export const RemoveIcon = styled(DeleteOutlined)`
  font-size: 16px;
  margin-right: 4px;
`;

export const RemarksIcon = styled(FormOutlined)`
  font-size: 16px;
  margin-right: 4px;
`;

export const StyledTotalPrice = styled.span`
  text-decoration: ${props => (props.isValidDiscount ? 'line-through' : '')};
`;

export const EmptyBookingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70%;
`;

export const RootContainer = styled(Row)`
  background-color: ${props => props.theme.color.white};
  box-shadow: 0 1px 2px 0 #00000012, 0 2px 2px 0 #0000001a;
  margin-bottom: ${props => props.theme.spacing.sm};
`;

export const CoverImageCol = styled(Col)`
  position: relative;
`;

export const CoverImageContainer = styled.div`
  height: 120px;

  @media (min-width: 992px) {
    height: 100%;
  }
`;

export const FooterContainer = styled(Row)`
  border-top: 1px solid #d2d2d2;
  margin-top: ${props => props.theme.spacing.regular};
  padding: ${props => props.theme.spacing.xs} ${props => props.theme.spacing.regular};
`;
