import React from 'react';
import { Col, Row } from 'antd';

import Image from 'components/Image/BareImage/BareImage';
import Title from 'components/Font/Title/Title';
import DeRantauLogo from '../../../images/MD-De-Rantau.png';

import {
  CoverImageCol,
  CardTag,
  ContentContainer,
  CoverImageContainer,
  FooterContainer,
  HostLogoContainer,
  RootContainer
} from './ListingCard.styles';

const CardDetailBody = ({ name, subtitle, bodyDescription }) => {
  return (
    <Row gutter={[0, 12]}>
      <Col span={24}>
        <Row gutter={[0, 4]}>
          <Col span={24}>
            <Title type="xs">{name}</Title>
          </Col>
          <Col span={24}>{subtitle}</Col>
        </Row>
      </Col>
      <Col span={24}>{bodyDescription}</Col>
    </Row>
  );
};

const ListingCard = ({ image, images, imageMaxHeight, name, subtitle, bodyDescription, hostLogo, footer, tag, isDerantauCertified }) => {
  return (
    <RootContainer type="flex" align="center">
      <CoverImageCol span={24} lg={6}>
        {tag && <CardTag>{tag}</CardTag>}
        <CoverImageContainer imageMaxHeight={imageMaxHeight}>
          <Image image={image} images={images} alt="Cover image of the property" />
          {isDerantauCertified && (
            <Image image={DeRantauLogo} width={'60px'} height={'40px'} isAbsolute={true} top={'18px'} right={'18px'} alt="DE Rantau Badge" />
          )}
        </CoverImageContainer>
      </CoverImageCol>
      <Col span={24} lg={18}>
        <Row type="flex" align="center">
          <Col span={24}>
            <ContentContainer type="flex" {...(hostLogo && { align: 'center' })}>
              <Col order={2} span={24} md={{ span: hostLogo ? 19 : 24, order: 1 }}>
                <CardDetailBody name={name} subtitle={subtitle} bodyDescription={bodyDescription} />
              </Col>
              {hostLogo && (
                <HostLogoContainer order={1} span={24} md={{ span: 5, order: 2 }}>
                  <Image image={hostLogo} alt="Host logo" height="80px" margin="auto 0" />
                </HostLogoContainer>
              )}
            </ContentContainer>
          </Col>
          {footer && <FooterContainer span={24}>{footer}</FooterContainer>}
        </Row>
      </Col>
    </RootContainer>
  );
};

export default ListingCard;
