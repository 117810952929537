module.exports = {
  // General configuration
  PORT: process.env.PORT || '3002',
  NODE_ENV: process.env.NODE_ENV,
  // API configuration
  REACT_APP_API_ENDPOINT: process.env.REACT_APP_API_ENDPOINT || 'http://localhost:3001/v1',
  // HCaptcha configuration
  HCAPTCHA_SITE_KEY: '505fb5e4-f236-4562-9897-c4721ee32bba',
  // GA configuration
  REACT_APP_GTAG_ID: process.env.REACT_APP_GTAG_ID || '',
  // Stripe configuration
  STRIPE_KEY:
    process.env.REACT_APP_STRIPE_KEY || 'pk_test_51LrcJ5ER9HOCBT0RYgXDSvb1ev3dYQjKUAiyAzRTovw3X6l5hfgbNTmiY3PMpM2HnbtPZrYcrgIp5qGBN3xPe37N00Gbol9LyU',

  REACT_APP_URL: process.env.REACT_APP_URL || 'https://booking.hostastay.com',
  // toggle booking
  IS_BOOKING_ENABLE: process.env.REACT_APP_IS_BOOKING_ENABLE || true
};
