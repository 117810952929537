import React from 'react';

import { Text } from './Title.styles';

const Title = ({ className, children, type = 'default', newLine = true, color = 'default', weight = 'default' }) => {
  return (
    <Text className={className} type={type} newLine={newLine} color={color} weight={weight}>
      {children}
    </Text>
  );
};

export default Title;
