import moment from 'moment';

import {
  BOOK_DAY_LIMIT,
  DATE_FORMAT,
  DATE_WITH_WEEKDAY_FORMAT,
  FORMAL_DATE_FORMAT,
  DATE_DATE_TIME_FORMAT,
  MONTH_FORMAT,
  DATE_MONTH_STRING_FORMAT,
  WEEKENDS
} from 'utils/constants';

/* ----------------------------------------base moment related functions----------------------------------------------- */
export const getTodayMoment = () => moment().startOf('day');
export const getAfterDaysMoment = daysToAdd => moment().add(daysToAdd, 'day').startOf('day');
export const getTomorrowMoment = () => getAfterDaysMoment(1);
export const getOneWeekLaterMoment = () => getAfterDaysMoment(7);
export const getCurrentMoment = () => moment();
export const getCurrentMonth = () => moment().startOf('month');

export const addMoment = (date, amount, dateUnit, shouldReturnString) => {
  const dateMoment = moment(date);
  const updatedDateMoment = dateMoment.add(amount, dateUnit.toLowerCase());

  return shouldReturnString ? formatToDateString(updatedDateMoment) : updatedDateMoment;
};

export const checkIsMoment = date => {
  return moment.isMoment(date);
};

export const formatToDateString = date => {
  return !!date ? moment(date).format(DATE_FORMAT) : date;
};

export const formatToDateWeekdayString = date => {
  return !!date ? moment(date).format(DATE_WITH_WEEKDAY_FORMAT) : date;
};

export const formatToFormalDateString = date => {
  return !!date ? moment(date).format(FORMAL_DATE_FORMAT) : date;
};

export const formatToMonthString = date => {
  return !!date ? moment(date).format(MONTH_FORMAT) : date;
};

export const formatToDateTimeString = date => {
  return !!date ? moment(date).format(DATE_DATE_TIME_FORMAT) : date;
};

export const formatToMomentObject = date => {
  return !!date ? moment(date) : date;
};

export const formatToDateWithMonthString = date => {
  return !!date ? moment(date).format(DATE_MONTH_STRING_FORMAT) : date;
};

/* ----------------------------------------return boolean----------------------------------------------- */
export const checkIsDate = (type, date1, date2, canOverlap) => {
  const date1Moment = moment(date1);
  const date2Moment = moment(date2);
  const typeInLowerCase = type.toLowerCase();

  const checkIsDateSame = () => date1Moment.isSame(date2Moment);
  const checkIsDateBefore = () => date1Moment.isBefore(date2Moment);
  const checkIsDateAfter = () => date1Moment.isAfter(date2Moment);

  switch (typeInLowerCase) {
    case 'same':
      return checkIsDateSame();

    case 'before':
      return checkIsDateBefore() || (canOverlap && checkIsDateSame());

    case 'after':
      return checkIsDateAfter() || (canOverlap && checkIsDateSame());

    default:
      return false;
  }
};

export const checkIsDateInBetween = (currentDate, startDate, endDate, overlapStart, overlapEnd) => {
  return checkIsDate('after', currentDate, startDate, overlapStart) && checkIsDate('before', currentDate, endDate, overlapEnd);
};

export const isWeekend = date => {
  const day = moment(date).day();
  return WEEKENDS.includes(day);
};

/* --------------------------------------return day number--------------------------------------------- */
export const getDifferenceBetweenDate = (startDate, endDate) => {
  return moment(endDate).diff(moment(startDate), 'day');
};

/* ----------------------------------------return others----------------------------------------------- */
export const getBookingLimitDateMoment = () => addMoment(getCurrentMoment(), BOOK_DAY_LIMIT, 'day');

export const generateDateArray = (startDate, endDate) => {
  const formattedStartDate = moment(startDate).format(DATE_FORMAT);
  const formattedEndDate = moment(endDate).format(DATE_FORMAT);

  let pointer = formattedStartDate;
  let dateArr = [];

  while (pointer < formattedEndDate) {
    dateArr.push(pointer);
    pointer = moment(pointer).add(1, 'days').format(DATE_FORMAT);
  }
  return dateArr;
};
