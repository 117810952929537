import React, { useMemo } from 'react';
import { Col, Empty, Row, Skeleton, Tabs, Tooltip } from 'antd';
import { Link } from 'react-router-dom';

import { useGetMemberSelfBookings } from 'apis/member';

import TextSecondary from 'components/Font/TextSecondary/TextSecondary';
import Title from 'components/Font/Title/Title';
import ListingCard from 'components/Card/ListingCard/ListingCard';

import { useFetchConstant } from 'hooks/constants';

import { formatToDateWithMonthString } from 'utils/date';
import { constructDisplayPrice, getSingularOrPluralLabel, getLabelOfConstant } from 'utils/general';

import { BookingCodeLabel, StatusCol, StatusTag, StatusInfoIcon, StaysContentRow } from './Stays.styles';

const { TabPane } = Tabs;

const BOOKING_STATUS_EXPIRED_CODE = 'expired';
const BOOKING_STATUS_EXPIRED_LABEL = 'Expired';

const constructPastStatus = (status, statusesObj) => {
  return [statusesObj.PENDING.code, statusesObj.STRIPE_UNPAID.code].includes(status) ? BOOKING_STATUS_EXPIRED_CODE : status;
};

const useFetchBookingStatusesConstant = () => {
  const { selection, isLoading, data, ...otherProps } = useFetchConstant('bookingStatuses');

  // To add new Expired status that is only visible to Frontend
  const newSelection = useMemo(() => {
    if (!isLoading) {
      return [...selection, { value: BOOKING_STATUS_EXPIRED_CODE, label: BOOKING_STATUS_EXPIRED_LABEL }];
    }
    return selection;
  }, [isLoading, selection]);

  // To add new Expired status that is only visible to Frontend
  const newData = useMemo(() => {
    if (!isLoading) {
      return {
        ...data,
        EXPIRED: { code: BOOKING_STATUS_EXPIRED_CODE, label: BOOKING_STATUS_EXPIRED_LABEL }
      };
    }
    return data;
  }, [isLoading, data]);

  return {
    selection: newSelection,
    data: newData,
    isLoading,
    ...otherProps
  };
};

const ListingCardDescription = ({
  checkInDate,
  checkOutDate,
  bookingCode,
  bookingStatus,
  bookingStatuses,
  address,
  paymentMethod,
  paymentDetails,
  rooms
}) => {
  return (
    <Row gutter={[0, 4]}>
      <Col span={24}>
        <BookingCodeLabel>{bookingCode}</BookingCodeLabel>
      </Col>
      <Col span={24}>
        <TextSecondary type="s">{address}</TextSecondary>
      </Col>
      <Col span={24}>
        <TextSecondary type="s">
          <b>Stay Period: </b>
          {` ${formatToDateWithMonthString(checkInDate)} - ${formatToDateWithMonthString(checkOutDate)}`}
        </TextSecondary>
      </Col>
      <Col span={24}>
        <TextSecondary type="s">
          {paymentMethod === 'package' ? 'Redeemed with ' : 'Paid by '} {paymentMethod}
        </TextSecondary>
      </Col>
      <Col span={24} style={{ marginTop: '16px' }}>
        <Row gutter={[0, 8]}>
          {rooms.map(room => (
            <>
              <Col span={24}>
                <div>
                  <TextSecondary>
                    {room.count} x <b>{room.name}</b>{' '}
                    {room.remarks && (
                      <Tooltip title={room.remarks}>
                        <StatusInfoIcon />
                      </Tooltip>
                    )}
                  </TextSecondary>
                </div>
              </Col>
            </>
          ))}
        </Row>
      </Col>
      <Col span={24} style={{ marginTop: '16px' }}>
        <ListingCardFooter paymentDetails={paymentDetails} status={bookingStatus} statuses={bookingStatuses} />
      </Col>
    </Row>
  );
};

const ListingCardFooter = ({ paymentDetails, status, statuses }) => {
  const { type, totalPrice, nightRedeemed } = paymentDetails;
  return (
    <Row type="flex" justify="space-between">
      <Col span={12} md={20}>
        {type === 'package' ? (
          <Row>
            <Col span={24}>
              <Title type="s" newLine={false}>
                {nightRedeemed}
              </Title>
              {` Total ${getSingularOrPluralLabel(nightRedeemed, 'Night', { labelOnly: true })} Redeemed`}
            </Col>
          </Row>
        ) : (
          <Row>
            <Col span={24}>
              <Title type="s" newLine={false}>
                {constructDisplayPrice(totalPrice || 0, false)}
              </Title>{' '}
              Total in MYR
            </Col>
          </Row>
        )}
      </Col>
      {status && (
        <StatusCol span={12} md={4}>
          <StatusTag type={status}>{getLabelOfConstant(status, statuses)}</StatusTag>
        </StatusCol>
      )}
    </Row>
  );
};

const EmptyBooking = ({ desc, isLinkNeeded }) => {
  const linkToMainPage = <Link to={'/'}>Book now!</Link>;

  return (
    <div>
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={
          <span>
            {desc || "It's time to take a break and plan for a trip."} {isLinkNeeded && linkToMainPage}
          </span>
        }
      />
    </div>
  );
};

const Stays = () => {
  const { isLoading: isBookingsLoading, data: bookings } = useGetMemberSelfBookings();
  const { selection: statuses, isLoading: isStatusesLoading, data: statusesObj } = useFetchBookingStatusesConstant();
  const isLoading = isStatusesLoading || isBookingsLoading;

  return isLoading ? (
    <Skeleton />
  ) : (
    <Tabs defaultActiveKey="upcomingStays">
      <TabPane tab="Upcoming" key="upcomingStays">
        <StaysContentRow display="flex" justify="center">
          {bookings.upcoming && bookings.upcoming.length > 0 ? (
            bookings.upcoming.map(booking => {
              const { _id, property, rooms, startDate, endDate, images, paymentDetails, host, status } = booking;
              return (
                <ListingCard
                  key={_id}
                  image={images && images[0]}
                  images={images}
                  imageMaxHeight="400px"
                  name={property.name}
                  hostLogo={host && host.coverImageUrl}
                  bodyDescription={
                    <ListingCardDescription
                      address={property.address}
                      rooms={rooms}
                      bookingCode={booking.code}
                      paymentMethod={paymentDetails.type}
                      checkInDate={startDate}
                      checkOutDate={endDate}
                      paymentDetails={paymentDetails}
                      bookingStatus={status}
                      bookingStatuses={statuses}
                    />
                  }
                />
              );
            })
          ) : (
            <EmptyBooking isLinkNeeded />
          )}
        </StaysContentRow>
      </TabPane>
      <TabPane tab="Past" key="pastStays">
        <StaysContentRow display="flex" justify="center">
          {bookings.past && bookings.past.length > 0 ? (
            bookings.past.map(booking => {
              const { _id, property, rooms, startDate, endDate, images, paymentDetails, host, status } = booking;
              return (
                <ListingCard
                  key={_id}
                  image={images && images[0]}
                  images={images}
                  imageMaxHeight="400px"
                  name={property.name}
                  hostLogo={host && host.image}
                  bodyDescription={
                    <ListingCardDescription
                      address={property.address}
                      rooms={rooms}
                      bookingCode={booking.code}
                      paymentMethod={paymentDetails.type}
                      checkInDate={startDate}
                      checkOutDate={endDate}
                      paymentDetails={paymentDetails}
                      bookingStatus={constructPastStatus(status, statusesObj)}
                      bookingStatuses={statuses}
                    />
                  }
                />
              );
            })
          ) : (
            <EmptyBooking
              desc={bookings.upcoming && bookings.upcoming.length > 0 && 'It seems like you have upcoming vacations awaiting. Enjoy!'}
              isLinkNeeded={!(bookings.upcoming && bookings.upcoming.length > 0)}
            />
          )}
        </StaysContentRow>
      </TabPane>
    </Tabs>
  );
};

export default Stays;
