import styled from '@emotion/styled';
import { Form, Row, Card, Col } from 'antd';
import BackgroundImg from 'images/header-background.png';

export const HeaderContainerCard = styled.div`
  box-shadow: 2px 2px 5px #d4d4d4;
  display: flex;
  justify-content: center;
  background-image: url(${BackgroundImg});
  background-size: cover;
  background-position-y: center;
  width: 100%;

  position: ${props => (props.sticky ? 'fixed' : '')};
  z-index: ${props => (props.sticky ? 20 : '')};
`;

export const StickySearchContainer = styled(Row)`
  width: 100%;
  padding: ${props => props.theme.spacing.xs} ${props => props.theme.spacing.sm};

  @media (min-width: 768px) {
    width: 80%;
  }

  @media (min-width: 992px) {
    padding: ${props => props.theme.spacing.sm};
  }
`;

export const SearchContainerCard = styled(Card)`
  background: none;
  width: 100%;
  margin: 0 auto;

  @media (min-width: 768px) {
    width: 80%;
  }
`;

export const SearchBarFormTitle = styled.span`
  color: ${props => props.theme.color.white};
  font-weight: 500;
  font-size: 16px;
`;

export const LPSearchBarFormTitle = styled.span`
  color: ${props => props.theme.color.SecondaryFontColor};
  font-weight: 500;
  font-size: 16px;
`;

export const FullWidthHeightForm = styled(Form)`
  width: 100%;
  height: 100%;
`;

export const FormFieldsContainer = styled(Row)`
  display: flex;
  align-items: center;
`;

export const MiniSearchBarFieldTitle = styled.p`
  color: ${props => props.theme.color.white};
  margin-bottom: ${props => props.theme.spacing.xxs};
`;

export const MiniSearchBarFieldText = styled.p`
  color: ${props => props.theme.color.white};
  font-weight: ${props => props.theme.fontWeight.bold};
`;

export const StyledMainTitle = styled.p`
  font-size: 30px;
  font-weight: 700;
  color: ${props => props.theme.color.white};
  margin-bottom: 25px;

  @media (min-width: 768px) {
    font-size: 40px;
  }
`;

export const StyledSecondaryTitle = styled.p`
  font-size: 15px;
  margin-bottom: 0px;
  color: ${props => props.theme.color.white};

  @media (min-width: 768px) {
    font-size: 20px;
    margin-top: 3%;
  }
`;

export const MobileCertifiedCol = styled(Col)`
  display: block;

  @media (min-width: 768px) {
    display: none;
  }
`;

export const DesktopCertifiedCol = styled(Col)`
  display: none;

  @media (min-width: 768px) {
    display: block;

    .ant-col-md-8 {
      max-width: 100%;
    }
  }
`;
