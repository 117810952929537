import styled from '@emotion/styled';
import { Col, Row } from 'antd';

export const BannerContainer = styled(Row)`
  margin-top: 24px;
  padding: 32px 24px;
  box-shadow: 2px 4px 16px #e0e0e0;

  @media (min-width: 768px) {
    padding: 32px 60px;
  }
`;

export const CoverImageCol = styled(Col)``;

export const CoverImageContainer = styled.div`
  height: 320px;
`;

export const BannerTextCol = styled(Col)`
  padding: 0px 0px 16px;

  @media (min-width: 768px) {
    padding: 0px 32px 0px 0px;
  }
`;

export const TextRow = styled(Row)`
  height: auto;
`;
