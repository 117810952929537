import React, { useState, useEffect } from 'react';
import { Row, Form, Modal, Col, Divider, Tooltip } from 'antd';
import { UserOutlined, KeyOutlined } from '@ant-design/icons';

import FormGroupSelectionInput from 'components/FormGroupSelectionInput/FormGroupSelectionInput';
import FormInput from 'components/Input/FormInput/FormInput';
import { withAppContext } from 'contexts/AppContext/AppContext';
import { postSignUp } from 'apis/auth';
import { SS_WP_BASE_URL } from 'utils/constants';
import { useFetchConstant } from 'hooks/constants';

import { ErrorMessage, TitleRow, TitleLabel, SignUpButton, Backdrop, LoginTextButton, FootnoteText, PasswordInfoIcon } from './SignupModal.styles';

const { useForm } = Form;

const PasswordRequirements = () => {
  return (
    <Row>
      <Col>Password must have:</Col>
      <Col>- at least one alphabet</Col>
      <Col>- at least one number</Col>
      <Col>- length between 8 and 20</Col>
    </Row>
  );
};

const SignupModal = ({ visible, onClose, onLoginButtonClick, onLogin }) => {
  const [form] = useForm();
  const [isRegistering, setIsRegistering] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { selection: countryCodes, data: countriesConst } = useFetchConstant('countries', {
    query: { isFetchingAllCountries: true },
    valueKey: 'phoneCode',
    labelKey: 'phoneCode'
  });

  useEffect(() => {
    if (countriesConst) {
      form.setFieldsValue({ contact: { countryCode: countriesConst.MALAYSIA.phoneCode } });
    }
  }, [form, countriesConst]);

  const handleOnFormSubmitAndValidated = values => {
    setIsRegistering(true);
    setErrorMessage('');

    postSignUp(values)
      .then(() => {
        onLogin(values.email, values.password);
        onClose();
      })
      .catch(ex => setErrorMessage(ex.message))
      .finally(() => setIsRegistering(false));
  };

  const Footer = () => {
    return (
      <Row>
        <Divider />
        <span style={{ fontSize: 12 }}>Already have an account?</span>
        <LoginTextButton showIcon={false} visualVisible text="Login now!" onClick={onLoginButtonClick} />
      </Row>
    );
  };

  const Footnote = () => {
    return (
      <FootnoteText>
        By signing up, you agree to our{' '}
        <a href={`${SS_WP_BASE_URL}/terms-conditions`} target="_blank" rel="noopener noreferrer">
          Terms and Conditions
        </a>{' '}
        and{' '}
        <a href={`${SS_WP_BASE_URL}/privacy-policy`} target="_blank" rel="noopener noreferrer">
          Privacy Policy
        </a>
      </FootnoteText>
    );
  };

  return (
    <Modal visible={visible} footer={null} keyboard={false} destroyOnClose width={800} bodyStyle={{ padding: 0 }} onCancel={onClose}>
      <Row>
        <Col span={24} md={11}>
          <Backdrop>
            <span>One Year</span>
            <span>Unlimited Deals</span>
            <span>for new members</span>
          </Backdrop>
        </Col>
        <Col span={24} md={13} style={{ padding: 20 }}>
          <TitleRow>
            <TitleLabel>Sign Up</TitleLabel>
          </TitleRow>
          <Form form={form} onFinish={handleOnFormSubmitAndValidated} preserve={false}>
            <FormInput
              label="Full Name"
              prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Your name"
              name="name"
              requiredErrorMessage="Please enter your name"
            />
            <FormInput
              label="Email"
              prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Your email"
              name="email"
              requiredErrorMessage="Please enter your email"
            />
            <FormGroupSelectionInput
              label="Contact Number"
              name="contact"
              inputName="contactNumber"
              selectionName="countryCode"
              inputType="number"
              selections={countryCodes}
              requiredErrorMessage="Please enter your contact number in the correct format"
              inputPlaceholder="123456789"
              selectionPlaceholder="+60"
            />
            <FormInput
              label={
                <Tooltip title={<PasswordRequirements />} placement="right">
                  Password <PasswordInfoIcon />
                </Tooltip>
              }
              prefix={<KeyOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Your password"
              type="password"
              name="password"
              requiredErrorMessage="Please enter your password"
              isNewPassword
            />
            {errorMessage && <ErrorMessage message={errorMessage} type="error" />}
            <Row>
              <SignUpButton htmlType="submit" type="primary" disabled={isRegistering} loading={isRegistering}>
                Sign Up
              </SignUpButton>
            </Row>
            <Footnote />
          </Form>
          <Footer />
        </Col>
      </Row>
    </Modal>
  );
};

export default withAppContext(SignupModal);
