import styled from '@emotion/styled';
import { CheckCircleFilled } from '@ant-design/icons';

export const ContainerDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 70vh;
  color: ${props => props.theme.color.text};
`;

export const TickIcon = styled(CheckCircleFilled)`
  color: ${props => props.theme.color.green} !important;
  font-size: 48px;

  @media (min-width: 768px) {
    font-size: 60px;
  }
`;

export const TitleContainer = styled.span`
  font-size: 24px;
  font-weight: ${props => props.theme.fontWeight.bolder};
  margin: 24px 0px 16px;
  text-align: center;

  @media (min-width: 768px) {
    font-size: 36px;
  }
`;

export const ConfirmationCodeContainer = styled.span`
  font-size: 24px;
  padding: ${props => props.theme.spacing.regular} 0;
  text-align: center;
  color: ${props => props.theme.color.secondary};
  font-weight: ${props => props.theme.fontWeight.bold};

  @media (min-width: 768px) {
    font-size: 32px;
  }
`;

export const DescriptionContainer = styled.span`
  font-size: 16px;
  text-align: center;

  @media (min-width: 768px) {
    font-size: 20px;
  }
`;
