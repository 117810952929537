import styled from '@emotion/styled';

export const StyledImage = styled.img`
  margin: ${props => props.margin || 'auto'};
  height: ${props => props.height || '100%'};
  width: ${props => (props.width || props.height ? 'auto' : '100%')};
  max-height: ${props => props.maxHeight || '100%'};
  cursor: ${props => (props.hasCursor ? 'pointer' : 'default')};
  object-fit: cover !important;
  position: ${props => (props.isAbsolute ? 'absolute' : 'static')};
  top: ${props => props.top || '0'};
  right: ${props => props.right || '0'};
`;
