import React, { useState } from 'react';
import { Row, Form, Modal, Col, Divider } from 'antd';
import { MailOutlined, KeyOutlined } from '@ant-design/icons';

import FormInput from 'components/Input/FormInput/FormInput';
import { withAppContext } from 'contexts/AppContext/AppContext';

import { ForgetPasswordButton, TitleRow, TitleLabel, LoginButton, Backdrop, RegisterButton, ErrorMessage } from './LoginModal.styles';

const { useForm } = Form;

const LoginModal = ({ visible, onLogin, isLoadingMember, onClose, onRegisterButtonClick, onForgetPasswordClick, onFirstTimeLoginClick }) => {
  const [form] = useForm();
  const [errorMessage, setErrorMessage] = useState('');

  const handleOnFormSubmitAndValidated = ({ email, password }) => {
    setErrorMessage('');
    onLogin(email, password)
      .then(() => onClose())
      .catch(ex => {
        setErrorMessage(ex.message);
      });
  };

  const Footer = () => {
    return (
      <Row>
        <Divider />
        <span style={{ fontSize: 12 }}>Not a member yet?</span>
        <RegisterButton showIcon={false} text="Sign Up now!" onClick={onRegisterButtonClick} visualVisible />
      </Row>
    );
  };

  return (
    <Modal visible={visible} footer={null} keyboard={false} destroyOnClose width={800} bodyStyle={{ padding: 0 }} onCancel={onClose}>
      <Row>
        <Col span={24} md={11}>
          <Backdrop>
            <span>One Year</span>
            <span>Unlimited Deals</span>
            <span>for new members</span>
          </Backdrop>
        </Col>
        <Col span={24} md={13} style={{ padding: 20 }}>
          <TitleRow>
            <TitleLabel>Login</TitleLabel>
          </TitleRow>
          <Form form={form} onFinish={handleOnFormSubmitAndValidated} preserve={false}>
            <FormInput
              label="Email"
              prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Your email"
              name="email"
              requiredErrorMessage="Please enter your email"
            />
            <FormInput
              label="Password"
              prefix={<KeyOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Your password"
              type="password"
              name="password"
              requiredErrorMessage="Please enter your password"
            />
            {errorMessage && <ErrorMessage type="error" message={errorMessage} />}
            <ForgetPasswordButton showIcon={false} text="First time login?" onClick={onFirstTimeLoginClick} />
            <ForgetPasswordButton showIcon={false} text="Forgot your password?" onClick={onForgetPasswordClick} />
            <Row>
              <LoginButton htmlType="submit" type="primary" disabled={isLoadingMember} loading={isLoadingMember}>
                Login
              </LoginButton>
            </Row>
          </Form>
          <Footer />
        </Col>
      </Row>
    </Modal>
  );
};

export default withAppContext(LoginModal);
