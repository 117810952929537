import React from 'react';
import { StyledCol, StyledContainer, StyledSecondaryTitle, StyledContent, DestinationWrapperColumn } from './PopularDestinations.styles';
import { Button } from 'antd';
import SectionTitle from 'components/Font/SectionTitle/SectionTitle';
import destinations from './helpers';
import TextSecondary from 'components/Font/TextSecondary/TextSecondary';
import Subtitle from 'components/Font/Subtitle/Subtitle';

const PopularDestinations = ({ redirectToBooking }) => {
  return (
    <StyledCol>
      <SectionTitle primary={'Popular'} secondary={'Destination'} />
      <StyledSecondaryTitle>10+ Destinations | 30+ Locations | 1000+ Rooms</StyledSecondaryTitle>

      <StyledContainer>
        <StyledContent>
          {destinations.map((item, index) => {
            const { destination } = item;
            return (
              <div key={`state${index}`}>
                <DestinationWrapperColumn key={item.state}>
                  <Subtitle type="s">{item.state}</Subtitle>
                  {destination.map((place, index) => (
                    <span key={`dest${index}`} style={{ margin: '12px' }}>
                      <TextSecondary newLine={true} type="s">
                        {place}
                      </TextSecondary>
                    </span>
                  ))}
                </DestinationWrapperColumn>
              </div>
            );
          })}
          <Button type="primary" onClick={() => redirectToBooking()}>
            VIEW MORE ROOMS
          </Button>
        </StyledContent>
      </StyledContainer>
    </StyledCol>
  );
};

export default PopularDestinations;
