export const LearnMore = [
  {
    text: 'About HostAStay',
    routes: 'https://hostastay.com/'
  },
  // {
  //   text: 'How It Works',
  //   routes: '/404'
  // },
  // {
  //   text: 'Frequently Asked Question',
  //   routes: '/404'
  // },
  {
    text: 'Terms & Conditions',
    routes: '/terms-conditions'
  },
  {
    text: 'HostAStay Booking Privacy Policy',
    routes: '/privacy-policy'
  }
];

export const ContactUs = [
  {
    content: ['HostAStay Sdn. Bhd. Tower 4 - PFCC,', 'Jalan Puteri 1/2. Bandar Puteri,', 'Puchong 47100 Selangor Malaysia'],
    icon: 'location'
  },
  {
    content: ['1800 88 7829 (ext. 20)', 'Mon-Fri : 09:00 - 18:00'],
    icon: 'phone'
  },
  {
    content: ['HostAStay Hoteline'],
    link: ' +60 19 597 1989',
    href: 'https://wa.me/60195971989',
    icon: 'whatsapp'
  },
  {
    content: [''],
    link: 'reservations@booking-hostastay.com',
    href: 'mailto:reservations@booking-hostastay.com',
    icon: 'email'
  }
];
