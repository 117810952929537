import React from 'react';
import { FullWidthHeightForm, FormFieldsContainer, SearchBarFormTitle } from '../SearchBar.styles';

import FormInputNumber from 'components/InputNumber/FormInputNumber/FormInputNumber';
import { REGEX_NUMBER_N_DOT } from 'utils/constants';
import FormSelection from 'components/FormSelection/FormSelection';
import FormDateRangePicker from 'components/DateRangePicker/FormDateRangePicker/FormDateRangePicker';

import { Col, Button } from 'antd';

const PropertiesForm = ({
  form,
  state,
  checkInDate,
  checkOutDate,
  stateMYSelection,
  adultPax,
  childPax,
  sortingCriteria,
  getSingularOrPluralLabel,
  sortingCriteriaSelection,
  handleOnSearchButtonClick,
  isDerantauCertifiedSelection
}) => {
  return (
    <FullWidthHeightForm
      form={form}
      initialValues={{
        state,
        dateRange: [checkInDate, checkOutDate],
        adultPax,
        childPax,
        sortingCriteria
      }}
    >
      <FormFieldsContainer gutter={16}>
        <Col span={24} md={8}>
          <FormDateRangePicker
            name="dateRange"
            label={<SearchBarFormTitle>Stay Period</SearchBarFormTitle>}
            placeholder={['Check-in', 'Check-out']}
          />
        </Col>
        <Col span={12} md={4}>
          <FormInputNumber
            name="adultPax"
            label={<SearchBarFormTitle>Adult</SearchBarFormTitle>}
            minValue={1}
            maxValue={36}
            formatter={value => getSingularOrPluralLabel(value, 'Adult')}
            parser={value => value.replace(REGEX_NUMBER_N_DOT, '')}
          />
        </Col>
        <Col span={12} md={4}>
          <FormInputNumber
            name="childPax"
            label={<SearchBarFormTitle>Child</SearchBarFormTitle>}
            minValue={0}
            maxValue={18}
            formatter={value => getSingularOrPluralLabel(value, 'Child', { pluralLabel: 'Children' })}
            parser={value => value.replace(REGEX_NUMBER_N_DOT, '')}
          />
        </Col>
        <Col span={24} md={8}>
          <FormSelection
            name="state"
            label={<SearchBarFormTitle>Destination</SearchBarFormTitle>}
            selections={stateMYSelection}
            placeholder="Select state of your destination"
          />
        </Col>
      </FormFieldsContainer>
      <FormFieldsContainer gutter={16} justify="space-between">
        <Col span={24} md={8}>
          <FormSelection
            name="sortingCriteria"
            label={<SearchBarFormTitle>Price</SearchBarFormTitle>}
            selections={sortingCriteriaSelection}
            isAllowClear={false}
          />
        </Col>
        <Col span={24} md={8}>
          {/* <FormSelection
            name="isDerantauCertified"
            label={<SearchBarFormTitle>DE Rantau Certified Units: </SearchBarFormTitle>}
            selections={isDerantauCertifiedSelection}
            placeholder="All"
          /> */}
        </Col>
        <Col span={24} md={6}>
          <Button type="primary" size={'large'} onClick={handleOnSearchButtonClick} block>
            Search
          </Button>
        </Col>
      </FormFieldsContainer>
    </FullWidthHeightForm>
  );
};

export default PropertiesForm;
