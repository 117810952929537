import styled from '@emotion/styled';
import { Row, Col, Card } from 'antd';

export const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border-radius: 20px;
  margin-top: 20px;
  padding-top: 40px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
`;
export const StyledRow = styled(Row)`
  padding-top: 20px;
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }
`;

export const StyledCol = styled(Col)`
  padding-top: 7%;
  text-align: center;
`;

export const StyledMainTitle = styled.p`
  font-size: 16px;
  font-weight: 700;
  color: ${props => props.theme.color.paragraphFontColor};
`;
export const StyledSecondaryTitle = styled.p`
  font-size: 13px;
  color: ${props => props.theme.color.paragraphFontColor};
`;
