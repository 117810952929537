import React from 'react';
import { StyledRow, StyledCol, StyledDivider, FooterWrapper, StyledText, StyledLink } from './Footer.styles';
import { Col } from 'antd';
import BareImage from 'components/Image/BareImage/BareImage';
import MasterCardLogo from './Images/MasterCard.png';
import VisaLogo from './Images/Visa.png';
import StripeLogo from './Images/Stripe.png';
import Subtitle from 'components/Font/Subtitle/Subtitle';
import { LearnMore, ContactUs } from './helpers';
import ExternalLink from 'components/ExternalLink/ExternalLink';

const Footer = () => {
  return (
    <>
      <StyledCol>
        <StyledDivider />
        <StyledRow>
          <div>
            <BareImage image={MasterCardLogo} width={66} height={38} />
            <BareImage image={VisaLogo} width={82} height={25} />
            <BareImage image={StripeLogo} width={88} height={49} />
          </div>
        </StyledRow>

        <FooterWrapper>
          <Col>
            <Subtitle>Learn More</Subtitle>
            {LearnMore.map(item => {
              return (
                <ExternalLink to={item.routes} key={item.text}>
                  <StyledLink>{item.text}</StyledLink>
                </ExternalLink>
              );
            })}
          </Col>

          <Col>
            <Subtitle>Contact Us</Subtitle>
            {ContactUs.map((item, index) => {
              const { content } = item;
              return (
                <span key={`contactUs${index}`}>
                  <BareImage image={require(`./Images/${item.icon}.png`)} width={20} height={20} />
                  <div>
                    {content?.map((text, index) => (
                      <div key={`contactCntnt${index}`}>
                        {text && <StyledText>{text}</StyledText>}
                        <a href={item.href} target="_blank" rel="noopener noreferrer">
                          {item.link}
                        </a>
                      </div>
                    ))}
                  </div>
                </span>
              );
            })}
          </Col>
        </FooterWrapper>
      </StyledCol>
    </>
  );
};

export default Footer;
