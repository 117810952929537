import React from 'react';
import PropTypes from 'prop-types';

import Image, { NoImage } from '../BareImage/BareImage';

import {
  StyledCarousel,
  StyledImageContainer,
  StyledLeftArrow,
  StyledLeftArrowContainer,
  StyledRightArrow,
  StyledRightArrowContainer
} from './BareImageCarousel.styles';

const LeftArrow = ({ onClick }) => (
  <StyledLeftArrowContainer onClick={onClick}>
    <StyledLeftArrow />
  </StyledLeftArrowContainer>
);

const RightArrow = ({ onClick }) => (
  <StyledRightArrowContainer onClick={onClick}>
    <StyledRightArrow />
  </StyledRightArrowContainer>
);

const BareImageCarousel = ({ className, images, alt, initialImageIndex = 0, onClick, hasCursor }) => {
  const handleOnImageClick = index => event => {
    onClick({ event, index });
  };

  return (
    <StyledCarousel
      infinite
      arrows
      draggable
      prevArrow={<LeftArrow />}
      nextArrow={<RightArrow />}
      initialSlide={initialImageIndex}
      hasCursor={hasCursor}
    >
      {images && images.length > 0 ? (
        images.map((image, index) => (
          <StyledImageContainer className={className} key={`${alt} ${image} ${index}`}>
            <Image image={image} alt={`${alt} image ${index}`} onClick={handleOnImageClick(index)} />
          </StyledImageContainer>
        ))
      ) : (
        <StyledImageContainer className={className}>
          <NoImage alt={`${alt} has no image`} onClick={onClick} />
        </StyledImageContainer>
      )}
    </StyledCarousel>
  );
};

BareImageCarousel.propTypes = {
  images: PropTypes.array.isRequired,
  alt: PropTypes.string.isRequired,
  initialImageIndex: PropTypes.number,
  onClick: PropTypes.func,
  hasCursor: PropTypes.bool
};

BareImageCarousel.defaultProps = {
  initialImageIndex: 0,
  onClick: () => {},
  hasCursor: false
};

export default BareImageCarousel;
