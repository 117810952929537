import React from 'react';
import PropTypes from 'prop-types';

import { StyledInputNumber } from './InputNumber.styles';

const InputNumber = ({
  placeholder,

  minValue,
  maxValue,
  formatter,
  parser,
  precision,
  isDisabled,

  onChange,
  updateFormValue,

  ...props
}) => {
  const handleOnChange = value => {
    onChange(value);
    updateFormValue(value);
  };

  return (
    <StyledInputNumber
      placeholder={placeholder}
      min={minValue}
      max={maxValue}
      formatter={formatter}
      parser={parser}
      precision={precision}
      disabled={isDisabled}
      onChange={handleOnChange}
      {...props}
    />
  );
};

InputNumber.propTypes = {
  placeholder: PropTypes.string,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  formatter: PropTypes.func,
  parser: PropTypes.func,
  precision: PropTypes.number,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func,
  updateFormValue: PropTypes.func
};

InputNumber.defaultProps = {
  placeholder: '',
  minValue: 1,
  maxValue: 100,
  formatter: undefined,
  parser: undefined,
  precision: 0,
  isDisabled: false,
  onChange: () => {},
  updateFormValue: () => {}
};

export default InputNumber;
