import React from 'react';
import PropTypes from 'prop-types';

import FormItem from 'components/FormItem/FormItem';
import DateRangePicker from 'components/DateRangePicker/DateRangePicker/DateRangePicker';

const FormDateRangePicker = ({
  name,
  placeholder,
  label,

  requiredErrorMessage,

  isDisabled,

  onChange,
  ...props
}) => {
  const isAllowClear = !requiredErrorMessage;

  return (
    <FormItem name={name} label={label} requiredErrorMessage={requiredErrorMessage}>
      <DateRangePicker placeholder={placeholder} allowClear={isAllowClear} disabled={isDisabled} onChange={onChange} {...props} />
    </FormItem>
  );
};

FormDateRangePicker.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.array,
  requiredErrorMessage: PropTypes.string,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func
};

FormDateRangePicker.defaultProps = {
  isDisabled: false,
  requiredErrorMessage: '',
  onChange: () => {}
};

export default FormDateRangePicker;
