import React, { useMemo, useState } from 'react';

import NoImagePlaceholder from 'images/no-image-placeholder.jpg';

import ImageGallery from '../ImageGallery/ImageGallery';

import { StyledImage } from './BareImage.styles';

const BareImage = ({ image, images, alt, height, width, margin, maxHeight, onClick = () => {}, isAbsolute, top, right }) => {
  const [isImageGalleryVisible, setIsImageGalleryVisible] = useState(false);
  const hasGallery = useMemo(() => !!images && images.length > 0, [images]);

  const handleOnImageClick = () => {
    if (hasGallery) {
      setIsImageGalleryVisible(true);
    }

    onClick();
  };

  const handleOnCloseImageGallery = () => {
    if (hasGallery) {
      setIsImageGalleryVisible(false);
    }
  };

  return (
    <>
      {!!image && (
        <StyledImage
          key={image}
          alt={alt}
          src={image}
          height={height}
          width={width}
          margin={margin}
          maxHeight={maxHeight}
          hasCursor={hasGallery}
          onClick={handleOnImageClick}
          isAbsolute={isAbsolute}
          top={top}
          right={right}
        />
      )}
      {!image && <NoImage alt={`${alt} is not found`} onClick={handleOnImageClick} />}
      {isImageGalleryVisible && <ImageGallery images={images} alt={alt} onClose={handleOnCloseImageGallery} />}
    </>
  );
};

export default BareImage;

export const NoImage = ({ alt, onClick }) => {
  return <StyledImage alt={`${alt} is not found`} src={NoImagePlaceholder} onClick={onClick} />;
};
