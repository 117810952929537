import styled from '@emotion/styled';
import { Layout } from 'antd';
import BackgroundImg from './images/background.jpg';

const { Header, Sider, Content } = Layout;

export const Root = styled.div`
  @media (min-width: 1200px) {
    /* background-image: url(${BackgroundImg}); */
    background-color: #F8F8F8;
    background-size: cover;
    background-position-y: bottom;
    padding: ${props => props.theme.spacing.md} 0;
    min-height: 85vh;
  }
`;

export const MainContainer = styled.div`
  background-color: white;
  box-shadow: 0 3px 8px ${props => props.theme.color.white}33;
  border-radius: 24px;
  margin: 0 auto;
  padding: ${props => props.theme.spacing.regular};
  width: 100%;

  @media (min-width: 576px) {
    padding: ${props => props.theme.spacing.md};
  }

  @media (min-width: 1200px) {
    width: 70%;
    padding: ${props => props.theme.spacing.xl};
  }
`;

export const TitleContainer = styled.div`
  margin-bottom: ${props => props.theme.spacing.xxs};

  @media (min-width: 768px) {
    margin-bottom: ${props => props.theme.spacing.sm};
  }
`;

export const MemberAccPageLayout = styled(Layout)`
  background-color: ${props => props.theme.color.white};
`;

export const MemberAccPageLayoutHeader = styled(Header)`
  background-color: ${props => props.theme.color.white};
  padding: 0 ${props => props.theme.spacing.regular};
  margin-bottom: ${props => props.theme.spacing.regular};
`;

export const MemberAccPageLayoutSider = styled(Sider)`
  background-color: ${props => props.theme.color.white};
`;

export const MemberAccPageLayoutContent = styled(Content)`
  @media (min-width: 576px) {
    padding: 0 ${props => props.theme.spacing.md};
  }
`;
