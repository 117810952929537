import React from 'react';
import { Col, Row } from 'antd';

import { withAppContext } from 'contexts/AppContext/AppContext';

// import ClickableTextButton from 'components/ClickableTextButton/ClickableTextButton';
import Title from 'components/Font/Title/Title';

import Content from './components/Content';

import { Root, MainContainer, TitleContainer } from './Privacy.styles';

const TermsAndCondition = () => {
  return (
    <Root>
      <Row>
        {/* Note: Remember to add to both mobile view and desktop view for any changes */}
        <Col span={0} sm={24}>
          <MainContainer>
            <TitleContainer>
              <Title>DE Rantau Privacy Policy </Title>
            </TitleContainer>
            <Content />
          </MainContainer>
        </Col>
        <Col span={24} sm={0}>
          <MainContainer>
            <TitleContainer>
              <Title>DE Rantau Privacy Policy </Title>
            </TitleContainer>
            <Content />
          </MainContainer>
        </Col>
      </Row>
    </Root>
  );
};

export default withAppContext(TermsAndCondition);
