import React, { useCallback, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { message } from 'antd';

import { checkIsStringTrue, constructQueryAsObject } from 'utils/general';
import { buildBaseUri, buildThankYouUri } from 'utils/routes';

const useRedirectUser = (history, urlParams) => {
  const redirectUser = useCallback(() => {
    const { confirmationCode, isPaymentCompleted: isPaymentCompletedInString } = constructQueryAsObject(urlParams);
    const isPaymentCompleted = checkIsStringTrue(isPaymentCompletedInString);

    if (isPaymentCompleted && !!confirmationCode) {
      history.push(buildThankYouUri(), { confirmationCode });
      return;
    }

    message.warning('Payment was not success, please try again');
    history.replace(`${buildBaseUri()}`);
  }, [history, urlParams]);

  useEffect(() => {
    redirectUser();
  }, [redirectUser]);
};

const StripePayment = () => {
  const history = useHistory();
  const { search: urlParams } = useLocation();

  useRedirectUser(history, urlParams);

  return <div />;
};

export default StripePayment;
