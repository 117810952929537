import React, { useState, useCallback } from 'react';

const UniversalModalContext = React.createContext();

const useModal = name => {
  const [isVisible, setIsVisible] = useState(false);

  const handleOnModalVisibleChange = useCallback(
    (newOpenStatus = !isVisible) => {
      setIsVisible(newOpenStatus);
    },
    [isVisible]
  );

  return { isVisible, handleOnModalVisibleChange };
};

export const UniversalModalContextProvider = ({ children }) => {
  const { isVisible: isLoginVisible, handleOnModalVisibleChange: handleOnLoginModalVisibleChange } = useModal('login');
  const { isVisible: isSignupVisible, handleOnModalVisibleChange: handleOnSignupModalVisibleChange } = useModal('signup');

  return (
    <UniversalModalContext.Provider
      value={{
        loginModalProps: { isVisible: isLoginVisible, onModalVisibleChange: handleOnLoginModalVisibleChange },
        signupModalProps: { isVisible: isSignupVisible, onModalVisibleChange: handleOnSignupModalVisibleChange }
      }}
    >
      {children}
    </UniversalModalContext.Provider>
  );
};

export const UniversalModalContextConsumer = UniversalModalContext.Consumer;

export const withUniversalModalContext = Component => {
  const UniversalModalContextComponent = props => (
    <UniversalModalContextConsumer>
      {modalProps => {
        return <Component {...modalProps} {...props} />;
      }}
    </UniversalModalContextConsumer>
  );

  return UniversalModalContextComponent;
};
