import React from 'react';
import {
  FullWidthHeightForm,
  LPSearchBarFormTitle,
  FormFieldsContainer,
  StyledMainTitle,
  StyledSecondaryTitle
  // MobileCertifiedCol,
  // DesktopCertifiedCol
} from '../SearchBar.styles';

import FormInputNumber from 'components/InputNumber/FormInputNumber/FormInputNumber';
import { REGEX_NUMBER_N_DOT } from 'utils/constants';
import FormSelection from 'components/FormSelection/FormSelection';
import FormDateRangePicker from 'components/DateRangePicker/FormDateRangePicker/FormDateRangePicker';

import { Col, Button, Card } from 'antd';

const LPForm = ({
  form,
  state,
  checkInDate,
  checkOutDate,
  adultPax,
  stateMYSelection,
  handleOnSearchButtonClick,
  getSingularOrPluralLabel,
  isDerantauCertifiedSelection,
  isDerantauCertified
}) => {
  return (
    <FullWidthHeightForm
      form={form}
      initialValues={{
        state,
        dateRange: [checkInDate, checkOutDate],
        adultPax,
        isDerantauCertified
      }}
      style={{ paddingBottom: '50px' }}
    >
      <StyledSecondaryTitle>Check out the best vacation accommodations in Malaysia</StyledSecondaryTitle>
      <StyledMainTitle>Book Your Extraordinary Stay</StyledMainTitle>
      <Card bordered style={{ border: '1px solid white', borderRadius: '20px', padding: '10px 10px 0px 10px' }}>
        <FormFieldsContainer gutter={16}>
          <Col span={24} md={8}>
            <FormDateRangePicker
              name="dateRange"
              label={<LPSearchBarFormTitle>Stay Period</LPSearchBarFormTitle>}
              placeholder={['Check-in', 'Check-out']}
            />
          </Col>
          <Col span={24} md={4}>
            <FormInputNumber
              name="adultPax"
              label={<LPSearchBarFormTitle>Guests</LPSearchBarFormTitle>}
              minValue={1}
              maxValue={36}
              formatter={value => getSingularOrPluralLabel(value, 'Guest')}
              parser={value => value.replace(REGEX_NUMBER_N_DOT, '')}
            />
          </Col>
          <Col span={24} md={6}>
            <FormSelection
              name="state"
              label={<LPSearchBarFormTitle>Destination</LPSearchBarFormTitle>}
              selections={stateMYSelection}
              placeholder="All"
            />
          </Col>
          {/* <MobileCertifiedCol span={24} md={8}>
            <Col span={24} md={8}>
              <FormSelection
                name="isDerantauCertified"
                label={<LPSearchBarFormTitle>DE Rantau Certified Units: </LPSearchBarFormTitle>}
                selections={isDerantauCertifiedSelection}
                placeholder="All"
              />
            </Col>
          </MobileCertifiedCol> */}
          <Col span={24} md={6}>
            <Button type="primary" size={'large'} onClick={handleOnSearchButtonClick} block>
              Search
            </Button>
          </Col>
          {/* <DesktopCertifiedCol span={24} md={8}>
            <Col span={24} md={8}>
              <FormSelection
                name="isDerantauCertified"
                label={<LPSearchBarFormTitle>DE Rantau Certified Units: </LPSearchBarFormTitle>}
                selections={isDerantauCertifiedSelection}
                placeholder="All"
              />
            </Col>
          </DesktopCertifiedCol> */}
        </FormFieldsContainer>
      </Card>
    </FullWidthHeightForm>
  );
};

export default LPForm;
