import styled from '@emotion/styled';

export const StyledSectionTitle = styled.h1`
  font-size: 32px;
  font-weight: 700;
  color: ${props => props.theme.color.primaryFontColor};

  @media (min-width: 768px) {
    font-size: 25px;
  }

  span {
    color: ${props => props.theme.color.secondaryFontColor};
  }
`;

export const StyledSecondaryTitle = styled.p`
  font-size: 18px;
  color: ${props => props.theme.color.paragraphFontColor};
`;
