import styled from '@emotion/styled';
import { CheckCircleOutlined } from '@ant-design/icons';
import { Button, Col, Empty, Row } from 'antd';
import ClickableTextButton from 'components/ClickableTextButton/ClickableTextButton';

import Title from 'components/Font/Title/Title';

export const RoomsAvailTitle = styled(Title)`
  margin-bottom: ${props => props.theme.spacing.regular};
`;

export const ListingCardDescContainer = styled(Row)`
  width: 100%;
`;

export const StyledEmpty = styled(Empty)`
  margin-top: ${props => props.theme.spacing.xxl};
`;

export const StyledListingCardFooter = styled(Row)`
  justify-content: space-between;
  padding: 0 ${props => props.theme.spacing.regular};
`;

export const PricingCol = styled(Col)`
  text-align: right;
`;

export const AddedToBookingTextButton = styled(ClickableTextButton)`
  margin-bottom: 0px;
`;

export const AddedIcon = styled(CheckCircleOutlined)`
  color: ${props => props.theme.color.green};
  font-weight: ${props => props.theme.fontWeight.bold};
`;

export const AddedToBookingText = styled.span`
  border-bottom: 1px solid ${props => props.theme.color.primary};
  padding-bottom: ${props => props.theme.spacing.xxs};
`;

export const StyledAddToBookingButton = styled(Button)`
  margin: ${props => props.theme.spacing.xxs} 0;
  height: 100%;
  width: 100%;

  @media (min-width: 576px) {
    margin: 0;
  }
`;

export const RoomAmenitiesText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ViewMoreButton = styled(ClickableTextButton)`
  margin-bottom: 0px;
  font-size: 12px;
`;

export const PopOverContentContainer = styled.div`
  width: 280px;
`;
