import React, { useMemo } from 'react';
import { withUserContext } from 'contexts/UserContext/UserContext';

import { SORTING_CRITERIAS, DERANTAU_CERTIFIED } from 'utils/constants';

import { useFetchConstant } from 'apis/constants';

import SearchBar from './SearchBar/SearchBar';
import { Skeleton } from 'antd';

const useFetchConstants = () => {
  const { isLoading: isLoadingStateMY, selection: stateMYSelection } = useFetchConstant('statesMY');

  const isLoadingConstants = useMemo(() => isLoadingStateMY, [isLoadingStateMY]);

  return {
    isLoadingConstants,
    stateMYSelection
  };
};

const StickySearchBar = ({ banner, searchAndFilter, updateSearchAndFilter, isSticky }) => {
  const {
    state: cacheState,
    checkInDate: cacheCheckInDate,
    checkOutDate: cacheCheckOutDate,
    adultPax: cacheAdultPax,
    childPax: cacheChildPax,
    sortingCriteria: cacheSortingCriteria,
    isDerantauCertified: cacheDerantauCertified
  } = searchAndFilter;

  /* ---------------------------------------------fetching data details--------------------------------------------- */
  const { isLoadingConstants, stateMYSelection } = useFetchConstants();

  /* ----------------------------------------------isLoading--------------------------------------------- */

  /* ---------------------------------------------functions--------------------------------------------- */
  const handleOnSearchButtonClick = (state, checkInDate, checkOutDate, adultPax, childPax, sortingCriteria, isDerantauCertified) => {
    updateSearchAndFilter({
      state: state || null,
      checkInDate: checkInDate || null,
      checkOutDate: checkOutDate || null,
      adultPax,
      childPax,
      sortingCriteria,
      isDerantauCertified
    });
  };

  return (
    <>
      <Skeleton active loading={isLoadingConstants}>
        {!isSticky && banner}
        <SearchBar
          stateMYSelection={stateMYSelection}
          isDerantauCertifiedSelection={DERANTAU_CERTIFIED}
          sortingCriteriaSelection={SORTING_CRITERIAS}
          state={cacheState}
          checkInDate={cacheCheckInDate}
          checkOutDate={cacheCheckOutDate}
          adultPax={cacheAdultPax}
          childPax={cacheChildPax}
          sortingCriteria={cacheSortingCriteria}
          onSearchButtonClick={handleOnSearchButtonClick}
          isSticky={isSticky}
          isDerantauCertified={cacheDerantauCertified}
        />
      </Skeleton>
    </>
  );
};

export default withUserContext(StickySearchBar);
