import React from 'react';
import { Button } from 'antd';

import BareImage from 'components/Image/BareImage/BareImage';
import img from './images/header-background.png';

import { StyledTitleContainer, StyledMainTitle, StyledSecondaryTitle, StyledRow, StyledCol } from './Header.styles';

const Header = ({ redirectToBooking }) => {
  return (
    <StyledRow>
      <StyledCol>
        <StyledTitleContainer>
          <StyledMainTitle>
            Work. <span>Play.</span>
          </StyledMainTitle>
          <StyledMainTitle>
            Travel. <span>Repeat.</span>
          </StyledMainTitle>
          <StyledSecondaryTitle>Travel brings people together because of the shared experience it provides.</StyledSecondaryTitle>
          <StyledSecondaryTitle> A weekend away with the family, a romantic trip in Penang.</StyledSecondaryTitle>
          <Button type="primary" onClick={() => redirectToBooking()}>
            Book now
          </Button>
        </StyledTitleContainer>
      </StyledCol>
      <StyledCol>
        <BareImage image={img} />
      </StyledCol>
    </StyledRow>
  );
};

export default Header;
