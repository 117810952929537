import styled from '@emotion/styled';
import { Row } from 'antd';
import { Link } from 'react-router-dom';

export const MainContainer = styled(Row)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 12px;
  margin: 24px 0;
  background-color: ${props => props.theme.color.backgroundColor};
  color: ${props => props.theme.color.text};

  @media (min-width: 768px) {
    padding: 8px 0;
    justify-content: space-between;
  }
`;

export const LinkContainer = styled(Row)`
  display: flex;
  width: 250px;
  justify-content: space-between;

  @media (min-width: 768px) {
    padding: 8px 0;
    width: ${props => (props.max ? props.max : '300px')};
    justify-content: space-between;
  }

  a {
    color: ${props => props.theme.color.paragraphFontColor};
    font-size: 14px;
  }

  & :last-child {
    padding-top: 20px;

    @media (min-width: 768px) {
      padding-top: 0;
    }
  }
`;
export const LinkText = styled(Link)`
  color: ${props => props.theme.color.paragraphFontColor};

  &:hover {
    color: ${props => props.theme.color.paragraphFontColor};
  }
`;
