import styled from '@emotion/styled';
import { Card, Row } from 'antd';

export const DrawerApplyCheckBoxRow = styled(Row)`
  padding: ${props => props.theme.spacing.regular} ${props => props.theme.spacing.md};
`;

export const BookingCard = styled(Card)`
  margin: ${props => props.theme.spacing.xs} ${props => props.theme.spacing.regular};

  @media (min-width: 768px) {
    margin: ${props => props.theme.spacing.regular} ${props => props.theme.spacing.md};
  }
`;
