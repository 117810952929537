import styled from '@emotion/styled';
import { Skeleton } from 'antd';

export const StyledHeader = styled(Skeleton)`
  height: 35%;
`;

export const StyledBody = styled(Skeleton)`
  height: 65%;
`;
