import React, { useState } from 'react';

import BareImageCarousel from '../BareImageCarousel/BareImageCarousel';
import ImageGallery from '../ImageGallery/ImageGallery';

const ImageCarousel = ({ className, images, alt, hasGallery }) => {
  const [isImageGalleryVisible, setIsImageGalleryVisible] = useState(false);
  const [initialImageIndex, setInitialImageIndex] = useState(0);

  const handleOnImageClick = ({ index }) => {
    if (hasGallery) {
      setIsImageGalleryVisible(true);
      setInitialImageIndex(index);
    }
  };

  const handleOnCloseImageGallery = () => {
    if (hasGallery) {
      setIsImageGalleryVisible(false);
      setInitialImageIndex(0);
    }
  };

  return (
    <>
      <BareImageCarousel className={className} images={images} alt={alt} onClick={handleOnImageClick} hasCursor={hasGallery} />
      {isImageGalleryVisible && <ImageGallery images={images} alt={alt} initialImageIndex={initialImageIndex} onClose={handleOnCloseImageGallery} />}
    </>
  );
};

export default ImageCarousel;
