import React, { useMemo, useContext } from 'react';
import { Skeleton } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { withUserContext } from 'contexts/UserContext/UserContext';
import { withTrackingContext } from 'contexts/TrackingContext/TrackingContext';

import { useGetPropertyById } from 'apis/property';

import ClickableTextButton from 'components/ClickableTextButton/ClickableTextButton';
import SectionDivider from 'components/SectionDivider/SectionDivider';

import HostAStayLogo from 'images/hostastay-logo-with-white-background.png';

import { BLANK_IMAGE } from 'utils/constants';
import { formatToDateString } from 'utils/date';
import { guard } from 'utils/general';
import { buildPromotionUri, buildPropertyUri, handleOnPageNotFound } from 'utils/routes';

import PropertyInfo from './PropertyInfo/PropertyInfo';
import RoomTypeListing from './RoomTypeListing/RoomTypeListing';

import { MainContainer } from './PropertyDetail.styles';

import { StandardLayoutContext } from 'layouts/StandardLayout/StandardLayout';
import { getIsMemberLoggedIn } from '../../utils/auth';

const useFetchProperty = searchAndFilter => {
  const { propertyId } = useParams();
  const history = useHistory();
  const isPreviousPromotionUrl = history.location.state && history.location.state.from === buildPromotionUri();

  const query = {
    adultPax: searchAndFilter.adultPax,
    sortingCriteria: searchAndFilter.sortingCriteria,
    checkInDate: formatToDateString(searchAndFilter.checkInDate),
    checkOutDate: formatToDateString(searchAndFilter.checkOutDate)
  };

  const {
    data: { property },
    isLoading: isLoadingProperty,
    isError
  } = useGetPropertyById(propertyId, query, handleOnPageNotFound(history));

  const formattedProperty = useMemo(() => {
    if (isLoadingProperty || isError) {
      return {};
    }

    const formattedRoomTypes = property.roomTypes.map(roomType => ({
      ...roomType,
      images: guard(() => (roomType.images.length > 0 ? roomType.images.map(image => image.imageUrl) : [BLANK_IMAGE]), [BLANK_IMAGE]),
      amenities: guard(() => roomType.amenities.map(amenity => amenity.label), []),
      bedrooms: guard(() => roomType.bedrooms.map(bedroom => ({ ...bedroom, beds: bedroom.beds.map(bed => ({ ...bed, type: bed.type.label })) })), [])
    }));

    return {
      ...property,
      roomTypes: formattedRoomTypes,
      images: guard(() => property.images.map(image => image.imageUrl), []),
      hostImage: guard(() => property.hostImages[0].imageUrl, HostAStayLogo),
      propertyAmenities: guard(() => property.propertyAmenities.map(propertyAmenity => propertyAmenity.label), []),
      propertyType: guard(() => property.propertyType.label, [])
    };
  }, [isLoadingProperty, isError, property]);

  return { property: formattedProperty, isLoadingProperty: isLoadingProperty || isError, isPreviousPromotionUrl };
};

const PropertyDetail = ({ searchAndFilter, userBooking, addToBooking }) => {
  const { property, isLoadingProperty, isPreviousPromotionUrl } = useFetchProperty(searchAndFilter);
  const isMemberLoggedIn = getIsMemberLoggedIn();

  const showLoginModal = useContext(StandardLayoutContext);

  const { roomTypes, hostImage, ...propertyDetail } = property; // Remove hostImage temporary
  const { rooms: bookingRooms } = userBooking;
  const { checkInDate, checkOutDate } = searchAndFilter;

  const bookingRoomIds = guard(() => bookingRooms.map(room => room._id), []);
  const checkInOutDates = [checkInDate, checkOutDate];

  const handleOnAddToBooking = booking => {
    if (!isMemberLoggedIn) return showLoginModal();

    if (!property.externalBookingUrl) {
      addToBooking({
        ...booking,
        propertyId: property._id,
        propertyName: property.displayName,
        isHoliStayPromo: property.isHoliStayPromo,
        isPremium: property.isPremium,
        propertyIsAllowInstantBooking: property.isAllowInstantBooking
      });
    } else {
      window.open(property.externalBookingUrl, '_self');
    }
  };

  return (
    <Skeleton active loading={isLoadingProperty}>
      <MainContainer>
        <ClickableTextButton text="View Listing" url={isPreviousPromotionUrl ? buildPromotionUri() : buildPropertyUri()} />
        <PropertyInfo propertyDetail={propertyDetail} />
        <SectionDivider />
        <RoomTypeListing
          roomTypes={roomTypes}
          bookingRoomIds={bookingRoomIds}
          checkInOutDates={checkInOutDates}
          onAddToBooking={handleOnAddToBooking}
        />
      </MainContainer>
    </Skeleton>
  );
};

export default withUserContext(withTrackingContext(PropertyDetail));
