import ExternalLink from 'components/ExternalLink/ExternalLink';
import React from 'react';

import { MainContainer, LinkContainer } from './Footer.styles';

const LPFooter = () => {
  return (
    <>
      <MainContainer>
        <span>HostAStay Sdn. Bhd {new Date().getFullYear()} © All Rights Reserved</span>
        <LinkContainer max={'200px'}>
          <ExternalLink to="https://web.facebook.com/hostastay/?_rdc=1&_rdr">
            <span>Facebook</span>
          </ExternalLink>
          /
          <ExternalLink to="/terms-conditions">
            <span>Instagram</span>
          </ExternalLink>
        </LinkContainer>
      </MainContainer>
    </>
  );
};

export default LPFooter;
