import NoImagePlaceholder from 'images/no-image-placeholder.jpg';
import { REACT_APP_URL } from 'config/env';
export const NODE_ENV_DEVELOPMENT = 'development';
export const NODE_ENV_PRODUCTION = 'production';

export const SS_WP_BASE_URL = REACT_APP_URL;

/* ----------------------------General Constants---------------------------- */
/* ---------------The constants that generally use everywhere--------------- */
// Date
export const BOOK_DAY_LIMIT = 729;
export const WEEKENDS = [5, 6];
export const PEAK_PERIODS = [
  '2020-11-13', // Deepavali
  '2020-11-14', // Deepavali
  '2020-12-24', // Christmas
  '2020-12-25', // Christmas
  '2020-12-31', // New Year
  '2021-01-01', // New Year
  '2021-01-27', // Thaipusam
  '2021-01-28', // Thaipusam
  '2021-02-11', // CNY
  '2021-02-12', // CNY
  '2021-02-13', // CNY
  '2021-04-28', // Nuzul Al-Quran
  '2021-04-29', // Nuzul Al-Quran
  '2021-04-30', // Labour Day
  '2021-05-01', // Labour Day
  '2021-05-12', // Hari Raya Aidilfitri
  '2021-05-13', // Hari Raya Aidilfitri
  '2021-05-14', // Hari Raya Aidilfitri
  '2021-05-25', // Wesak Day
  '2021-05-26', // Wesak Day
  '2021-06-06', // Agong Birthday
  '2021-06-07' // Agong Birthday
];

// Date format
export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_WITH_WEEKDAY_FORMAT = 'YYYY-MM-DD, dddd';
export const FORMAL_DATE_FORMAT = 'DD-MM-YYYY';
export const MONTH_FORMAT = `YYYY-MM`;
export const DATE_DATE_TIME_FORMAT = `${DATE_FORMAT} HH:mm`;
export const DATE_MONTH_STRING_FORMAT = `DD MMM YYYY`;

// Form Label
export const LABEL_SIZE_XXLARGE = 'xxlarge';
export const LABEL_SIZE_XLARGE = 'xlarge';
export const LABEL_SIZE_LARGE = 'large';
export const LABEL_SIZE_REGULAR = 'regular';
export const LABEL_SIZE_SMALL = 'small';

// Regex
export const REGEX_NUMBER_N_DOT = /[^0-9.]/g;
export const CONTACT_NUMBER_REGEX = /^[+0-9][-\s0-9]*$/;
export const EMAIL_ADDRESS_REGEX = /^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$/;
export const PRICE_REGEX = /^\d*(\.\d{0,2})?$/;
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{12,20}$/;
export const NO_WHITESPACE_REGEX = /^\S*$/;

// Image
export const BLANK_IMAGE = NoImagePlaceholder;

/* ----------------------------Specific Constants---------------------------- */
/* ---------------The constants that generally use in one page--------------- */
// Unit Listings Page
export const SORTING_CRITERIAS = [
  { value: 'priceAscending', label: 'Lowest to Highest Price', isDefault: true },
  { value: 'priceDescending', label: 'Highest to Lowest Price' }
];

export const DERANTAU_CERTIFIED = [
  { value: undefined, label: 'All' },
  { value: '0', label: 'Host A Stay Unit' },
  { value: '1', label: 'DE Rantau Unit' }
];
