import styled from '@emotion/styled';
import { Row } from 'antd';

export const Card = styled.div`
  border: 1px solid #e9e9e9;
  border-radius: 2px;
  padding: ${props => props.theme.spacing.md};
`;

export const HeaderRow = styled(Row)`
  margin-bottom: ${props => props.theme.spacing.md};
`;

export const CardTag = styled.div`
  background-color: ${props => props.theme.color.lightSecondary};
  border-radius: 0 4px 4px 0;
  color: ${props => props.theme.color.primary};
  font-size: 16px;
  font-weight: ${props => props.theme.fontWeight.bold};
  margin: -16px 0 0 -32px;
  padding: ${props => props.theme.spacing.xs} ${props => props.theme.spacing.regular};
  width: fit-content;
`;
