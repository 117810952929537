import React, { useEffect, useMemo, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'antd';
import { BankOutlined, CreditCardOutlined } from '@ant-design/icons';

// import { NODE_ENV } from 'config/env';
import { withAppContext } from 'contexts/AppContext/AppContext';

import Subtitle from 'components/Font/Subtitle/Subtitle';
import FormGroupSelectionInput from 'components/FormGroupSelectionInput/FormGroupSelectionInput';
// import FormHCaptcha from 'components/HCaptcha/FormHCaptcha/FormHCaptcha';
import FormInput from 'components/Input/FormInput/FormInput';
import FormInputNumber from 'components/InputNumber/FormInputNumber/FormInputNumber';
import FormRadio from 'components/Radio/FormRadio/FormRadio';
import FormSelection from 'components/FormSelection/FormSelection';
import SectionCard from 'components/SectionCard/SectionCard';

import { LABEL_SIZE_LARGE /*, NODE_ENV_DEVELOPMENT*/ } from 'utils/constants';
import { guard } from 'utils/general';

import { ButtonContainer, FillDetailCheckbox, PaymentTypeRadio, PaymentTypeRadioIcon, PaymentTypeRadioText } from './GuestDetailsModal.styles';

// const IS_DEVELOPMENT_ENV = NODE_ENV === NODE_ENV_DEVELOPMENT;

const { useForm } = Form;

const GuestDetailsModal = ({ isMemberLoggedIn, member, visible, constantSelections, constantDatas, shouldMakePayment, onCancel, onSubmit }) => {
  const [form] = useForm();
  const { countryCodes, countries, identificationTypes } = constantSelections;
  const { countryCodesConst, countriesConst, paymentTypesConst, identificationTypesConst } = constantDatas;

  const [isBookedForSelf, setIsBookedForSelf] = useState(!!isMemberLoggedIn);
  const [selectedIdTypeValue, setSelectedIdTypeValue] = useState(identificationTypes.find(id => id.isDefault).value);
  const selectedIdTypeLabel = useMemo(
    () =>
      guard(() => identificationTypes.find(type => type.value === selectedIdTypeValue).label, identificationTypes.find(type => type.isDefault).label),
    [identificationTypes, selectedIdTypeValue]
  );

  const handleOnSubmit = values => {
    form.resetFields(['hCaptchaToken']);
    onSubmit(values);
    onCancel();
  };

  // const handleVerificationSuccess = hCaptchaToken => {
  //   form.setFieldsValue({ hCaptchaToken });
  // };

  useEffect(() => {
    if (isBookedForSelf && member) {
      form.setFieldsValue({
        name: member.name,
        identification: member.identification,
        email: member.email,
        contact: member.contact
      });
      if (guard(() => member.identification.type)) {
        setSelectedIdTypeValue(member.identification.type);
      }
    } else {
      form.setFieldsValue({
        name: undefined,
        identification: undefined,
        email: undefined,
        contact: undefined
      });
      setSelectedIdTypeValue(identificationTypes.find(type => type.isDefault).code);
    }
  }, [form, isBookedForSelf, identificationTypes, member]);

  useEffect(() => {
    if (!isBookedForSelf) {
      if (countriesConst) {
        form.setFieldsValue({ contact: { countryCode: countriesConst.MALAYSIA.phoneCode } });
      }
      if (countryCodesConst) {
        form.setFieldsValue({ identification: { nationality: countryCodesConst.MALAYSIA.iso3 } });
      }
      if (countryCodesConst) {
        form.setFieldsValue({ identification: { nationality: countryCodesConst.MALAYSIA.iso3 } });
      }
    }
  }, [form, isBookedForSelf, countriesConst, countryCodesConst]);

  useEffect(() => {
    setIsBookedForSelf(!!isMemberLoggedIn);
  }, [isMemberLoggedIn]);

  return (
    <Modal visible={visible} footer={null} onCancel={onCancel} destroyOnClose width="80%">
      <br />
      <Subtitle>Almost done! Final details required for processing your booking.</Subtitle>
      {isMemberLoggedIn && (
        <FillDetailCheckbox checked={isBookedForSelf} onChange={() => setIsBookedForSelf(!isBookedForSelf)}>
          Fill in my saved details
        </FillDetailCheckbox>
      )}
      <Form form={form} onFinish={handleOnSubmit}>
        <Row gutter={[8, 16]}>
          <Col span={24} md={12}>
            <SectionCard title="Basic Details">
              <Row gutter={[8, 8]}>
                <Col span={24}>
                  <FormInput
                    name="name"
                    label="Name"
                    labelSize={LABEL_SIZE_LARGE}
                    placeholder="eg. John Doe"
                    requiredErrorMessage="Please enter your name"
                  />
                </Col>
                <Col span={24}>
                  <FormSelection
                    name={['identification', 'nationality']}
                    label="Nationality"
                    labelSize={LABEL_SIZE_LARGE}
                    placeholder="Select your nationality"
                    requiredErrorMessage="Please select your nationality"
                    selections={countries}
                  />
                </Col>
                <Col span={24}>
                  <FormSelection
                    name={['identification', 'type']}
                    label="ID Type"
                    labelSize={LABEL_SIZE_LARGE}
                    placeholder="Select your Identification Type"
                    requiredErrorMessage="Please select your ID Type"
                    selections={identificationTypes}
                    onChange={setSelectedIdTypeValue}
                  />
                </Col>
                <Col span={24}>
                  <FormInput
                    name={['identification', 'id']}
                    label={`${selectedIdTypeLabel} Number`}
                    labelSize={LABEL_SIZE_LARGE}
                    placeholder={`Enter your ${selectedIdTypeLabel}`}
                    requiredErrorMessage={`Please enter your ${selectedIdTypeLabel}`}
                    isNRIC={selectedIdTypeValue === identificationTypesConst.NRIC.code}
                  />
                </Col>
              </Row>
            </SectionCard>
          </Col>
          <Col span={24} md={12}>
            <SectionCard title="Contact Details">
              <Row gutter={[8, 8]}>
                <Col span={24}>
                  <FormInput
                    name="email"
                    label="Email"
                    labelSize={LABEL_SIZE_LARGE}
                    placeholder="Enter your email address"
                    requiredErrorMessage="Please enter your email address"
                    isEmail
                  />
                </Col>
                <Col span={24}>
                  <FormGroupSelectionInput
                    name="contact"
                    selectionName="countryCode"
                    inputName="contactNumber"
                    label="Contact Number"
                    labelSize={LABEL_SIZE_LARGE}
                    requiredErrorMessage="Please enter your contact number"
                    inputType="number"
                    inputPlaceholder="123456789"
                    selections={countryCodes}
                    selectionPlaceholder="+60"
                  />
                </Col>
              </Row>
            </SectionCard>
            <Row style={{ margin: '24px 16px' }}>
              <div style={{ marginBottom: '16px' }}>
                <Subtitle type="s">Please state number of guests for this booking.</Subtitle>
              </div>
              <Col span={24}>
                <FormInputNumber
                  name="numberOfPax"
                  label="Number of Pax"
                  labelSize={LABEL_SIZE_LARGE}
                  placeholder="Enter your pax number"
                  requiredErrorMessage="Please enter your pax number"
                />
              </Col>
            </Row>
          </Col>
        </Row>
        {shouldMakePayment && (
          <FormRadio
            name="paymentType"
            label="Select your payment type"
            labelSize={LABEL_SIZE_LARGE}
            selections={[
              {
                value: paymentTypesConst.CARD.code,
                label: (
                  <PaymentTypeRadio>
                    <PaymentTypeRadioIcon>
                      <BankOutlined />
                    </PaymentTypeRadioIcon>
                    <PaymentTypeRadioText>credit card</PaymentTypeRadioText>
                  </PaymentTypeRadio>
                )
              },
              {
                value: paymentTypesConst.FPX.code,
                label: (
                  <PaymentTypeRadio>
                    <PaymentTypeRadioIcon>
                      <CreditCardOutlined />
                    </PaymentTypeRadioIcon>
                    <PaymentTypeRadioText>fpx</PaymentTypeRadioText>
                  </PaymentTypeRadio>
                )
              }
            ]}
            type="button"
            requiredErrorMessage="Please select a payment type"
          />
        )}
        <ButtonContainer>
          {/* NOTE: Temporary take off */}
          {/* {IS_DEVELOPMENT_ENV ? (
            <>~This is hCaptcha in dev mode~</>
          ) : (
            <FormHCaptcha name="hCaptchaToken" requiredErrorMessage="Please complete the captcha" onVerify={handleVerificationSuccess} />
          )} */}
          <Button type="primary" htmlType="submit" block>
            Send my booking
          </Button>
        </ButtonContainer>
      </Form>
    </Modal>
  );
};

export default withAppContext(GuestDetailsModal);
