import React from 'react';
import { Button, Col, Empty, Row, Skeleton } from 'antd';
import { useHistory } from 'react-router-dom';

import { withUserContext } from 'contexts/UserContext/UserContext';
import { withUniversalModalContext } from 'contexts/UniversalModalContext/UniversalModalContext';

import { useGetPropertyListingsByQuery } from 'apis/property';

import TextSecondary from 'components/Font/TextSecondary/TextSecondary';
import Title from 'components/Font/Title/Title';
import ListingCard from 'components/Card/ListingCard/ListingCard';
import Subtitle from 'components/Font/Subtitle/Subtitle';
import Image from 'components/Image/BareImage/BareImage';
import PropertyInfoTags from 'components/PropertyInfoTags/PropertyInfoTags';

import HostAStayLogo from 'images/hostastay-logo-with-white-background.png';
// import ramadanStaycationBannerImage from './images/ramadan-staycation-banner.jpg';
import signupBannerImage from './images/signup-banner.jpg';

import { BLANK_IMAGE } from 'utils/constants';
import { formatToDateString } from 'utils/date';
import { constructDisplayNumber, guard } from 'utils/general';
import { buildPropertyDetailUri } from 'utils/routes';

import {
  StyledBody,
  BodyContainer,
  ListingMainTitle,
  StyledEmpty,
  ListingCardFooterCol,
  BannersRootContainer,
  BannerContainer,
  BannerTextCol,
  BannerCtaExternalLink,
  CoverImageCol,
  CoverImageContainer
} from './PropertyListing.styles';

const useFetchPropertyListings = ({ state, checkInDate, checkOutDate, adultPax, childPax, sortingCriteria, isDerantauCertified }) => {
  const query = {
    state,
    checkInDate: formatToDateString(checkInDate),
    checkOutDate: formatToDateString(checkOutDate),
    adultPax,
    childPax,
    sortingCriteria,
    isDerantauCertified
  };

  const { isLoading: isLoadingProperties, data } = useGetPropertyListingsByQuery(query);

  const properties = data.properties || [];
  const totalNumberOfProperties = properties.length;

  const propertiesWithTotal = {
    total: totalNumberOfProperties,
    properties: properties.map(property => ({
      ...property,
      images: guard(() => (property.images.length > 0 ? property.images.map(image => image.imageUrl) : [BLANK_IMAGE]), [BLANK_IMAGE]),
      propertyType: guard(() => property.propertyTypes.label),
      host: {
        ...property.host,
        image: property.host.image || HostAStayLogo
      },
      isAllowInstantBooking: property.isAllowInstantBooking,
      isPremium: property.isPremium,
      isHoliStayPromo: property.isHoliStayPromo,
      isDerantauCertified: property.isDerantauCertified
    }))
  };

  return { isLoadingProperties, propertiesWithTotal };
};

const ListingCardDescription = ({ address }) => {
  return (
    <Row>
      <Col span={24}>
        <TextSecondary type="s">{address}</TextSecondary>
      </Col>
    </Row>
  );
};

const ListingCardFooter = ({ pricePerNight, onListClick }) => {
  return (
    <Row type="flex" align="middle" style={{ padding: '0 24px' }}>
      <Col span={12} md={20}>
        <Row>
          <Col span={24} md={0}>
            <span>From </span>
            <Title type="s" newLine={false}>
              {constructDisplayNumber(pricePerNight, 0)}
            </Title>
          </Col>
          <Col span={24} md={0}>
            <b> MYR / night</b>
          </Col>
          <Col span={0} md={24}>
            <span>From </span>
            <Title type="s" newLine={false}>
              {constructDisplayNumber(pricePerNight, 0)}
            </Title>
            <b> MYR / night</b>
          </Col>
        </Row>
      </Col>
      <ListingCardFooterCol span={12} md={4}>
        <Button type="primary" onClick={onListClick}>
          View more
        </Button>
      </ListingCardFooterCol>
    </Row>
  );
};

const Banner = ({ imageUrl, imageAlt, title, description, actionButtonLink, actionButtonText, actionButtonOnClick }) => {
  return (
    <BannerContainer>
      <CoverImageCol span={6}>
        <CoverImageContainer>
          <Image image={imageUrl} alt={imageAlt} />
        </CoverImageContainer>
      </CoverImageCol>
      <BannerTextCol span={18}>
        <Row gutter={[0, 6]} justify="space-around">
          <Col span={24}>
            <Subtitle type="s">{title}</Subtitle>
            <TextSecondary type="s">{description}</TextSecondary>
          </Col>
          <Col span={24}>
            <BannerCtaExternalLink
              target={!actionButtonOnClick ? '_blank' : undefined}
              href={!actionButtonOnClick ? actionButtonLink : '#'}
              onClick={actionButtonOnClick}
            >
              {actionButtonText}
            </BannerCtaExternalLink>
          </Col>
        </Row>
      </BannerTextCol>
    </BannerContainer>
  );
};

const Banners = ({ onSignupBannerButtonClick }) => {
  return (
    <BannersRootContainer gutter={[24, 24]}>
      <Col span={24}>
        <Banner
          imageUrl={signupBannerImage}
          imageAlt="Signup Banner Image"
          title="Unlimited Deals for 1 Year"
          description="Be a HostAStay member to redeem this limited time exclusive deal."
          actionButtonText="Sign Up Now!"
          actionButtonOnClick={onSignupBannerButtonClick}
        />
      </Col>
    </BannersRootContainer>
  );
};

const PropertyListing = ({ searchAndFilter, signupModalProps }) => {
  const history = useHistory();

  const { isLoadingProperties, propertiesWithTotal } = useFetchPropertyListings(searchAndFilter);

  const handleOnListClick = propertyId => () => {
    history.push(buildPropertyDetailUri(propertyId));
  };

  return (
    <>
      <Banners onSignupBannerButtonClick={() => signupModalProps.onModalVisibleChange(true)} />
      <Skeleton active loading={isLoadingProperties}>
        <StyledBody loading={false}>
          {propertiesWithTotal.properties.length > 0 ? (
            <BodyContainer>
              <ListingMainTitle>
                <Col span={24}>
                  <Title type="s" color="primary">
                    Explore Malaysia
                  </Title>
                </Col>
                <Col span={24}>
                  <TextSecondary>
                    <b>{propertiesWithTotal.total}</b> properties found based on your search
                  </TextSecondary>
                </Col>
              </ListingMainTitle>
              <Row>
                {propertiesWithTotal.properties.map(property => {
                  const {
                    _id,
                    name,
                    propertyType,
                    images,
                    address,
                    pricePerNight,
                    isAllowInstantBooking,
                    isPremium,
                    isHoliStayPromo,
                    isPromo,
                    isDerantauCertified
                  } = property;

                  return (
                    <ListingCard
                      key={_id}
                      image={images[0]}
                      images={images}
                      imageMaxHeight="216px"
                      name={name}
                      isDerantauCertified={isDerantauCertified}
                      subtitle={
                        <PropertyInfoTags
                          propertyType={propertyType}
                          isAllowInstantBooking={isAllowInstantBooking}
                          isPremium={isPremium}
                          isHoliStayPromo={isHoliStayPromo}
                          isPromo={isPromo}
                        />
                      }
                      bodyDescription={<ListingCardDescription address={address} />}
                      footer={<ListingCardFooter pricePerNight={pricePerNight} onListClick={handleOnListClick(_id)} />}
                    />
                  );
                })}
              </Row>
            </BodyContainer>
          ) : (
            <StyledEmpty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No properties available based on your search" />
          )}
        </StyledBody>
      </Skeleton>
    </>
  );
};

export default withUserContext(withUniversalModalContext(PropertyListing));
