import React from 'react';

import { BannerContainer, TextCol, Column } from '../About.style';
import Subtitle from 'components/Font/Subtitle/Subtitle';

const Content = () => {
  return (
    <BannerContainer justify="start">
      <Column>
        <Subtitle>About DE Rantau</Subtitle>
        <TextCol>
          The DE Rantau programme seeks to make Malaysia as the preferred Digital Nomad Hub in ASEAN, in line with the Malaysia Digital Economy
          Blueprint (mydigital)’s goals to harness human capital that can thrive in the digital economy.
        </TextCol>
      </Column>

      <Column>
        <Subtitle>About DE Rantau Platform</Subtitle>
        <ul>
          <li>
            <TextCol>Local services to facilitate your nomadic lifestyle</TextCol>
          </li>
          <li>
            <TextCol>
              Mobile application for all things DE Rantau – explores and access local hubs, services, discount vouchers and more at your fingertips.
              For registered DE Rantau members only.
            </TextCol>
          </li>
        </ul>
      </Column>

      <Column>
        <Subtitle>DE Rantau Hubs</Subtitle>
        <TextCol>
          DE Rantau hub is a nomad-ready accommodation that has been verified, validated and certified by MDEC based on DE Rantau hubs criteria to
          cater to the active and dynamic nomad lifestyle.
        </TextCol>
        <ul>
          <li>
            <TextCol>Nomad-ready accommodations and working spaces across the countr</TextCol>
          </li>
          <li>
            <TextCol>Check-out available digital nomad-ready hubs throughout the country so you can plan your journey ahead!</TextCol>
          </li>
        </ul>
      </Column>
    </BannerContainer>
  );
};

export default Content;
