import styled from '@emotion/styled';
import { Alert, Row, Button } from 'antd';

export const TitleRow = styled(Row)`
  margin-bottom: ${props => props.theme.spacing.regular};
`;

export const TitleLabel = styled.label`
  font-size: 28px;
`;

export const SendLinkButton = styled(Button)`
  width: 100%;
`;

export const StyledAlert = styled(Alert)`
  margin-bottom: 10px;
  width: 100%;
`;
