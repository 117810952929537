import styled from '@emotion/styled';
import { Row, Col } from 'antd';

export const StyledTitleContainer = styled.div`
  padding: 7%;
`;

export const StyledMainTitle = styled.p`
  font-size: 30px;
  font-weight: 700;
  color: ${props => props.theme.color.primaryFontColor};
  margin-bottom: 0px;

  @media (min-width: 768px) {
    font-size: 40px;
  }

  span {
    color: ${props => props.theme.color.secondaryFontColor};
  }
`;

export const StyledSecondaryTitle = styled.p`
  font-size: 15px;
  color: ${props => props.theme.color.paragraphFontColor};

  @media (min-width: 768px) {
    font-size: 20px;
  }
`;

export const StyledRow = styled(Row)`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 768px) {
    flex-wrap: nowrap;
  }
`;

export const StyledCol = styled(Col)`
  padding-top: 7%;
`;

// export const StyledButton = styled(Button)``;
