import React from 'react';
import { withUserContext } from 'contexts/UserContext/UserContext';
import { useHistory } from 'react-router-dom';
// import { useFetchConstant } from 'apis/constants';
// import { useGetStateLists } from 'apis/property';

import Header from './Header/Header';
// import Body from './Body/Body';

import { StyledBody, StyledHeader } from './LandingPage.styles';
import Card from './Body/Card/Card';
// import Partners from './Body/Partners/Partners';
import PopularDestinations from './Body/PopularDestinations/PopularDestinations';
// import NewsLetter from './Body/NewsLetter/NewsLetter';
import Footer from './Body/Footer/Footer';
// const useFetchConstants = () => {
//   const { isLoading: isLoadingStateMY, selection: stateMYSelection } = useFetchConstant('statesMY');
//   const { isLoading: isLoadingRoomViews, selection: roomViewsSelection } = useFetchConstant('roomAmenities');

//   const formattedRoomViewsSelection = useMemo(() => roomViewsSelection.filter(roomView => roomView.type === 'Outdoor and View'), [
//     roomViewsSelection
//   ]);

//   const isLoadingConstants = useMemo(() => isLoadingStateMY || isLoadingRoomViews, [isLoadingStateMY, isLoadingRoomViews]);

//   return {
//     isLoadingConstants,
//     stateMYSelection,
//     roomViewsSelection: formattedRoomViewsSelection
//   };
// };

// const useFetchStateLists = () => {
//   const { isLoading: isLoadingStateLists, data } = useGetStateLists();
//   const stateLists = data.stateLists;

//   return { isLoadingStateLists, stateLists };
// };

const LandingPage = ({ searchAndFilter, updateSearchAndFilter }) => {
  const history = useHistory();
  // const {
  //   state: cacheState,
  //   checkInDate: cacheCheckInDate,
  //   checkOutDate: cacheCheckOutDate,
  //   adultPax: cacheAdultPax,
  //   childPax: cacheChildPax,
  //   roomViews: cacheRoomViews,
  //   sortingCriteria: cacheSortingCriteria
  // } = searchAndFilter;

  // /* ---------------------------------------------fetching data details--------------------------------------------- */
  // const { isLoadingConstants, stateMYSelection, roomViewsSelection } = useFetchConstants();
  // const { isLoadingStateLists, stateLists } = useFetchStateLists();

  // /* ----------------------------------------------isLoading--------------------------------------------- */
  // const isLoading = useMemo(() => isLoadingConstants, [isLoadingConstants]);

  /* ---------------------------------------------functions--------------------------------------------- */
  // const handleOnSearchButtonClick = (state, checkInDate, checkOutDate, adultPax, childPax, roomViews, sortingCriteria) => {
  //   updateSearchAndFilter({
  //     state: state || null,
  //     checkInDate: checkInDate || null,
  //     checkOutDate: checkOutDate || null,
  //     adultPax,
  //     childPax,
  //     roomViews,
  //     sortingCriteria
  //   });
  // };

  const redirectToBooking = () => history.push('/property');

  return (
    <>
      <StyledHeader loading={false}>
        <Header redirectToBooking={redirectToBooking} />
      </StyledHeader>
      <StyledBody loading={false}>
        <Card />
        <PopularDestinations redirectToBooking={redirectToBooking} />
        {/* <NewsLetter /> */}
        <Footer />
      </StyledBody>
    </>
  );
};

export default withUserContext(LandingPage);
