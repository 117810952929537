import React from 'react';
import { Col, Row } from 'antd';
import MdecBannerImg from 'images/landing-mdec-image.png';
import MdecLogo from 'images/MD-De-Rantau.png';
import { MdecBannerBackground, StyledBannerTitle, StyledBody, StyledLogo } from './MdecBanner.styles';

const MdecBanner = () => {
  return (
    <MdecBannerBackground>
      <StyledBody style={{ height: '100%' }}>
        <Row style={{ height: '100%' }}>
          <Col xs={24} md={8} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <div>
              <StyledLogo src={MdecLogo} alt="mdecLogo" />
            </div>
            <div style={{ paddingTop: '1rem' }}>
              <StyledBannerTitle>Malaysia as your next digital nomad destination.</StyledBannerTitle>
            </div>
          </Col>
          <Col xs={24} md={16} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <img src={MdecBannerImg} style={{ width: '100%' }} alt="landingMdecImage" />
          </Col>
        </Row>
      </StyledBody>
    </MdecBannerBackground>
  );
};

export default MdecBanner;
