import styled from '@emotion/styled';

import Font from '../Font';

const fontSizeForTitle = {
  xs: '12px',
  s: '16px',
  default: '20px',
  md: '24px'
};

export const Text = styled(Font)`
  color: ${props => props.theme.color.primary};
  font-size: ${props => fontSizeForTitle[`${props.type}`]};
  font-weight: 600;
`;
