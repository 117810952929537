import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export const buildBaseUri = () => '/';

export const build404Uri = () => '/404';

export const buildThankYouUri = () => `/thankyou`;

export const buildMyAccountUri = () => '/my-account';
export const buildMyAccountDetailsUri = () => `${buildMyAccountUri()}/profile`;
export const buildMyAccountPackagesUri = () => `${buildMyAccountUri()}/packages`;
export const buildMyAccountStaysUri = () => `${buildMyAccountUri()}/stays`;

export const buildPropertyUri = () => `/property`;
export const buildPropertyDetailUri = (propertyId = ':propertyId') => `${buildPropertyUri()}/${propertyId}`;

export const buildResetPasswordUri = (token = ':token') => `/reset-password/${token}`;

export const buildMemberActivationUri = (token = ':token') => `/member/activate/${token}`;

export const buildPromotionUri = () => `/promotion`;

export const buildStripPaymentUri = () => '/stripe-payment';

export const buildTermsUri = () => '/terms-conditions/';

export const buildPrivacyUri = () => '/privacy-policy/';

export const buildAboutUri = () => '/about-us/';

export const buildAboutDeRantauUri = () => '/de-rantau/';

/* ------------------ extra function ------------------ */
export const handleOnPageNotFound = history => () => {
  history.push(build404Uri());
};

export const staticRoutes = ['/', '/about-us', '/privacy-policy', '/terms-conditions'];

/* ------------------ React router wrapper ------------------ */
export const InnerRoute = ({ children, title, ...otherProps }) => {
  return (
    <Route {...otherProps}>
      {title && (
        <Helmet>
          <title>{`${title} . HostAStay`}</title>
        </Helmet>
      )}
      {children}
    </Route>
  );
};

InnerRoute.propTypes = {
  title: PropTypes.string
};

InnerRoute.defaultProps = {
  title: 'Book Your Extraordinary Stay . HostAStay'
};
