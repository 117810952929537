import React, { useEffect, useState, useMemo } from 'react';
import { Alert, Button, Drawer, Row, Col, Empty, message, Modal, Select, Skeleton, Tooltip } from 'antd';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { QuestionCircleOutlined } from '@ant-design/icons';

import { STRIPE_KEY } from 'config/env';

import { postCreateBooking } from 'apis/booking';
import { getPackageCodeValidation } from 'apis/packageOrder';
import { getPromotionCodeValidation, getBookingTotalPriceAfterPromotion } from 'apis/promotion';
import { useGetMemberSelfPackageOrders } from 'apis/member';

import { withUserContext } from 'contexts/UserContext/UserContext';
import { withAppContext } from 'contexts/AppContext/AppContext';
import { withTrackingContext } from 'contexts/TrackingContext/TrackingContext';

import Loading from 'components/FullScreenLoading/FullScreenLoading';
import TextSecondary from 'components/Font/TextSecondary/TextSecondary';
import Title from 'components/Font/Title/Title';
import Image from 'components/Image/BareImage/BareImage';
import ClickableTextButton from 'components/ClickableTextButton/ClickableTextButton';
import Subtitle from 'components/Font/Subtitle/Subtitle';

import { useFetchConstant } from 'hooks/constants';

import { PEAK_PERIODS } from 'utils/constants';
import { getDifferenceBetweenDate, formatToDateString, formatToDateWeekdayString, isWeekend, generateDateArray } from 'utils/date';
import { checkIsObjectEmpty, constructDisplayPrice, guard, getSingularOrPluralLabel } from 'utils/general';
import { buildPropertyDetailUri, buildThankYouUri } from 'utils/routes';

import GuestDetailsModal from './GuestDetailsModal/GuestDetailsModal';
import RemarksModal from './RemarksModal/RemarksModal';
import PackageCodeAndPromotion from './PackageCodeAndPromotion/PackageCodeAndPromotion';

import {
  BoldRow,
  BookingRowContainer,
  BookingRowPricingCol,
  CoverImageCol,
  CoverImageContainer,
  DrawerHeader,
  DrawerTotalPriceRow,
  DrawerTotalPriceValueCol,
  EmptyBookingContainer,
  FooterContainer,
  RemoveIcon,
  RemarksIcon,
  RootContainer,
  StyledTotalPrice,
  TitleRow
} from './BookingDrawer.styles';

import { staticRoutes } from 'utils/routes';

const { Option } = Select;

const DISCOUNT_TYPE_PACKAGE_CODE = 'discountTypePackageCode';
const DISCOUNT_TYPE_PROMOTION = 'discountTypePromotion';

/* =============================================== Local Function =============================================== */

const calculateBookingTotalRoomRate = rooms => {
  if (!rooms) {
    return 0;
  }

  return rooms.reduce((totalPrice, room) => {
    return totalPrice + guard(() => room.totalPrice, 0);
  }, 0);
};

const calculatedTotalNightsCost = (rooms = []) =>
  rooms.reduce((totalNightsCost, room) => {
    return totalNightsCost + guard(() => room.nightsCost, 0);
  }, 0);

const calculateTotalPriceAfterPromotion = async ({ isValidDiscount, promotionCode, totalRoomRate, propertyId, rooms, startDate, endDate }) => {
  let totalAfterPromotion = totalRoomRate;
  let totalDiscountAmount = 0;

  if (isValidDiscount && promotionCode) {
    const totalPriceAfterPromotion = await getBookingTotalPriceAfterPromotion({ promotionCode, propertyId, rooms, startDate, endDate }).catch(ex =>
      console.log(ex)
    );

    if (totalPriceAfterPromotion) {
      totalAfterPromotion = totalPriceAfterPromotion.totalAfterPromotion;
      totalDiscountAmount = totalPriceAfterPromotion.totalDiscountAmount;
    }
  }

  return { totalAfterPromotion, totalDiscountAmount };
};

const calculateSurcharge = (startDate, endDate, rooms) => {
  const dates = generateDateArray(startDate, endDate);
  const noOfRooms = guard(() => rooms.reduce((totalRooms, roomType) => totalRooms + roomType.roomCount, 0), 1);

  const totalSurcharge = dates.reduce((surcharge, date) => {
    if (PEAK_PERIODS.includes(date)) {
      return surcharge + 50;
    } else if (isWeekend(date)) {
      return surcharge + 30;
    }
    return surcharge;
  }, 0);

  return totalSurcharge * noOfRooms;
};

const submitBooking = (userBooking, totalPrice, guestDetails, memberId, paymentType, hCaptchaToken, { packageCode, promotionCode }) => {
  const {
    startDate,
    endDate,
    rooms,
    property: { _id: propertyId }
  } = userBooking;

  const startDateInString = formatToDateString(startDate);
  const endDateInString = formatToDateString(endDate);

  return postCreateBooking({
    hCaptchaToken,
    propertyId,
    rooms,
    startDate: startDateInString,
    endDate: endDateInString,
    guestDetails,
    packageCode,
    promotionCode,
    totalPrice,
    memberId,
    paymentType
  });
};

const generateInvOptions = inventory => {
  let options = [];
  for (let i = 1; i <= inventory; i++) {
    options.push(i);
  }
  return options;
};

/* =============================================== Use Function =============================================== */

const useFetchConstants = () => {
  const { selection: countries, data: countriesConst, isLoading: isCountriesLoading } = useFetchConstant('countries', {
    query: { isFetchingAllCountries: true },
    valueKey: 'iso3',
    labelKey: 'name'
  });
  const { selection: countryCodes, data: countryCodesConst, isLoading: isCountryCodesLoading } = useFetchConstant('countries', {
    query: { isFetchingAllCountries: true },
    valueKey: 'phoneCode',
    labelKey: 'phoneCode'
  });

  const { selection: identificationTypes, data: identificationTypesConst, isLoading: isIndetificationTypesLoading } = useFetchConstant(
    'identificafionTypes'
  );

  const { data: paymentTypesConst, isLoading: isPaymentTypesLoading } = useFetchConstant('paymentTypes');

  const isLoadingConstants = useMemo(() => isCountriesLoading || isIndetificationTypesLoading || isCountryCodesLoading || isPaymentTypesLoading, [
    isCountriesLoading,
    isIndetificationTypesLoading,
    isCountryCodesLoading,
    isPaymentTypesLoading
  ]);

  return {
    isLoadingConstants,
    selections: { countryCodes, countries, identificationTypes },
    datas: { countriesConst, countryCodesConst, identificationTypesConst, paymentTypesConst }
  };
};

const useFetchMemberPackageCodes = (isMemberLoggedIn, property, nightsCost, endDate) => {
  const location = useLocation();
  const { data: packageOrders } = useGetMemberSelfPackageOrders({ shouldFetch: isMemberLoggedIn && !staticRoutes.includes(location.pathname) });

  const formattedPackageCodes = useMemo(
    () =>
      property && packageOrders && Array.isArray(packageOrders)
        ? packageOrders
            .filter(packageOrder => packageOrder.nightsLeft)
            .map(packageOrder => {
              let isValidToBook = true;

              if (packageOrder.isHoliStayPromo) {
                if (endDate > '2021-07-01') {
                  isValidToBook = false;
                }

                if (!property.isHoliStayPromo) {
                  isValidToBook = false;
                } else if (!packageOrder.isPremium && property.isPremium) {
                  isValidToBook = false;
                }
              }

              if (packageOrder.nightsLeft < nightsCost) {
                isValidToBook = false;
              }

              return {
                value: packageOrder.packageCode,
                label: `${packageOrder.packageCode} (Room Nights left: ${packageOrder.nightsLeft})`,
                shouldDisable: !isValidToBook
              };
            })
        : [],
    [nightsCost, packageOrders, property, endDate]
  );

  return { packageCodes: formattedPackageCodes };
};

const usePackageCodeAndPromotion = (userBooking, nightsCost) => {
  const [selectedDiscountType, setSelectedDiscountType] = useState('');
  const [discountCode, setDiscountCode] = useState('');
  const [discountCodeErrorMessage, setDiscountCodeErrorMessage] = useState('');

  const [isValidDiscount, setIsValidDiscount] = useState(false);
  const [hasSurcharge, setHasSurcharge] = useState(false);
  const [isValidating, setIsValidating] = useState(false);

  // Invalidated a valid discount whenever there is changes in userBooking and discount code
  useEffect(() => {
    if (isValidDiscount) {
      setIsValidDiscount(false);
      setHasSurcharge(false);
    }

    setDiscountCodeErrorMessage('');
  }, [userBooking, discountCode, selectedDiscountType]); // eslint-disable-line react-hooks/exhaustive-deps

  const isShowPackageCode = useMemo(() => (selectedDiscountType === DISCOUNT_TYPE_PACKAGE_CODE ? true : false), [selectedDiscountType]);
  const isShowPromotion = useMemo(() => (selectedDiscountType === DISCOUNT_TYPE_PROMOTION ? true : false), [selectedDiscountType]);
  const isConfiguringDiscountSetting = useMemo(() => !!selectedDiscountType && !isValidDiscount, [isValidDiscount, selectedDiscountType]);
  const hasOutStandingAfterDiscount = useMemo(() => isValidDiscount && (selectedDiscountType === DISCOUNT_TYPE_PROMOTION || hasSurcharge), [
    isValidDiscount,
    hasSurcharge,
    selectedDiscountType
  ]);

  const handleOnCheckboxClick = typeInArr => {
    const discountType = guard(() => typeInArr[0]);

    setSelectedDiscountType(discountType);
  };

  const handleOnChangeDiscountCode = discountCode => {
    setDiscountCode(discountCode);
  };

  const handleOnApplyDiscountCode = async () => {
    setIsValidating(true);

    const {
      property: { _id: propertyId },
      endDate
    } = userBooking;

    if (!discountCode) {
      setDiscountCodeErrorMessage('Please input a code before apply!');
    } else {
      if (isShowPackageCode) {
        await getPackageCodeValidation(discountCode, propertyId, nightsCost, endDate)
          .then(packageCode => {
            setIsValidDiscount(true);
            setHasSurcharge(!!packageCode.isHoliStayPromo);

            message.success('Package Code Applied! Please proceed to confirm your booking');
          })
          .catch(ex => {
            setDiscountCodeErrorMessage(ex.message);
          });
      } else if (isShowPromotion) {
        await getPromotionCodeValidation(propertyId, discountCode)
          .then(() => {
            setIsValidDiscount(true);

            message.success('Promotion Code Applied! Please proceed to confirm your booking');
          })
          .catch(ex => {
            setDiscountCodeErrorMessage(ex.message);
          });
      }
    }

    setIsValidating(false);
  };

  return {
    selectedDiscountType,
    discountCode,
    discountCodeErrorMessage,

    isShowPackageCode,
    isShowPromotion,
    isValidDiscount,
    isValidating,
    isConfiguringDiscountSetting,
    hasOutStandingAfterDiscount,
    hasSurcharge,

    handleOnCheckboxClick,
    handleOnChangeDiscountCode,
    handleOnApplyDiscountCode
  };
};

const useTotalPriceDetails = (isValidDiscount, selectedDiscountType, hasSurcharge, { discountCode, property, rooms, startDate, endDate }) => {
  const [totalAfterDiscount, setTotalAfterDiscount] = useState(0);
  // const [totalDiscountAmount, setTotalDiscountAmount] = useState(0);

  const isPromotion = useMemo(() => selectedDiscountType === DISCOUNT_TYPE_PROMOTION, [selectedDiscountType]);
  const totalRoomRate = useMemo(() => calculateBookingTotalRoomRate(rooms), [rooms]);
  const surcharge = useMemo(() => (hasSurcharge ? calculateSurcharge(startDate, endDate, rooms) : 0), [hasSurcharge, startDate, endDate, rooms]);
  const displayTotalAfterDiscount = useMemo(() => (isPromotion ? totalAfterDiscount : 0 + surcharge), [isPromotion, surcharge, totalAfterDiscount]);

  const totalPrice = useMemo(() => (isValidDiscount && isPromotion ? totalAfterDiscount : totalRoomRate), [
    isPromotion,
    isValidDiscount,
    totalAfterDiscount,
    totalRoomRate
  ]);

  useEffect(() => {
    if (isPromotion && property && property._id && rooms && rooms.length > 0) {
      calculateTotalPriceAfterPromotion({
        isValidDiscount,

        promotionCode: discountCode,
        totalRoomRate,
        propertyId: property._id,
        rooms,
        startDate: formatToDateString(startDate),
        endDate: formatToDateString(endDate)
      }).then(totalPrice => {
        setTotalAfterDiscount(totalPrice.totalAfterPromotion);
        // setTotalDiscountAmount(totalPrice.totalDiscountAmount);
      });
    }
  }, [isValidDiscount]); // eslint-disable-line react-hooks/exhaustive-deps

  return { totalPrice, totalRoomRate, displayTotalAfterDiscount, surcharge };
};

/* =============================================== Sub Component =============================================== */

const BookingRow = ({ room, onChangeRoomCount, onRemoveClick, onRemarksClick }) => {
  const { adultCapacity, bathrooms, childCapacity, displayName, image, inventory, livingrooms, roomCount, totalPrice, remarks } = room;
  const roomCap = `${getSingularOrPluralLabel(adultCapacity, 'Adult')}
  , ${getSingularOrPluralLabel(childCapacity, 'Child', { pluralLabel: 'Children' })}
  , ${getSingularOrPluralLabel(bathrooms, 'Bathroom')}
  , ${getSingularOrPluralLabel(livingrooms, 'Living Room')}`;

  return (
    <RootContainer type="flex" align="center">
      <CoverImageCol span={24} md={6}>
        <CoverImageContainer>
          <Image image={image} alt="Cover image of the property" />
        </CoverImageContainer>
      </CoverImageCol>
      <Col span={24} md={18}>
        <Row type="flex" align="center">
          <Col span={24}>
            <Row type="flex">
              <Col order={2} span={24} md={{ span: 24, order: 1 }}>
                <BookingRowContainer gutter={[8, 16]} align="middle">
                  <Col span={24}>
                    <Row gutter={[0, 4]}>
                      <Col span={24}>
                        <Subtitle type="s">{displayName}</Subtitle>
                      </Col>
                      <Col span={24}>
                        <TextSecondary type="s">{roomCap}</TextSecondary>
                      </Col>
                      {remarks && (
                        <Col span={24} style={{ fontStyle: 'italic' }}>
                          <Row gutter={4}>
                            <Col>
                              <TextSecondary type="s">
                                <b>Remarks:</b>
                              </TextSecondary>
                            </Col>
                            <Col>
                              <TextSecondary type="s">{remarks}</TextSecondary>
                            </Col>
                          </Row>
                        </Col>
                      )}
                    </Row>
                  </Col>
                  <Col span={12}>
                    <Row type="flex" align="middle">
                      <Col flex="40px">
                        <TextSecondary type="s">Qty: </TextSecondary>
                      </Col>
                      <Col flex="auto">
                        <Select defaultValue={roomCount} onChange={onChangeRoomCount} style={{ width: '100%' }}>
                          {inventory &&
                            generateInvOptions(inventory).map(inv => (
                              <Option key={inv} value={inv}>
                                {inv}
                              </Option>
                            ))}
                        </Select>
                      </Col>
                    </Row>
                  </Col>
                  <BookingRowPricingCol span={12}>
                    <Subtitle type="s">{constructDisplayPrice(totalPrice)}</Subtitle>
                  </BookingRowPricingCol>
                </BookingRowContainer>
                <FooterContainer gutter={[0, 8]}>
                  <Row gutter={8}>
                    <Col>
                      <Button onClick={onRemoveClick} type="danger">
                        <RemoveIcon /> Remove
                      </Button>
                    </Col>
                    <Col>
                      <Button onClick={onRemarksClick} block type="primary">
                        <RemarksIcon />
                        Leave a note
                      </Button>
                    </Col>
                  </Row>
                </FooterContainer>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </RootContainer>
  );
};

const EmptyBooking = ({ onCloseDrawer }) => {
  const linkToMainPage = (
    <Link to={'/'} onClick={onCloseDrawer}>
      Book now!
    </Link>
  );

  return (
    <EmptyBookingContainer>
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>Are you ready for a vacation? {linkToMainPage}</span>} />
    </EmptyBookingContainer>
  );
};

/* =============================================== Main Component =============================================== */

const BookingDrawer = ({
  visible,
  onCloseDrawer,
  userBooking,
  updateBookingItem,
  clearBooking,
  removeBookingItem,
  isMemberLoggedIn,
  member,
  onLoginButtonClicked,
  trackBeginCheckoutEvent,
  trackPurchaseEvent
}) => {
  const history = useHistory();
  const { startDate, endDate, property, rooms } = userBooking;

  const { isLoadingConstants, selections, datas } = useFetchConstants();

  const [isConfirmBookingLoading, setIsConfirmBookingLoading] = useState(false);
  const [isGuestDetailsModalVisible, setIsGuestDetailsModalVisible] = useState(false);
  const [isRemarksModalVisible, setIsRemarksModalVisible] = useState(false);
  const [selectedRoomIndex, setSelectedRoomIndex] = useState(-1);

  const hasBooking = useMemo(() => guard(() => !checkIsObjectEmpty(userBooking), {}), [userBooking]);
  const bookingNights = useMemo(() => getDifferenceBetweenDate(startDate, endDate), [startDate, endDate]);
  const selectedRoom = useMemo(() => guard(() => rooms[selectedRoomIndex], {}), [rooms, selectedRoomIndex]);
  const nightsCost = useMemo(() => calculatedTotalNightsCost(rooms), [rooms]);
  const roomsNights = useMemo(() => rooms && getSingularOrPluralLabel(calculatedTotalNightsCost(rooms), 'room night'), [rooms]);

  const { packageCodes } = useFetchMemberPackageCodes(isMemberLoggedIn, property, nightsCost, endDate);
  const {
    selectedDiscountType,
    discountCode,
    discountCodeErrorMessage,

    isShowPackageCode,
    isShowPromotion,
    isValidDiscount,
    isConfiguringDiscountSetting,
    isValidating,
    hasOutStandingAfterDiscount,
    hasSurcharge,

    handleOnCheckboxClick,
    handleOnChangeDiscountCode,
    handleOnApplyDiscountCode
  } = usePackageCodeAndPromotion(userBooking, nightsCost);

  const { totalPrice, totalRoomRate, displayTotalAfterDiscount, surcharge } = useTotalPriceDetails(
    isValidDiscount,
    selectedDiscountType,
    hasSurcharge,
    {
      discountCode,
      property,
      rooms,
      startDate,
      endDate
    }
  );

  const shouldMakePayment = useMemo(
    () => guard(() => property.isAllowInstantBooking, false) && (!isValidDiscount || (hasOutStandingAfterDiscount && displayTotalAfterDiscount > 0)),
    [isValidDiscount, hasOutStandingAfterDiscount, property, displayTotalAfterDiscount]
  );

  const { formattedStartDate, formattedEndDate } = useMemo(
    () => ({ formattedStartDate: formatToDateWeekdayString(startDate), formattedEndDate: formatToDateWeekdayString(endDate) }),
    [startDate, endDate]
  );
  const isLoading = useMemo(() => isLoadingConstants, [isLoadingConstants]);
  const isReadyToBook = useMemo(() => !isConfiguringDiscountSetting, [isConfiguringDiscountSetting]);

  const handleOnConfirmBookingClick = () => {
    setIsGuestDetailsModalVisible(true);
    trackBeginCheckoutEvent(userBooking);
  };

  const handleOnConfirmBookingClose = () => {
    setIsGuestDetailsModalVisible(false);
  };

  const handleOnConfirmBookingSubmit = ({ hCaptchaToken, paymentType, ...guestDetails }) => {
    setIsConfirmBookingLoading(true);

    submitBooking(userBooking, totalPrice, guestDetails, member._id, paymentType, hCaptchaToken, {
      ...(isShowPackageCode ? { packageCode: discountCode } : { promotionCode: discountCode })
    })
      .then(async ({ newBooking, paymentSession }) => {
        clearBooking();
        trackPurchaseEvent(newBooking.code, userBooking);
        onCloseDrawer();
        if (!!paymentSession) {
          const stripe = await loadStripe(STRIPE_KEY);
          stripe
            .redirectToCheckout({
              sessionId: paymentSession.id
            })
            .then(result => {
              if (!!result.error) {
                throw new Error(result.error);
              }
            });
        } else {
          history.push(buildThankYouUri(), { confirmationCode: newBooking.code });
        }
      })
      .catch(ex => {
        let errorMessage = 'Something went wrong when creating your booking. Please try again later or contact our support.';

        if (ex.code === '40002') {
          clearBooking();
          onCloseDrawer();
          errorMessage = 'The start date is set before today, please select a valid date and try again.';
        }

        message.error(errorMessage);
      })
      .finally(() => {
        setIsConfirmBookingLoading(false);
      });
  };

  const handleOnChangeRoomCount = index => roomCount => {
    const room = rooms[index];
    updateBookingItem(index, { ...room, roomCount: roomCount || 1 });
  };

  const handleOnRemoveUnit = index => () => {
    Modal.confirm({
      title: 'Are you sure you want to remove this listing from your booking?',
      onOk: () => {
        removeBookingItem(index);
      },
      okText: 'Yes',
      cancelText: 'No'
    });
  };

  const handleOnRemarksClick = index => () => {
    setSelectedRoomIndex(index);
    setIsRemarksModalVisible(true);
  };

  const handleOnRemarkModalClose = () => {
    setSelectedRoomIndex(-1);
    setIsRemarksModalVisible(false);
  };

  const handleOnAddUpdateRemarks = remarks => {
    updateBookingItem(selectedRoomIndex, { ...selectedRoom, remarks });
  };

  return (
    <>
      {isLoading ? (
        <Skeleton active />
      ) : (
        <>
          <Loading isLoading={isConfirmBookingLoading} />
          <Drawer
            visible={visible}
            closable={false}
            bodyStyle={{ padding: 0 }}
            width={window.innerWidth >= 768 ? 520 : '100%'}
            onClose={onCloseDrawer}
          >
            <DrawerHeader gutter={[0, 8]}>
              <Col span={24}>
                <ClickableTextButton text="Continue browsing" onClick={onCloseDrawer} />
              </Col>
              <Col span={24}>
                <Subtitle>My Booking Summary</Subtitle>
              </Col>
              {!isMemberLoggedIn && (
                <Col span={24}>
                  <Alert
                    message={
                      <>
                        <Link onClick={onLoginButtonClicked} to="#">
                          <b>Login / Register now </b>
                        </Link>
                        to book with your saved details and to view your bookings!
                      </>
                    }
                    type="warning"
                  ></Alert>
                </Col>
              )}
            </DrawerHeader>
            {hasBooking ? (
              <>
                <TitleRow gutter={[16, 16]}>
                  <Col span={24} md={20}>
                    <BoldRow>Stay Period</BoldRow>
                    <TextSecondary type="s">
                      {formattedStartDate} - {formattedEndDate}
                    </TextSecondary>
                  </Col>
                  <Col span={24} md={4}>
                    <BoldRow>Nights</BoldRow>
                    <TextSecondary type="s">{bookingNights}</TextSecondary>
                  </Col>
                  <Col span={24}>
                    <BoldRow>Property</BoldRow>
                    <BoldRow>
                      <TextSecondary type="s">
                        <Link to={buildPropertyDetailUri(property._id)}>{property.name}</Link>
                      </TextSecondary>
                    </BoldRow>
                  </Col>
                </TitleRow>
                <div>
                  {rooms.map((room, index) => (
                    <BookingRow
                      key={room._id}
                      room={room}
                      onChangeRoomCount={handleOnChangeRoomCount(index)}
                      onRemoveClick={handleOnRemoveUnit(index)}
                      onRemarksClick={handleOnRemarksClick(index)}
                    />
                  ))}
                </div>
                <DrawerTotalPriceRow gutter={[0, 8]} align="middle">
                  {isValidDiscount && isShowPackageCode && (
                    <>
                      <Col span={24} md={12}>
                        <TextSecondary>Surcharge </TextSecondary>
                        <Tooltip
                          title="Surcharge applicable for weekends and national holidays. State holidays may have additional surcharge on top of this charges."
                          placement="rightTop"
                        >
                          <QuestionCircleOutlined />
                        </Tooltip>
                      </Col>
                      <DrawerTotalPriceValueCol span={24} md={12}>
                        <TextSecondary>{constructDisplayPrice(surcharge)}</TextSecondary>
                      </DrawerTotalPriceValueCol>
                    </>
                  )}

                  <Col span={24} md={12}>
                    <TextSecondary>Total price</TextSecondary>
                  </Col>
                  <DrawerTotalPriceValueCol span={24} md={12}>
                    {isValidDiscount ? (
                      <Row align="top">
                        <Title type="s">{constructDisplayPrice(displayTotalAfterDiscount)}</Title>
                        <TextSecondary type="s">
                          <StyledTotalPrice isValidDiscount={isValidDiscount}>{constructDisplayPrice(totalRoomRate, false)}</StyledTotalPrice>
                        </TextSecondary>
                      </Row>
                    ) : (
                      <Title type="s">
                        <StyledTotalPrice isValidDiscount={isValidDiscount}>{constructDisplayPrice(totalRoomRate)}</StyledTotalPrice>
                      </Title>
                    )}
                  </DrawerTotalPriceValueCol>
                </DrawerTotalPriceRow>

                <PackageCodeAndPromotion
                  selectedDiscountType={selectedDiscountType}
                  discountCodeErrorMessage={discountCodeErrorMessage}
                  memberPackageCodes={packageCodes}
                  roomsNights={roomsNights}
                  isShowPackageCode={isShowPackageCode}
                  isShowPromotion={isShowPromotion}
                  isMemberLoggedIn={isMemberLoggedIn}
                  isConfiguringDiscountSetting={isConfiguringDiscountSetting}
                  isSubmitLoading={isValidating}
                  onCheckboxClick={handleOnCheckboxClick}
                  onChangeDiscountCode={handleOnChangeDiscountCode}
                  onApplyDiscountCode={handleOnApplyDiscountCode}
                />

                <Row>
                  <Button block type="primary" onClick={handleOnConfirmBookingClick} disabled={!isReadyToBook}>
                    Confirm My Booking
                  </Button>
                </Row>
                <GuestDetailsModal
                  visible={isGuestDetailsModalVisible}
                  constantSelections={selections}
                  constantDatas={datas}
                  shouldMakePayment={shouldMakePayment}
                  onCancel={handleOnConfirmBookingClose}
                  onSubmit={handleOnConfirmBookingSubmit}
                />
                {isRemarksModalVisible && (
                  <RemarksModal
                    roomName={selectedRoom.displayName}
                    defaultRemarks={selectedRoom.remarks}
                    onClose={handleOnRemarkModalClose}
                    onConfirm={handleOnAddUpdateRemarks}
                  />
                )}
              </>
            ) : (
              <EmptyBooking onCloseDrawer={onCloseDrawer} />
            )}
          </Drawer>
        </>
      )}
    </>
  );
};

export default withAppContext(withUserContext(withTrackingContext(BookingDrawer)));
