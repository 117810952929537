import React, { useMemo, useState, useEffect } from 'react';
import { ThemeProvider as EmotionThemeProvider } from 'emotion-theming';

import { updateApiHeaders } from 'apis/apiHelper';
import { postMemberLogin } from 'apis/auth';
import { getIsMemberLoggedIn, setMemberAuthObj, deleteMemberAuthObj, getMember } from 'utils/auth';

const AppContext = React.createContext();

const useTheme = () => {
  const originalTheme = useMemo(
    () => ({
      color: {
        primary: '#17B6CC',
        secondary: '#FFCE38',
        lightSecondary: '#FFFCE6',
        green: '#52C41A',
        red: '#F5222D',
        white: '#FFFFFF',
        black: '#231F20',
        borderColor: '#E9E9E9',
        backgroundColor: '#FAFAFA',
        primaryFontColor: '#17B6CC',
        secondaryFontColor: '#FFCE38',
        paragraphFontColor: '#05171D'
      },
      fontWeight: {
        light: 200,
        regular: 400,
        bold: 600,
        bolder: 700
      },
      spacing: {
        xxs: '4px',
        xs: '8px',
        regular: '16px',
        sm: '24px',
        md: '32px',
        lg: '40px',
        xl: '48px',
        xxl: '60px',
        xxxl: '104px'
      }
    }),
    []
  );

  const [theme] = useState(originalTheme);

  return theme;
};

export const AppContextProvider = ({ children }) => {
  const theme = useTheme();

  const [member, setMember] = useState({});
  const [isLoadingMember, setIsLoadingMember] = useState(false);

  const isMemberLoggedIn = getIsMemberLoggedIn();
  const memberName = member.name;

  useEffect(() => {
    const member = getMember();
    setMember(member);
  }, []);

  const onLogin = (email, password) => {
    setIsLoadingMember(true);
    return postMemberLogin(email, password)
      .then(memberData => {
        setMemberAuthObj(memberData);
        setMember(memberData.member);
        updateApiHeaders();
        return memberData;
      })
      .finally(() => setIsLoadingMember(false));
  };

  const onLogout = () => {
    deleteMemberAuthObj();
    setMember({});
    updateApiHeaders();
  };

  return (
    <AppContext.Provider value={{ isMemberLoggedIn, member, memberName, onLogin, onLogout, isLoadingMember }}>
      <EmotionThemeProvider theme={theme}>{children}</EmotionThemeProvider>
    </AppContext.Provider>
  );
};

export const AppContextConsumer = AppContext.Consumer;

export const withAppContext = Component => {
  const AppContextComponent = props => <AppContextConsumer>{appContextProps => <Component {...appContextProps} {...props} />}</AppContextConsumer>;
  return AppContextComponent;
};
