import React from 'react';
import { Card } from 'antd';
import { StyledCol, StyledRow, StyledCard, StyledMainTitle, StyledSecondaryTitle } from './Card.styles';
import globe from './Images/globe.png';
import hands from './Images/hands.png';
import love from './Images/love.png';
import BareImage from 'components/Image/BareImage/BareImage';
import SectionTitle from 'components/Font/SectionTitle/SectionTitle';

const { Meta } = Card;

const CardSection = () => {
  return (
    <StyledCol>
      <SectionTitle primary={'Why Book'} secondary={'With Us?'} paragraph={'We are here to make a change & create a positive impact'} />
      <StyledRow>
        <StyledCard cover={<BareImage image={globe} width={70} />}>
          <Meta
            title={<StyledMainTitle>Revitalize Malaysia’s Tourism</StyledMainTitle>}
            description={
              <StyledSecondaryTitle>
                You can do a part in supporting domestic tourism to support local businessess while giving Malaysia’s tourism a new breath of life.
              </StyledSecondaryTitle>
            }
          />
        </StyledCard>
        <StyledCard cover={<BareImage image={hands} width={70} />}>
          <Meta
            title={<StyledMainTitle>Safe & Trustworthy Stay</StyledMainTitle>}
            description={
              <StyledSecondaryTitle>
                You safety and comfort is our priority. We ensure that all rooms are cleaned and sanitized with strict guidelines.
              </StyledSecondaryTitle>
            }
          />
        </StyledCard>
        <StyledCard cover={<BareImage image={love} width={70} />}>
          <Meta
            title={<StyledMainTitle>Serving Our Community</StyledMainTitle>}
            description={
              <StyledSecondaryTitle>
                You should have the right to an improved standard of living. We are providing accessibility to quality rooms in strategic locations at
                affordable prices.
              </StyledSecondaryTitle>
            }
          />
        </StyledCard>
      </StyledRow>
    </StyledCol>
  );
};

export default CardSection;
