import React, { useState } from 'react';
import { UserOutlined, LogoutOutlined } from '@ant-design/icons';
import { Badge, Dropdown, Menu } from 'antd';
import { useHistory } from 'react-router-dom';
import env from 'config/env';
import { Cross } from 'hamburger-react';

import ExternalLink from 'components/ExternalLink/ExternalLink';
import StickySearchBar from 'components/StickySearchBar/StickySearchBar';
import { withAppContext } from 'contexts/AppContext/AppContext';

import { buildMyAccountDetailsUri } from 'utils/routes';
import { staticRoutes } from 'utils/routes';

import {
  AvatarButton,
  MainContainer,
  NavButton,
  NavContainer,
  DesktopNavWrapper,
  MobileNavWrapper,
  StyledLogo,
  MenuItem,
  StyledAnchor
} from './Header.styles';
import MdecBanner from 'components/Mdecbanner/MdecBanner';

const KEY_MY_ACCOUNT = 'my-account';
const KEY_LOGOUT = 'logout';

const Header = ({
  banner,
  hasStickyHeader,
  hasMdecBanner,
  homePageURL,
  iconImage,
  onOpenDrawer,
  onLogout,
  isMemberLoggedIn,
  memberName,
  numberOfBookings,
  onLoginButtonClick,
  aboutUs
}) => {
  const history = useHistory();
  const [isSticky, setSticky] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const currentPath = history.location.pathname;

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const getInitalsFromFullName = () => {
    const nameSeperations = memberName ? memberName.split(' ') : [];
    if (nameSeperations.length > 0) {
      const firstInitial = nameSeperations[0] ? nameSeperations[0].charAt(0).toUpperCase() : '';
      const secondInitial = nameSeperations[1] ? nameSeperations[1].charAt(0).toUpperCase() : '';
      return `${firstInitial}${secondInitial}`;
    }
    return 'AD';
  };

  const stickSearchBarToHeader = () => {
    const header = document.getElementById('searchBarSection');

    const stickSearchBarOnScroll = () => {
      const offsetTop = header && header.offsetTop;
      const currentWindowWidthSize = window.innerWidth || 360;

      if (hasStickyHeader && window.pageYOffset > offsetTop && Number(currentWindowWidthSize) > 768) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    };

    window.onscroll = () => stickSearchBarOnScroll();
  };

  const handleOnMenuItemClick = e => {
    const uri = e.key;
    if (uri === KEY_LOGOUT) {
      return onLogout();
    }
    if (uri === KEY_MY_ACCOUNT) {
      return history.push(buildMyAccountDetailsUri());
    }
    history.push(uri);
  };

  const menu = (
    <Menu onClick={handleOnMenuItemClick}>
      <MenuItem key={KEY_MY_ACCOUNT}>
        <UserOutlined />
        My Account
      </MenuItem>
      <MenuItem key={KEY_LOGOUT}>
        <LogoutOutlined />
        Logout
      </MenuItem>
    </Menu>
  );

  const staticNavBar = (
    <>
      <NavButton
        showIcon={false}
        text={
          <Badge>
            <span>About DE Rantau</span>
          </Badge>
        }
        onClick={aboutUs}
      />
      <NavButton
        showIcon={false}
        text={
          <Badge>
            <StyledAnchor target="_blank" rel="noopener noreferrer" href="https://hostastay.com/">
              HostAStay
            </StyledAnchor>
          </Badge>
        }
      />
      {isMemberLoggedIn ? (
        <Dropdown overlay={menu} placement="bottomRight" trigger="click">
          <AvatarButton>{getInitalsFromFullName()}</AvatarButton>
        </Dropdown>
      ) : (
        <NavButton showIcon={false} text="Login/Sign Up" onClick={onLoginButtonClick} />
      )}
    </>
  );

  const propertyNavBar = (
    <>
      {JSON.parse(env.IS_BOOKING_ENABLE) && isMemberLoggedIn && (
        <NavButton
          showIcon={false}
          text={
            <Badge count={numberOfBookings || 0} overflowCount={9} offset={[10, -2]}>
              <span>My Booking</span>
            </Badge>
          }
          onClick={onOpenDrawer}
        />
      )}

      {isMemberLoggedIn ? (
        <Dropdown overlay={menu} placement="bottomRight" trigger="click">
          <AvatarButton>{getInitalsFromFullName()}</AvatarButton>
        </Dropdown>
      ) : (
        <NavButton showIcon={false} text="Login/Sign Up" onClick={onLoginButtonClick} />
      )}
    </>
  );

  stickSearchBarToHeader();
  return (
    <MainContainer>
      <NavContainer>
        <span>
          <ExternalLink to={homePageURL}>
            <StyledLogo src={iconImage} alt="HostAStay"></StyledLogo>
          </ExternalLink>
        </span>
        {!hasMdecBanner && (
          <MobileNavWrapper open={isOpen}>
            <Cross toggled={isOpen} toggle={toggle} />

            <ul>{staticRoutes.includes(currentPath) ? staticNavBar : propertyNavBar}</ul>
          </MobileNavWrapper>
        )}
        {!hasMdecBanner && <DesktopNavWrapper>{staticRoutes.includes(currentPath) ? staticNavBar : propertyNavBar}</DesktopNavWrapper>}
      </NavContainer>
      {hasStickyHeader && <StickySearchBar banner={banner} isSticky={isSticky} />}
      {hasMdecBanner && <MdecBanner />}
    </MainContainer>
  );
};

export default withAppContext(Header);
