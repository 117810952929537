import { Col, Row } from 'antd';
import React from 'react';
import {
  CasualPar,
  FooterBody,
  FooterContainer,
  InnerImage,
  KeyAreaImg,
  KeyAreaTitle,
  LargePar,
  MdecAboutBackground,
  NomadBtn,
  NomadCard,
  NomadCardContainer,
  NomadImg,
  NomadsContainer,
  OuterImage,
  StyledTitle,
  StyledTitleH4
} from './AboutDeRantau.styles';
import KeyArea1 from 'images/key-area-1.png';
import KeyArea2 from 'images/key-area-2.png';
import KeyArea3 from 'images/key-area-3.png';
import Nomads1 from 'images/nomads-1.png';
import Nomads2 from 'images/nomads-2.png';
import Place1 from 'images/place-1.webp';
import Place2 from 'images/place-2.png';
import Place3 from 'images/place-3.png';
import Place4 from 'images/place-4.webp';
import { useHistory } from 'react-router-dom';
import { useFetchConstant } from 'apis/constants';

const AboutDeRantau = () => {
  const history = useHistory();
  const { isLoading: isLoadingStateMY, selection: stateMYSelection } = useFetchConstant('statesMY');
  let kedahCode = '';
  let klCode = '';
  let selangorCode = '';
  let pPinangCode = '';
  if (!isLoadingStateMY) {
    stateMYSelection.forEach(state => {
      switch (state.label) {
        case 'Kedah':
          kedahCode = state.code;
          break;
        case 'W.P Kuala Lumpur':
          klCode = state.code;
          break;
        case 'Selangor':
          selangorCode = state.code;
          break;
        case 'Pulau Pinang':
          pPinangCode = state.code;
          break;
        default:
          break;
      }
    });
  }

  const redirectToMdecWeb = () => window.open('https://mdec.my/derantau', '_blank');
  const openUnitList = destinationCode => history.push(`/property?adultPax=1&childPax=0&sortingCriteria=priceAscending&state=${destinationCode}`);

  return (
    <div style={{ marginTop: '-1.5rem' }}>
      <MdecAboutBackground>
        <StyledTitle>What is DE Rantau?</StyledTitle>
        <LargePar>
          The DE Rantau programme aims at establishing Malaysia as the preferred digital nomad hub in ASEAN while boosting digital adoption and
          promoting digital professional mobility and tourism across the country.
        </LargePar>
        <CasualPar>
          The DE Rantau programme will benefit both local talents and foreign nomads via the creation of a vibrant ecosystem that suports digital
          nomads. Digital nomads can travel and work remotely across various locations in Malaysia, while having access to stable broadband
          connectivity and various other facilities and services that support the nomadic lifestyle.
        </CasualPar>
        <hr style={{ borderTop: '1px solid #0C20B3', margin: '2rem 0' }} />
        <StyledTitle>DE Rantau Key Areas</StyledTitle>
        <Row gutter={[0, 16]}>
          <Col xs={24} md={8} style={{ textAlign: 'center' }}>
            <KeyAreaImg src={KeyArea1} alt="keyArea1" />
            <KeyAreaTitle>The creation of DE Rantau Hubs</KeyAreaTitle>
          </Col>
          <Col xs={24} md={8} style={{ textAlign: 'center' }}>
            <KeyAreaImg src={KeyArea2} alt="keyArea2" />
            <KeyAreaTitle>Digital nomad communities and ecosystems</KeyAreaTitle>
          </Col>
          <Col xs={24} md={8} style={{ textAlign: 'center' }}>
            <KeyAreaImg src={KeyArea3} alt="keyArea3" />
            <KeyAreaTitle>DE Rantau Nomad Pass for qualified foreign digital nomads</KeyAreaTitle>
          </Col>
        </Row>
      </MdecAboutBackground>
      <NomadsContainer>
        <StyledTitle style={{ paddingTop: 30 }}>All Nomads Are Welcome!</StyledTitle>
        <Row>
          <Col xs={24} lg={12}>
            <NomadCardContainer>
              <NomadCard>
                <div>
                  <NomadImg src={Nomads1} alt="NomadImg1" />
                </div>
                <div style={{ padding: '1rem' }}>
                  <StyledTitleH4>Foreign Digital Nomads</StyledTitleH4>
                  <CasualPar>
                    Malaysia welcomes digital professionals from all over the world to join the DE Rantau programme. MDEC facilities your stay in
                    Malaysia via new digital nomad pass, nomad-ready hubs and specially curated services and facilities to ease your stay for
                    productive and memorable nomad experience around the country.
                  </CasualPar>
                </div>
              </NomadCard>
            </NomadCardContainer>
          </Col>
          <Col xs={24} lg={12}>
            <NomadCardContainer>
              <NomadCard>
                <div>
                  <NomadImg src={Nomads2} alt="NomadImg2" />
                </div>
                <div style={{ padding: '1rem' }}>
                  <StyledTitleH4>Local Digital Nomads</StyledTitleH4>
                  <CasualPar>
                    We welcome Malaysian digital professionals to join DE Rantau where MDEC facilities digital nomads with nomad-ready hubs, specially
                    curated services and facilities for productive and memorable nomad experience around the country.
                  </CasualPar>
                </div>
              </NomadCard>
            </NomadCardContainer>
          </Col>
        </Row>
        <div style={{ textAlign: 'center', padding: '50px 0' }}>
          <NomadBtn onClick={redirectToMdecWeb}>BE NOMAD {'>'}</NomadBtn>
        </div>
      </NomadsContainer>
      <FooterContainer>
        <FooterBody>
          <StyledTitle>Beautiful Places Around the Malaysia</StyledTitle>
          <Row>
            <Col xs={24} lg={8}>
              <div style={{ position: 'relative', height: '100%', cursor: 'pointer' }} onClick={() => openUnitList(kedahCode)}>
                <OuterImage src={Place1} alt="place1" />
                <StyledTitle style={{ color: '#fff', position: 'absolute', left: 40, bottom: 0 }}>Kedah</StyledTitle>
              </div>
            </Col>
            <Col xs={24} lg={8}>
              <div style={{ position: 'relative', cursor: 'pointer' }} onClick={() => openUnitList(klCode)}>
                <InnerImage src={Place2} alt="place2" />
                <StyledTitle style={{ color: '#fff', position: 'absolute', left: 40, bottom: 0 }}>Kuala Lumpur</StyledTitle>
              </div>
              <div style={{ position: 'relative', cursor: 'pointer' }} onClick={() => openUnitList(selangorCode)}>
                <InnerImage src={Place3} alt="place3" />
                <StyledTitle style={{ color: '#fff', position: 'absolute', left: 40, bottom: 0 }}>Selangor</StyledTitle>
              </div>
            </Col>
            <Col xs={24} lg={8}>
              <div style={{ position: 'relative', height: '100%', cursor: 'pointer' }} onClick={() => openUnitList(pPinangCode)}>
                <OuterImage src={Place4} alt="place4" />
                <StyledTitle style={{ color: '#fff', position: 'absolute', left: 40, bottom: 0 }}>Pulau Pinang</StyledTitle>
              </div>
            </Col>
          </Row>
        </FooterBody>
      </FooterContainer>
    </div>
  );
};

export default AboutDeRantau;
