import React from 'react';
import { Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';

import { withAppContext } from 'contexts/AppContext/AppContext';
import { buildMyAccountDetailsUri, buildMyAccountPackagesUri, buildMyAccountStaysUri } from 'utils/routes';

import { StyledMenu } from './NavBar.styles';

const myAccProfileRoute = { path: buildMyAccountDetailsUri(), key: 'profile' };
const myAccPackagesRoute = { path: buildMyAccountPackagesUri(), key: 'packages' };
const myAccStaysRoute = { path: buildMyAccountStaysUri(), key: 'stays' };

const MenuItemText = ({ path, label, onClick }) => {
  return (
    <Link to={path} onClick={onClick}>
      <span>{label}</span>
    </Link>
  );
};

const getCurrentPathMenuKey = pathname => {
  switch (true) {
    case pathname.startsWith(myAccProfileRoute.path):
      return myAccProfileRoute.key;
    case pathname.startsWith(myAccPackagesRoute.path):
      return myAccPackagesRoute.key;
    case pathname.startsWith(myAccStaysRoute.path):
      return myAccStaysRoute.key;

    default:
      return '';
  }
};

const NavBar = ({ mode }) => {
  const location = useLocation();

  const currentKey = getCurrentPathMenuKey(location.pathname);

  return (
    <StyledMenu mode={mode || 'inline'} defaultSelectedKeys={[currentKey]} selectedKeys={[currentKey]} onClick={() => {}}>
      <Menu.Item key={myAccProfileRoute.key}>
        <MenuItemText path={myAccProfileRoute.path} label="Profile" />
      </Menu.Item>
      <Menu.Item key={myAccStaysRoute.key}>
        <MenuItemText path={myAccStaysRoute.path} label="Stays" />
      </Menu.Item>
      <Menu.Item key={myAccPackagesRoute.key}>
        <MenuItemText path={myAccPackagesRoute.path} label="Packages" />
      </Menu.Item>
    </StyledMenu>
  );
};

export default withAppContext(NavBar);
