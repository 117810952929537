import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, Checkbox, Col, Row, Select } from 'antd';

import FormLabel from 'components/FormLabel/FormLabel';
import Input from 'components/Input/Input';

import { LABEL_SIZE_LARGE } from 'utils/constants';

import { BookingCard, DrawerApplyCheckBoxRow } from './PackageCodeAndPromotion.styles';

const { Option } = Select;
const { Group: CheckboxGroup } = Checkbox;

// const DISCOUNT_TYPE_PACKAGE_CODE = 'discountTypePackageCode';
const DISCOUNT_TYPE_PROMOTION = 'discountTypePromotion';

/* =============================================== Sub Component =============================================== */

const DiscountTypeTitle = ({ isShowPackageCode }) => {
  const title = isShowPackageCode ? 'Package Code' : 'Promotion Code';

  return (
    <Col span={24}>
      <FormLabel labelSize={LABEL_SIZE_LARGE}>{title}</FormLabel>
    </Col>
  );
};

const AlertMessage = ({ isShowPackageCode, roomsNights }) => {
  const discountTypeText = isShowPackageCode ? 'package Code' : 'promotion Code';
  const roomsNightsText = isShowPackageCode && roomsNights && (
    <span>
      This booking costs <b>{roomsNights}</b>.{' '}
    </span>
  );

  return (
    <Col span={24}>
      <Alert
        message={
          <span>
            Please apply your {discountTypeText} before confirming the booking. {roomsNightsText}
          </span>
        }
        type="warning"
        showIcon
      />
    </Col>
  );
};

const DiscountCodeErrorMessage = ({ discountCodeErrorMessage }) => {
  return (
    <Col span={24}>
      <Alert message={<span>{discountCodeErrorMessage}</span>} type="error" showIcon />
    </Col>
  );
};

const DiscountCodeInputForm = ({
  isShowPackageCode,
  isMemberLoggedIn,

  memberPackageCodes,

  onChangeDiscountCode
}) => {
  if (isShowPackageCode) {
    return isMemberLoggedIn ? (
      <Col span={24}>
        <Select onChange={onChangeDiscountCode} style={{ width: '100%' }}>
          {memberPackageCodes.map(code => (
            <Option value={code.value} disabled={code.shouldDisable}>
              {code.label}
            </Option>
          ))}
        </Select>
      </Col>
    ) : (
      <Col span={24}>
        <Input placeholder="Apply your code to redeem room nights!" onChange={onChangeDiscountCode} />
      </Col>
    );
  } else {
    return (
      <Col span={24}>
        <Input placeholder="Apply your promotion code here!" onChange={onChangeDiscountCode} />
      </Col>
    );
  }
};

/* =============================================== Main Component =============================================== */

const PackageCodeAndPromotion = ({
  selectedDiscountType,
  discountCodeErrorMessage,
  memberPackageCodes,
  roomsNights,

  isShowPackageCode,
  isShowPromotion,
  isMemberLoggedIn,
  isConfiguringDiscountSetting,
  isSubmitLoading,

  onCheckboxClick,
  onChangeDiscountCode,
  onApplyDiscountCode
}) => {
  const isShowBookingCard = useMemo(() => isShowPackageCode || isShowPromotion, [isShowPackageCode, isShowPromotion]);

  return (
    <>
      <DrawerApplyCheckBoxRow align="middle" justify="center">
        <CheckboxGroup onChange={onCheckboxClick} value={selectedDiscountType} style={{ width: '100%' }}>
          <Row justify="space-around">
            <Col span={24} md={12}>
              {/* <Checkbox value={DISCOUNT_TYPE_PACKAGE_CODE}>Apply Package Code?</Checkbox> */}
            </Col>
            <Col span={24} md={12}>
              <Checkbox value={DISCOUNT_TYPE_PROMOTION}>Apply Promotion Code?</Checkbox>
            </Col>
          </Row>
        </CheckboxGroup>
      </DrawerApplyCheckBoxRow>

      {isShowBookingCard && (
        <BookingCard>
          <Row gutter={[16, 8]}>
            <DiscountTypeTitle isShowPackageCode={isShowPackageCode} />
            <AlertMessage isShowPackageCode={isShowPackageCode} roomsNights={roomsNights} />
            {discountCodeErrorMessage && <DiscountCodeErrorMessage discountCodeErrorMessage={discountCodeErrorMessage} />}
            <DiscountCodeInputForm
              isShowPackageCode={isShowPackageCode}
              isMemberLoggedIn={isMemberLoggedIn}
              memberPackageCodes={memberPackageCodes}
              onChangeDiscountCode={onChangeDiscountCode}
            />
            <Col span={24}>
              <Button type="primary" block disabled={!isConfiguringDiscountSetting} onClick={onApplyDiscountCode} loading={isSubmitLoading}>
                Apply
              </Button>
            </Col>
          </Row>
        </BookingCard>
      )}
    </>
  );
};

PackageCodeAndPromotion.propTypes = {
  selectedDiscountType: PropTypes.string.isRequired,
  memberPackageCodes: PropTypes.array.isRequired,
  roomsNights: PropTypes.string.isRequired,

  isShowPackageCode: PropTypes.bool.isRequired,
  isShowPromotion: PropTypes.bool.isRequired,
  isMemberLoggedIn: PropTypes.bool.isRequired,
  isConfiguringDiscountSetting: PropTypes.bool.isRequired,
  isSubmitLoading: PropTypes.bool.isRequired,

  onCheckboxClick: PropTypes.func.isRequired,
  onChangeDiscountCode: PropTypes.func.isRequired,
  onApplyDiscountCode: PropTypes.func.isRequired
};

PackageCodeAndPromotion.defaultProps = {
  selectedDiscountType: '',
  memberPackageCodes: [],
  roomsNights: '',

  isShowPackageCode: false,
  isShowPromotion: false,
  isMemberLoggedIn: false,
  isConfiguringDiscountSetting: false
};

export default PackageCodeAndPromotion;
