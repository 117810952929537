import styled from '@emotion/styled';
import { Col, Empty, Row, Skeleton, Tag } from 'antd';

export const StyledHeader = styled(Skeleton)`
  height: 35%;
`;

export const StyledBody = styled(Skeleton)`
  height: 65%;
`;

export const BodyContainer = styled(Row)``;

export const ListingMainTitle = styled.div`
  margin: 24px 0px 12px;
`;

export const StyledTag = styled(Tag)`
  margin-right: 0;
`;

export const ListingCardFooterCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
`;

export const StyledEmpty = styled(Empty)`
  margin: 96px auto;
`;
