import React from 'react';
import PropTypes from 'prop-types';

import FormItem from 'components/FormItem/FormItem';
import InputNumber from '../InputNumber';

const FormInputNumber = ({
  className,
  name,
  label,
  placeholder,

  extraRules,

  minValue,
  maxValue,
  formatter,
  parser,
  precision,
  isDisabled,

  requiredErrorMessage,

  onChange,
  ...props
}) => {
  const rules = [
    {
      pattern: precision ? /^\d+\.?\d*$/ : /^[0-9]*$/,
      message: precision ? `Only allow numeric input with ${precision} decimal points` : `Only allow numeric input`
    }
  ];

  return (
    <FormItem name={name} label={label} requiredErrorMessage={requiredErrorMessage} extraRules={rules} {...props}>
      <InputNumber
        className={className}
        placeholder={placeholder}
        minValue={minValue}
        maxValue={maxValue}
        formatter={formatter}
        parser={parser}
        precision={precision}
        onChange={onChange}
        isDisabled={isDisabled}
        {...props}
      />
    </FormItem>
  );
};

FormInputNumber.propTypes = {
  className: PropTypes.string
};

FormInputNumber.defaultProps = {
  className: ''
};

export default FormInputNumber;
