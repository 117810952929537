import React, { useEffect } from 'react';
import { Button, Col, Form } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';

import { formatToDateString, formatToDateWithMonthString } from 'utils/date';
import { getSingularOrPluralLabel, guard, getLabelOfConstant } from 'utils/general';

import LPForm from './SearchBarForm/LPForm';

import { HeaderContainerCard, MiniSearchBarFieldTitle, MiniSearchBarFieldText, StickySearchContainer, SearchContainerCard } from './SearchBar.styles';
import PropertiesForm from './SearchBarForm/PropertiesForm';
import { buildPropertyUri } from 'utils/routes';

const MiniStickSearchBar = ({ allValues, stateMYSelection, isDerantauCertifiedSelection }) => {
  const getCurrentFormFieldValue = name => {
    const formattedFields = {
      ...allValues,
      state: allValues.state && getLabelOfConstant(allValues.state, stateMYSelection),
      checkInDate: guard(() => formatToDateString(allValues.dateRange[0])),
      checkOutDate: guard(() => formatToDateString(allValues.dateRange[1])),
      adultPax: allValues.adultPax && allValues.adultPax > 0 ? allValues.adultPax : 1,
      childPax: allValues.childPax && allValues.childPax > 0 ? allValues.childPax : 0,
      isDerantauCertified: allValues.isDerantauCertified && getLabelOfConstant(allValues.isDerantauCertified, isDerantauCertifiedSelection)
    };
    return formattedFields[name];
  };

  const handleOnEditButtonClick = () =>
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });

  return (
    <StickySearchContainer type="flex" align="middle" justify="space-between">
      {getCurrentFormFieldValue('state') && (
        <Col span={0} md={6}>
          <MiniSearchBarFieldTitle>Destination</MiniSearchBarFieldTitle>
          <MiniSearchBarFieldText>{`${getCurrentFormFieldValue('state')}`}</MiniSearchBarFieldText>
        </Col>
      )}
      {!(getCurrentFormFieldValue('checkInDate') && getCurrentFormFieldValue('checkOutDate')) && (
        <Col span={18} md={0}>
          <MiniSearchBarFieldTitle>Stay Period</MiniSearchBarFieldTitle>
          <MiniSearchBarFieldText>Please select stay period</MiniSearchBarFieldText>
        </Col>
      )}
      {getCurrentFormFieldValue('checkInDate') && getCurrentFormFieldValue('checkOutDate') && (
        <Col span={18} md={10}>
          <MiniSearchBarFieldTitle>Stay Period</MiniSearchBarFieldTitle>
          <MiniSearchBarFieldText>{`${formatToDateWithMonthString(getCurrentFormFieldValue('checkInDate'))} - ${formatToDateWithMonthString(
            getCurrentFormFieldValue('checkOutDate')
          )}`}</MiniSearchBarFieldText>
        </Col>
      )}
      {getCurrentFormFieldValue('adultPax') && (
        <Col span={0} md={4}>
          <MiniSearchBarFieldTitle>Guest</MiniSearchBarFieldTitle>
          <MiniSearchBarFieldText>
            {Number(getCurrentFormFieldValue('adultPax')) + Number(getCurrentFormFieldValue('childPax'))}
          </MiniSearchBarFieldText>
        </Col>
      )}
      <Col span={6} md={4}>
        <Button type="primary" block onClick={handleOnEditButtonClick}>
          Edit
        </Button>
      </Col>
    </StickySearchContainer>
  );
};

const SearchBar = ({
  stateMYSelection,
  isDerantauCertifiedSelection,
  sortingCriteriaSelection,

  state,
  checkInDate,
  checkOutDate,
  adultPax,
  childPax,
  sortingCriteria,
  isDerantauCertified,

  onSearchButtonClick,
  isSticky
}) => {
  const [form] = Form.useForm();
  const history = useHistory();

  //? re render form if value change
  useEffect(() => {
    form.setFieldsValue({
      state,
      dateRange: [checkInDate, checkOutDate],
      adultPax,
      childPax,
      sortingCriteria,
      isDerantauCertified
    });
  }, [form, state, checkInDate, checkOutDate, adultPax, childPax, sortingCriteria, isDerantauCertified]);

  const handleOnSearchButtonClick = e => {
    e.preventDefault();
    form
      .validateFields()
      .then(values => {
        const state = values.state;
        const checkInDate = guard(() => values.dateRange[0]);
        const checkOutDate = guard(() => values.dateRange[1]);
        const adultPax = values.adultPax;
        const childPax = values.childPax;
        const sortingCriteria = values.sortingCriteria;
        const isDerantauCertified = values.isDerantauCertified;
        onSearchButtonClick(state, checkInDate, checkOutDate, adultPax, childPax, sortingCriteria, isDerantauCertified);
        history.push(buildPropertyUri());
      })
      .catch(() => {});
  };
  const location = useLocation();

  //? to make isSticky is false on landing page
  isSticky = !location.pathname === '/';

  return (
    <>
      <HeaderContainerCard id="searchBarSection" sticky={isSticky}>
        {isSticky ? (
          <MiniStickSearchBar
            allValues={
              {
                state,
                dateRange: [checkInDate, checkOutDate],
                adultPax,
                childPax,
                sortingCriteria
              } || form.getFieldsValue()
            }
            stateMYSelection={stateMYSelection}
          />
        ) : (
          <SearchContainerCard bordered={false}>
            {location.pathname === '/' ? (
              <LPForm
                form={form}
                checkInDate={checkInDate}
                checkOutDate={checkOutDate}
                state={state}
                isDerantauCertified={isDerantauCertified}
                adultPax={adultPax}
                stateMYSelection={stateMYSelection}
                isDerantauCertifiedSelection={isDerantauCertifiedSelection}
                handleOnSearchButtonClick={handleOnSearchButtonClick}
                getSingularOrPluralLabel={getSingularOrPluralLabel}
              />
            ) : (
              <PropertiesForm
                form={form}
                checkInDate={checkInDate}
                checkOutDate={checkOutDate}
                state={state}
                stateMYSelection={stateMYSelection}
                isDerantauCertifiedSelection={isDerantauCertifiedSelection}
                adultPax={adultPax}
                childPax={childPax}
                sortingCriteria={sortingCriteria}
                getSingularOrPluralLabel={getSingularOrPluralLabel}
                sortingCriteriaSelection={sortingCriteriaSelection}
                handleOnSearchButtonClick={handleOnSearchButtonClick}
              />
            )}
          </SearchContainerCard>
        )}
      </HeaderContainerCard>
    </>
  );
};

export default SearchBar;
