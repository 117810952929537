import styled from '@emotion/styled';
import { Checkbox } from 'antd';

export const InputContainer = styled.div`
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const FillDetailCheckbox = styled(Checkbox)`
  margin-top: 10px;
  margin-bottom: 5px;
`;

export const PaymentTypeRadio = styled.div`
  text-transform: uppercase;
  text-align: center;
  padding: 16px;
  width: 148px;
`;

export const PaymentTypeRadioIcon = styled.div`
  font-size: 24px;
`;

export const PaymentTypeRadioText = styled.div`
  text-transform: uppercase;
`;
