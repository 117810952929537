import React from 'react';
import { Button } from 'antd';
import { Link, useLocation } from 'react-router-dom';

import { buildBaseUri } from 'utils/routes';

import { ConfirmationCodeContainer, ContainerDiv, DescriptionContainer, TickIcon, TitleContainer } from './ThankYou.styles';

const ThankYou = () => {
  const location = useLocation();

  const { confirmationCode } = location.state || {};

  return (
    <ContainerDiv>
      <TickIcon />
      <TitleContainer>We're processing your booking request!</TitleContainer>
      <DescriptionContainer>Here is your reference code:</DescriptionContainer>
      {!!confirmationCode && <ConfirmationCodeContainer>{confirmationCode}</ConfirmationCodeContainer>}
      <DescriptionContainer>Thank you for choosing us.</DescriptionContainer>
      <DescriptionContainer>You will receive an email with your booking details shortly.</DescriptionContainer>
      <br />
      <Link to={buildBaseUri()}>
        <Button type="primary">Continue Browsing</Button>
      </Link>
    </ContainerDiv>
  );
};

export default ThankYou;
