import React from 'react';
import { Form, Modal } from 'antd';

import FormInput from 'components/Input/FormInput/FormInput';

import { ConfirmButton } from './RemarksModal.styles';

const FORM_REMARKS_KEY = 'roomRemarks';

const RemarksModal = ({ roomName, defaultRemarks, onClose, onConfirm }) => {
  const handleOnAddUpdateRemarks = values => {
    const remarks = values[FORM_REMARKS_KEY];

    onConfirm(remarks);
    onClose();
  };

  return (
    <Modal
      bodyStyle={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
      visible
      destroyOnClose={true}
      title={`Remarks for ${roomName}`}
      footer={null}
      onCancel={onClose}
    >
      <Form onFinish={handleOnAddUpdateRemarks} initialValues={{ [FORM_REMARKS_KEY]: defaultRemarks }}>
        <FormInput type="textarea" name={FORM_REMARKS_KEY} rows={4} />
        <ConfirmButton type="primary" htmlType="submit">
          Add Remarks
        </ConfirmButton>
      </Form>
    </Modal>
  );
};

export default RemarksModal;
