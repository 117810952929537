import React, { useCallback } from 'react';
import { Helmet } from 'react-helmet';

import { rounds } from 'utils/general';
import { logInfo, logWarning } from 'utils/logging';
import { REACT_APP_GTAG_ID } from 'config/env';

const TrackingContext = React.createContext();

const hasGtagId = !!REACT_APP_GTAG_ID;

const constructRoomBookingToGA = roomBooking => {
  if (!roomBooking) {
    return;
  }

  return {
    currency: 'MYR', // Currency is currently MYR, need to change to dynamic when we go intl
    items: [{ id: roomBooking._id, price: rounds(roomBooking.totalPrice / roomBooking.roomCount, 2), quantity: roomBooking.roomCount }],
    value: rounds(roomBooking.totalPrice, 2)
  };
};

const constructUserBookingToGA = userBooking => {
  if (!userBooking || (userBooking.rooms && userBooking.rooms.length === 0)) {
    return;
  }

  let totalBookingPrice = 0;

  const roomBookings = userBooking.rooms.map(room => {
    totalBookingPrice = totalBookingPrice + room.totalPrice;

    return {
      id: room._id,
      price: rounds(room.totalPrice / room.roomCount, 2),
      quantity: room.roomCount
    };
  });

  return {
    currency: 'MYR', // Currency is currently MYR, need to change to dynamic when we go intl
    items: roomBookings,
    value: totalBookingPrice
  };
};

export const TrackingContextProvider = ({ children }) => {
  const trackEvent = useCallback((event, params) => {
    if (hasGtagId) {
      if (window.gtag) {
        window.gtag('event', event, params);
      } else {
        logWarning('GA was not initialised. Will skip tracking event to GA.');
      }
    } else {
      logInfo('GA ID was not passed in. Will log the event instead.');
      logInfo('Event: ', event);
      logInfo('Params: ', JSON.stringify(params, null, 2));
    }
  }, []);

  const trackAddToCartEvent = useCallback(
    roomBooking => {
      try {
        const params = constructRoomBookingToGA(roomBooking);
        trackEvent('add_to_cart', params);
      } catch (ex) {
        logWarning('GA track add to cart event failed with error: ', ex.message);
      }
    },
    [trackEvent]
  );

  const trackRemoveRoomFromCartEvent = useCallback(
    roomBooking => {
      try {
        const params = constructRoomBookingToGA(roomBooking);
        trackEvent('remove_from_cart', params);
      } catch (ex) {
        logWarning('GA track add to cart event failed with error: ', ex.message);
      }
    },
    [trackEvent]
  );

  const trackCartEvent = useCallback(
    (gaCartEvent, userBooking) => {
      try {
        const params = constructUserBookingToGA(userBooking);
        trackEvent(gaCartEvent, params);
      } catch (ex) {
        logWarning('GA track view cart event failed with error: ', ex.message);
      }
    },
    [trackEvent]
  );

  const trackViewCartEvent = useCallback(
    userBooking => {
      trackCartEvent('view_cart', userBooking);
    },
    [trackCartEvent]
  );

  const trackRemoveAllRoomsFromCartEvent = useCallback(
    userBooking => {
      trackCartEvent('remove_from_cart', userBooking);
    },
    [trackCartEvent]
  );

  const trackBeginCheckoutEvent = useCallback(
    userBooking => {
      trackCartEvent('begin_checkout', userBooking);
    },
    [trackCartEvent]
  );

  const trackPurchaseEvent = useCallback(
    (bookingCode, userBooking) => {
      try {
        const bookingParams = constructUserBookingToGA(userBooking);
        const params = {
          transaction_id: bookingCode,
          ...bookingParams
        };
        trackEvent('purchase', params);
      } catch (ex) {
        logWarning('GA track purchase event failed with error: ', ex.message);
      }
    },
    [trackEvent]
  );

  return (
    <TrackingContext.Provider
      value={{
        trackAddToCartEvent,
        trackViewCartEvent,
        trackRemoveAllRoomsFromCartEvent,
        trackBeginCheckoutEvent,
        trackPurchaseEvent,
        trackRemoveRoomFromCartEvent
      }}
    >
      {hasGtagId && (
        <Helmet>
          <script async src={`https://www.googletagmanager.com/gtag/js?id=${REACT_APP_GTAG_ID}`}></script>
          <script>
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag() { dataLayer.push(arguments); }
              gtag('js', new Date());
              gtag('config', '${REACT_APP_GTAG_ID}');
            `}
          </script>
        </Helmet>
      )}

      {children}
    </TrackingContext.Provider>
  );
};

export const TrackingContextConsumer = TrackingContext.Consumer;

export const withTrackingContext = Component => {
  const TrackingContextComponent = props => (
    <TrackingContextConsumer>{trackingContextProps => <Component {...trackingContextProps} {...props} />}</TrackingContextConsumer>
  );
  return TrackingContextComponent;
};
