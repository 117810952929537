import styled from '@emotion/styled';
import { InfoCircleFilled } from '@ant-design/icons';
import { Col, Row } from 'antd';

const defaultStatusBgColor = '#231F20';

const tagTypeColor = {
  pending: defaultStatusBgColor,
  stripeUnpaid: defaultStatusBgColor,
  confirm: '#52C41A',
  cancel: '#F5222D',
  expired: '#757575'
};

export const StaysContentRow = styled(Row)`
  padding: 0 8px 0 0;
`;

export const BookingCodeLabel = styled.p`
  font-size: 24px;
  font-weight: ${props => props.theme.fontWeight.bold};
  color: ${props => props.theme.color.secondary};
  margin-bottom: 0px;
`;

export const StatusTag = styled.div`
  height: ${props => props.theme.spacing.lg};
  display: inline-block;
  padding: ${props => props.theme.spacing.xs} ${props => props.theme.spacing.regular};
  font-size: 14px;
  border-radius: 3px;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  border: 1px solid transparent;
  font-weight: ${props => props.theme.fontWeight.regular};
  white-space: nowrap;
  text-align: center;
  background: ${props => tagTypeColor[`${props.type}`] || defaultStatusBgColor};
  color: ${props => props.theme.color.white};
`;

export const StatusCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
`;

export const StatusInfoIcon = styled(InfoCircleFilled)`
  cursor: pointer;
  color: ${props => props.theme.color.secondary};
`;
