import React from 'react';
import { Col, Row } from 'antd';
import PropTypes from 'prop-types';

import FormLabel from 'components/FormLabel/FormLabel';
import FormInput from 'components/Input/FormInput/FormInput';
import FormSelection from 'components/FormSelection/FormSelection';

import { StyledFormItem } from './FormGroupSelectionInput.styles';

const FormGroupSelectionInput = ({
  disabled,
  name,
  selectionName,
  inputName,
  label,
  requiredErrorMessage,

  selections,
  selectionPlaceholder,
  selectionDefaultValue,

  inputType,
  inputPlaceholder,
  inputDefaultValue,

  ...props
}) => {
  return (
    <>
      <FormLabel hasRequiredErrorMessage={!!requiredErrorMessage} {...props}>
        {label}
      </FormLabel>
      <StyledFormItem>
        <Row>
          <Col span={6}>
            <FormSelection
              noStyle
              name={[name, selectionName]}
              placeholder={selectionPlaceholder}
              selections={selections}
              isAllowClear={!requiredErrorMessage}
              requiredErrorMessage={requiredErrorMessage}
              disabled={disabled}
              defaultValue={selectionDefaultValue}
            />
          </Col>
          <Col span={18}>
            <FormInput
              noStyle
              type={inputType}
              name={[name, inputName]}
              placeholder={inputPlaceholder}
              requiredErrorMessage={requiredErrorMessage}
              disabled={disabled}
              defaultValue={inputDefaultValue}
            />
          </Col>
        </Row>
      </StyledFormItem>
    </>
  );
};

FormGroupSelectionInput.propTypes = {
  disabled: PropTypes.bool,
  extraProps: PropTypes.object,
  requiredErrorMessage: PropTypes.string,
  selections: PropTypes.array
};

FormGroupSelectionInput.defaultProps = {
  disabled: false,
  extraProps: {},
  selections: []
};

export default FormGroupSelectionInput;
