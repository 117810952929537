import styled from '@emotion/styled';

import Font from '../Font';

const fontSizeForTitle = {
  s: '12px',
  default: '16px',
  md: '20px'
};

export const Text = styled(Font)`
  color: ${props => props.theme.color.textSecondary};
  font-size: ${props => fontSizeForTitle[`${props.type}`]};
`;
