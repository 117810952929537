import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useCookies, CookiesProvider } from 'react-cookie';
import { useHistory, useLocation } from 'react-router-dom';
import { message, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { withAppContext } from 'contexts/AppContext/AppContext';
import { withTrackingContext } from 'contexts/TrackingContext/TrackingContext';
import { SORTING_CRITERIAS } from 'utils/constants';
import {
  checkIsDate,
  formatToDateString,
  formatToMomentObject,
  getTodayMoment,
  getTomorrowMoment,
  getOneWeekLaterMoment,
  getDifferenceBetweenDate
} from 'utils/date';
import { checkIsObjectEmpty, checkHasValue, constructQueryAsObject, constructQueryString, guard } from 'utils/general';
import { buildBaseUri, buildPropertyUri, buildPromotionUri } from 'utils/routes';

const UserContext = React.createContext();

const COOKIE_KEY_BOOKING = 'booking';
const COOKIE_KEY_SEARCH_FLTER = 'search and filter';
const COOKIE_KEY_VISIT_BEFORE = 'visit before';
const COOKIE_OPTIONS = {
  path: `/`
};

const DEFAULT_STATE = undefined;
const DEFAULT_CHECKINDATE = undefined;
const DEFAULT_CHECKOUTDATE = undefined;
const DEFAULT_ADULTPAX = 2;
const DEFAULT_CHILDPAX = 0;
const DEFAULT_SORTINGCRITERIA = SORTING_CRITERIAS.find(s => s.isDefault).value;
const DEFAULT_DERANTAU_CERTIFIED = undefined;

const DEFAULT_SEARCH_AND_FILTER = {
  state: DEFAULT_STATE,
  checkInDate: DEFAULT_CHECKINDATE,
  checkOutDate: DEFAULT_CHECKOUTDATE,
  adultPax: DEFAULT_ADULTPAX,
  childPax: DEFAULT_CHILDPAX,
  sortingCriteria: DEFAULT_SORTINGCRITERIA,
  isDerantauCertified: DEFAULT_DERANTAU_CERTIFIED
};

const constructCookieOptions = () => {
  return {
    ...COOKIE_OPTIONS,
    expires: getTomorrowMoment().toDate()
  };
};

const constructCookieOptionsForVisit = () => {
  return {
    ...COOKIE_OPTIONS,
    expires: getOneWeekLaterMoment().toDate()
  };
};

const checkIfUrlRequiredSearchParams = currentPath => {
  switch (true) {
    case currentPath.startsWith(buildPropertyUri()):
      return true;
    case currentPath.startsWith(buildPromotionUri()):
      return true;
    case currentPath === buildBaseUri():
      return true;
    default:
      return false;
  }
};

const checkIfNeedToRedirectSearchResultPage = (newSearchAndFilter, previousSearchAndFilter, currentPath) => {
  return newSearchAndFilter.state !== previousSearchAndFilter.state && currentPath.startsWith(buildBaseUri());
};

const checkIfOneOfRequiredSearchParamMissing = currentSearchParamsStr => {
  const currentSearchParamsObj = constructQueryAsObject(currentSearchParamsStr);
  return ['adultPax', 'childPax', 'sortingCriteria'].find(searchParam => !checkHasValue(currentSearchParamsObj[searchParam]));
};

const checkIfSearchParamsSame = (currentSearchParamsStr, newSearchParamsStr) => {
  const covertSearchParamToStdObjString = searchParam => {
    const searchParamObj = constructQueryAsObject(searchParam);
    return JSON.stringify({
      state: searchParamObj.state,
      checkInDate: searchParamObj.checkInDate,
      checkOutDate: searchParamObj.checkOutDate,
      adultPax: searchParamObj.adultPax,
      childPax: searchParamObj.childPax,
      sortingCriteria: searchParamObj.sortingCriteria,
      isDerantauCertified: searchParamObj.isDerantauCertified
    });
  };
  const currentSearchParamsObjString = covertSearchParamToStdObjString(currentSearchParamsStr);
  const newSearchParamsObjString = covertSearchParamToStdObjString(newSearchParamsStr);
  return currentSearchParamsObjString === newSearchParamsObjString;
};

const checkHasSSWPSearchParams = searchParamsStr => {
  const currentSearchParamsObj = constructQueryAsObject(searchParamsStr);
  return !!['ss_adult_pax', 'ss_child_pax', 'ss_check_in_date', 'ss_check_out_date', 'ss_state', 'ss_derantau_Certified'].find(searchParam =>
    checkHasValue(currentSearchParamsObj[searchParam])
  );
};

const formatSSWPSearchParams = searchParamsStr => {
  const currentSearchParamsObj = constructQueryAsObject(searchParamsStr);

  const convertSSState = ssStateQueryValue => {
    if (!checkHasValue(ssStateQueryValue)) {
      return;
    }

    // Currently state mapping is hardcoded
    // Source is in HP -> constants
    const ssStateToHPStateCodeMapping = {
      '131': '14',
      '132': '15',
      '133': '16',
      '134': '01',
      '135': '02',
      '136': '03',
      '137': '04',
      '138': '05',
      '139': '06',
      '140': '08',
      '141': '09',
      '142': '07',
      '143': '12',
      '144': '13',
      '145': '10',
      '146': '11'
    };

    return ssStateToHPStateCodeMapping[ssStateQueryValue];
  };

  const converSSDate = ssDateQueryValue => {
    if (!checkHasValue(ssDateQueryValue)) {
      return;
    }

    const ssDates = Array.isArray(ssDateQueryValue) ? ssDateQueryValue : [ssDateQueryValue];
    const foundDateWithValidFormat = ssDates.find(date => guard(() => !String(date).includes('/'), false));

    return foundDateWithValidFormat;
  };

  const formattedSSState = convertSSState(currentSearchParamsObj.ss_state);
  const formattedSSCheckInDate = converSSDate(currentSearchParamsObj.ss_check_in_date);
  const formattedSSCheckOutDate = converSSDate(currentSearchParamsObj.ss_check_out_date);
  const formattedSSAdultPax = currentSearchParamsObj.ss_adult_pax ? Number(currentSearchParamsObj.ss_adult_pax) : undefined;
  const formattedSSChildPax = currentSearchParamsObj.ss_child_pax ? Number(currentSearchParamsObj.ss_child_pax) : undefined;
  const formattedSSDerantauCertified = currentSearchParamsObj.ss_derantau_Certified
    ? Number(currentSearchParamsObj.ss_derantau_Certified)
    : undefined;

  return {
    ...DEFAULT_SEARCH_AND_FILTER,
    ...(formattedSSState && { state: formattedSSState }),
    ...(formattedSSCheckInDate && { checkInDate: formattedSSCheckInDate }),
    ...(formattedSSCheckOutDate && { checkOutDate: formattedSSCheckOutDate }),
    ...(formattedSSAdultPax && { adultPax: formattedSSAdultPax }),
    ...(formattedSSChildPax && { childPax: formattedSSChildPax }),
    ...(formattedSSDerantauCertified && { isDerantauCertified: formattedSSDerantauCertified })
  };
};

// Search values are all cached in cookies and default from url params
// Source of truth value is from cookies
// URL params will update cookies value if present
const useSearchAndFilter = (history, urlParams) => {
  const [cookies, setCookie] = useCookies([COOKIE_KEY_SEARCH_FLTER]);
  const cookieOptions = useMemo(() => constructCookieOptions(), []);
  const currentPath = history.location.pathname;

  const searchAndFilter = useMemo(() => {
    if (cookies && cookies[COOKIE_KEY_SEARCH_FLTER]) {
      return cookies[COOKIE_KEY_SEARCH_FLTER];
    }

    return {};
  }, [cookies]);

  const searchAndFilterForRender = useMemo(() => {
    const checkInDate = formatToMomentObject(searchAndFilter.checkInDate);
    const checkOutDate = formatToMomentObject(searchAndFilter.checkOutDate);

    return { ...searchAndFilter, checkInDate, checkOutDate };
  }, [searchAndFilter]);

  /* ----------------------------------------local function-----------------------------------------*/
  const getSearchAndFilterQuery = useCallback(
    ({ state, checkInDate, checkOutDate, adultPax, childPax, sortingCriteria, isDerantauCertified }) => {
      const updatedSearchAndFilter = {
        ...searchAndFilter,
        ...(checkInDate !== undefined && { checkInDate: checkInDate !== null ? formatToDateString(checkInDate) : DEFAULT_CHECKINDATE }),
        ...(checkOutDate !== undefined && { checkOutDate: checkOutDate !== null ? formatToDateString(checkOutDate) : DEFAULT_CHECKOUTDATE }),
        ...(state !== undefined && { state: state !== null ? state : DEFAULT_STATE }),
        ...(adultPax !== undefined && { adultPax: adultPax !== null ? adultPax : DEFAULT_ADULTPAX }),
        ...(childPax !== undefined && { childPax: childPax !== null ? childPax : DEFAULT_CHILDPAX }),
        ...(sortingCriteria !== undefined && { sortingCriteria: sortingCriteria !== null ? sortingCriteria : DEFAULT_SORTINGCRITERIA }),
        ...(isDerantauCertified !== undefined && {
          isDerantauCertified: isDerantauCertified !== null ? isDerantauCertified : DEFAULT_DERANTAU_CERTIFIED
        })
      };

      return updatedSearchAndFilter;
    },
    [searchAndFilter]
  );

  const constructQueryAsSearchObject = useCallback(
    queryString => {
      const queryObject = constructQueryAsObject(queryString);
      const searchAndFilterObject = getSearchAndFilterQuery({
        state: queryObject.state || null,
        checkInDate: queryObject.checkInDate || null,
        checkOutDate: queryObject.checkOutDate || null,
        adultPax: queryObject.adultPax || null,
        childPax: queryObject.childPax || null,
        sortingCriteria: queryObject.sortingCriteria || null,
        isDerantauCertified: queryObject.isDerantauCertified || null
      });

      return searchAndFilterObject;
    },
    [getSearchAndFilterQuery]
  );

  const updateUrl = useCallback(
    (newSearchAndFilter, shouldRemoveSSQueryParams = false) => {
      const oriQueryObj = constructQueryAsObject(urlParams);
      const queryObject = {
        ...oriQueryObj,
        ...constructQueryAsSearchObject(urlParams),
        ...newSearchAndFilter,
        ...(shouldRemoveSSQueryParams && {
          ss_adult_pax: undefined,
          ss_check_in_date: undefined,
          ss_check_out_date: undefined,
          ss_state: undefined,
          ss_child_pax: undefined,
          ss_derantau_Certified: undefined
        })
      };

      history.replace({
        ...history.location,
        ...(checkIfNeedToRedirectSearchResultPage(newSearchAndFilter, searchAndFilter, currentPath) && { pathname: buildPropertyUri() }),
        search: checkIfUrlRequiredSearchParams(currentPath) && `?${constructQueryString(queryObject)}`
      });
    },
    [history, urlParams, currentPath, searchAndFilter, constructQueryAsSearchObject]
  );

  // this only use for landing page searching
  const updateLandingPageUrl = useCallback(
    (newSearchAndFilter, shouldRemoveSSQueryParams = false) => {
      const oriQueryObj = constructQueryAsObject(urlParams);
      const queryObject = {
        ...oriQueryObj,
        ...constructQueryAsSearchObject(urlParams),
        ...newSearchAndFilter,
        ...(shouldRemoveSSQueryParams && {
          ss_adult_pax: undefined,
          ss_check_in_date: undefined,
          ss_check_out_date: undefined,
          ss_state: undefined,
          ss_child_pax: undefined
        })
      };

      history.push({
        ...history.location,
        ...{ pathname: buildPropertyUri() },
        search: checkIfUrlRequiredSearchParams(currentPath) && `?${constructQueryString(queryObject)}`
      });
    },
    [history, urlParams, currentPath, constructQueryAsSearchObject]
  );

  /* ----------------------------------------exported function-----------------------------------------*/
  const updateSearchAndFilter = useCallback(
    ({ state, checkInDate, checkOutDate, adultPax, childPax, sortingCriteria, isDerantauCertified }) => {
      const updatedSearchAndFilter = getSearchAndFilterQuery({
        state,
        checkInDate,
        checkOutDate,
        adultPax,
        childPax,
        sortingCriteria,
        isDerantauCertified
      });

      if (!currentPath.startsWith(buildPropertyUri())) {
        updateLandingPageUrl(updatedSearchAndFilter);
      }
      updateUrl(updatedSearchAndFilter);
    },
    [getSearchAndFilterQuery, updateUrl, currentPath, updateLandingPageUrl]
  );

  useEffect(() => {
    if (checkHasSSWPSearchParams(urlParams)) {
      // Prioritise to SS WP search params when exists
      const SSWPSearchAndFilter = formatSSWPSearchParams(urlParams);
      updateUrl(SSWPSearchAndFilter, true);
    } else if (!urlParams && !checkIsObjectEmpty(searchAndFilter)) {
      // Default back url params if cookies has the value
      updateUrl(searchAndFilter);
    } else if (!urlParams || checkIfOneOfRequiredSearchParamMissing(urlParams)) {
      // Update url to our default search params
      updateUrl(DEFAULT_SEARCH_AND_FILTER);
    }
  }, [urlParams, searchAndFilter, updateUrl]);

  useEffect(() => {
    if (!!urlParams && !checkIfSearchParamsSame(urlParams, constructQueryString(searchAndFilter))) {
      const queryObject = constructQueryAsSearchObject(urlParams);
      setCookie(COOKIE_KEY_SEARCH_FLTER, queryObject, cookieOptions);
    }
  }, [cookieOptions, urlParams, searchAndFilter, constructQueryAsSearchObject, setCookie]);

  return { searchAndFilter: searchAndFilterForRender, updateSearchAndFilter };
};

const useUserBooking = (trackAddToCartEvent, trackRemoveRoomFromCartEvent, trackRemoveAllRoomsFromCartEvent) => {
  const [cookies, setCookie, removeCookie] = useCookies([COOKIE_KEY_BOOKING]);
  const cookieOptions = useMemo(() => constructCookieOptions(), []);

  const userBooking = useMemo(() => guard(() => cookies[COOKIE_KEY_BOOKING], {}), [cookies]);

  /* ----------------------------------------local function-----------------------------------------*/
  const updateBooking = newUserBooking => {
    setCookie(COOKIE_KEY_BOOKING, newUserBooking, cookieOptions);
  };

  const showAddSuccessMessage = () => {
    message.success('Room added to your booking');
  };

  const createNewBooking = (startDate, endDate, property, room) => {
    const newUserBooking = { startDate, endDate, property, rooms: [room] };
    updateBooking(newUserBooking);
    showAddSuccessMessage();
  };

  const addToExistingBooking = (property, room) => {
    const newUserBooking = { ...userBooking, property, rooms: [...userBooking.rooms, room] };
    updateBooking(newUserBooking);
    showAddSuccessMessage();
  };

  const constructTotalPriceAndNightCost = (roomCount, startDate, endDate, pricePerNight, nightsCostPerRoomPerNight) => {
    const bookingNights = getDifferenceBetweenDate(startDate, endDate);
    const totalPrice = bookingNights * pricePerNight * roomCount;
    const nightsCost = bookingNights * nightsCostPerRoomPerNight * roomCount;

    return { totalPrice, nightsCost };
  };

  /* ----------------------------------------exported function-----------------------------------------*/
  const clearBooking = useCallback(() => {
    removeCookie(COOKIE_KEY_BOOKING, cookieOptions);
  }, [cookieOptions, removeCookie]);

  const updateBookingItem = (itemIndex, updatedItem) => {
    const { roomCount, nightsCostPerRoomPerNight, pricePerNight } = updatedItem;
    const { startDate, endDate } = userBooking;

    const { rooms } = userBooking;
    rooms[itemIndex] = {
      ...updatedItem,
      ...(rooms[itemIndex].roomCount !== roomCount &&
        constructTotalPriceAndNightCost(roomCount, startDate, endDate, pricePerNight, nightsCostPerRoomPerNight))
    };

    updateBooking({ ...userBooking, rooms });
  };

  const removeBookingItem = itemIndex => {
    const { rooms } = userBooking;

    const removedRooms = rooms.splice(itemIndex, 1);
    trackRemoveRoomFromCartEvent(removedRooms[0]);

    if (rooms.length === 0) {
      clearBooking();
      return;
    }

    updateBooking({ ...userBooking, rooms });
  };

  const addToBooking = ({
    startDate: newRoomBookingStartDate,
    endDate: newRoomBookingEndDate,
    room,
    propertyId,
    propertyName,
    isHoliStayPromo,
    isPremium,
    propertyIsAllowInstantBooking
  }) => {
    const currentBookingStartDate = userBooking.startDate;
    const currentBookingEndDate = userBooking.endDate;

    const hasDate = newRoomBookingStartDate && newRoomBookingEndDate;
    const isDatePastToday = hasDate && checkIsDate('after', newRoomBookingStartDate, getTodayMoment(), true);

    if (!hasDate || !isDatePastToday) {
      const warningMessage = !hasDate
        ? 'Search with your stay period to get more accurate price deals'
        : 'The date is set in the past, please select a new date range';
      message.warning(warningMessage);
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
      return;
    }

    const {
      _id: roomId,
      bathrooms,
      capacity: { adult, child },
      displayName,
      images,
      inventory,
      livingrooms,
      pricePerNight,
      roomCount,
      nightsCost
    } = room;

    const property = { _id: propertyId, name: propertyName, isHoliStayPromo, isPremium, isAllowInstantBooking: propertyIsAllowInstantBooking };
    const newRoomBooking = {
      _id: roomId,
      adultCapacity: adult,
      bathrooms,
      childCapacity: child,
      displayName,
      image: images && images.length && images[0],
      inventory,
      livingrooms,
      nightsCostPerRoomPerNight: nightsCost,
      pricePerNight,
      roomCount,
      ...constructTotalPriceAndNightCost(roomCount, newRoomBookingStartDate, newRoomBookingEndDate, pricePerNight, nightsCost)
    };

    if (currentBookingStartDate && currentBookingEndDate) {
      const checkIsBookingDifferentDate =
        !checkIsDate('same', currentBookingStartDate, newRoomBookingStartDate) || !checkIsDate('same', currentBookingEndDate, newRoomBookingEndDate);
      const checkIsBookingAnotherProperty = !guard(() => checkIsObjectEmpty(userBooking.property), true) && userBooking.property._id !== propertyId;

      if (checkIsBookingDifferentDate || checkIsBookingAnotherProperty) {
        const title = `You are about to make booking on another ${checkIsBookingDifferentDate ? 'date' : 'property'}`;
        Modal.confirm({
          title,
          icon: <ExclamationCircleOutlined />,
          content: 'This will reset all current booking in the cart',
          onOk() {
            trackRemoveAllRoomsFromCartEvent(userBooking);
            trackAddToCartEvent(newRoomBooking);
            createNewBooking(newRoomBookingStartDate, newRoomBookingEndDate, property, newRoomBooking);
          },
          okText: 'Yes',
          okType: 'danger',
          cancelText: 'No, I want to keep my booking',
          cancelButtonProps: {
            type: 'primary'
          }
        });
      } else {
        addToExistingBooking(property, newRoomBooking);
        trackAddToCartEvent(newRoomBooking);
      }
    } else {
      createNewBooking(newRoomBookingStartDate, newRoomBookingEndDate, property, newRoomBooking);
      trackAddToCartEvent(newRoomBooking);
    }
  };

  return { userBooking, addToBooking, updateBookingItem, clearBooking, removeBookingItem };
};

const useMyBookingDrawer = (trackViewCartEvent, userBooking) => {
  const [isOpen, setIsOpen] = useState(false);

  const openDrawer = useCallback(() => {
    setIsOpen(true);
    trackViewCartEvent(userBooking);
  }, [setIsOpen, trackViewCartEvent, userBooking]);

  const closeDrawer = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  return { isOpen, openDrawer, closeDrawer };
};

const useUserVisit = () => {
  const [cookies, setCookie] = useCookies([COOKIE_KEY_VISIT_BEFORE]);
  const [hasLoadedCookie, setHasLoadedCookie] = useState(false);
  const [hasUserVisitBefore, setHasUserVisitBefore] = useState(undefined);

  const visitBeforeCookieValue = useMemo(() => guard(() => cookies[COOKIE_KEY_VISIT_BEFORE], false), [cookies]);
  const cookieOptions = useMemo(() => constructCookieOptionsForVisit(), []);

  useEffect(() => {
    if (!hasUserVisitBefore) {
      setCookie(COOKIE_KEY_VISIT_BEFORE, true, cookieOptions);
    }
  }, [hasUserVisitBefore, setCookie, cookieOptions]);

  useEffect(() => {
    if (!hasLoadedCookie) {
      setHasUserVisitBefore(visitBeforeCookieValue);
      setHasLoadedCookie(true);
    }
  }, [hasLoadedCookie, visitBeforeCookieValue]);

  return { hasUserVisitBefore };
};

const BareUserContextProvider = withAppContext(
  withTrackingContext(({ trackAddToCartEvent, trackRemoveRoomFromCartEvent, trackRemoveAllRoomsFromCartEvent, trackViewCartEvent, children }) => {
    const history = useHistory();
    const { search: urlParams } = useLocation();

    const { searchAndFilter, updateSearchAndFilter } = useSearchAndFilter(history, urlParams);
    const { userBooking, addToBooking, updateBookingItem, clearBooking, removeBookingItem } = useUserBooking(
      trackAddToCartEvent,
      trackRemoveRoomFromCartEvent,
      trackRemoveAllRoomsFromCartEvent
    );
    const { isOpen, openDrawer, closeDrawer } = useMyBookingDrawer(trackViewCartEvent, userBooking);
    const { hasUserVisitBefore } = useUserVisit();

    useEffect(() => {
      if (!checkIsObjectEmpty(userBooking) && checkIsDate('before', userBooking.startDate, getTodayMoment())) {
        clearBooking();
      }
    }, [userBooking, clearBooking]);

    return (
      <UserContext.Provider
        value={{
          userBooking,
          addToBooking,
          updateBookingItem,
          removeBookingItem,
          clearBooking,
          searchAndFilter,
          updateSearchAndFilter,
          hasUserVisitBefore,
          drawer: {
            isOpen,
            openDrawer,
            closeDrawer
          }
        }}
      >
        {children}
      </UserContext.Provider>
    );
  })
);

export const UserContextProvider = ({ children }) => {
  return (
    <CookiesProvider>
      <BareUserContextProvider>{children}</BareUserContextProvider>
    </CookiesProvider>
  );
};

export const UserContextConsumer = UserContext.Consumer;

export const withUserContext = Component => {
  const UserContextComponent = props => (
    <UserContextConsumer>{userContextProps => <Component {...userContextProps} {...props} />}</UserContextConsumer>
  );
  return UserContextComponent;
};
