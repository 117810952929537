import api, { BOOKING_ENGINE_ROUTE } from './apiHelper';
import { queryCache } from 'react-query';
import { useCustomQuery } from 'hooks/reactQuery';

const getMemberPath = params => `${BOOKING_ENGINE_ROUTE}/member${!!params ? `/${params}` : ''}`;

/* ---------------------------------- GET ---------------------------------------- */

export const useGetMemberSelf = onError => {
  const getMemberSelf = key => {
    const path = getMemberPath('self');
    return api.get(path);
  };
  return useCustomQuery('member self', [], getMemberSelf, { onError });
};

export const useGetMemberSelfBookings = onError => {
  const getMemberSelfBookings = key => {
    const path = getMemberPath('self/bookings');
    return api.get(path);
  };
  return useCustomQuery('member self bookings', [], getMemberSelfBookings, { onError });
};

export const useGetMemberSelfPackageOrders = ({ onError, shouldFetch } = {}) => {
  const getMemberSelfPackageOrders = () => {
    const path = getMemberPath('self/packageOrders');
    return api.get(path);
  };
  return useCustomQuery('member self package orders', [], getMemberSelfPackageOrders, { onError, enabled: shouldFetch });
};

/* ---------------------------------- PATCH UPDATE ----------------------------------- */
export const patchUpdateMemberSelf = payload => {
  const path = getMemberPath('self');
  return api.patch(path, payload).then(updatedSelf => {
    refetchMemberSelfDetails();
    return updatedSelf;
  });
};

/* ---------------------------------- REFETCH FUNCTION ---------------------------------------- */

const refetchMemberSelfDetails = () => queryCache.invalidateQueries(['member self']);
