import React from 'react';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';

import { NavButton, IconContainer, Text } from './ClickableTextButton.styles';

const ClickableTextButton = ({
  className,
  text,
  url = '',
  onClick = () => {},

  visualVisible = false,
  showIcon = true,
  icon = <LeftOutlined />,

  style
}) => {
  const history = useHistory();
  return (
    <NavButton align="middle" onClick={url ? () => history.push(url) : onClick} className={className} style={style}>
      {showIcon && <IconContainer>{icon}</IconContainer>}
      <Text visualVisible={visualVisible}>{text}</Text>
    </NavButton>
  );
};

export default ClickableTextButton;
