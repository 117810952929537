import React from 'react';
import { Button, Skeleton } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { Card, CardTag, HeaderRow } from './SectionCard.styles';

const SectionCard = ({ children, loading = false, title, buttonIcon, buttonText, onActionButtonClick, cardStyle }) => {
  return (
    <Card style={cardStyle}>
      <HeaderRow justify="space-between" align="middle">
        <CardTag>{title}</CardTag>
        {onActionButtonClick && (
          <Button type="primary" icon={buttonIcon || <PlusOutlined />} onClick={onActionButtonClick}>
            {buttonText}
          </Button>
        )}
      </HeaderRow>
      <Skeleton loading={loading} active>
        {children}
      </Skeleton>
    </Card>
  );
};

export default SectionCard;
