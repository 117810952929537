import styled from '@emotion/styled';
import MdecBannerBg from 'images/landing-mdec-banner.png';

const LAYOUT_TYPE_ACCOUNT = 'memberAccountLayout';

export const MdecBannerBackground = styled.div`
  background-image: url(${MdecBannerBg});
  background-size: cover;
  background-position-x: center;
  padding: 2rem 0;
`;

export const StyledLogo = styled.img`
  width: 125px;

  @media (min-width: 1441px) {
    width: 240px;
  }
`;

export const StyledBannerTitle = styled.h1`
  font-weight: 700;
  font-size: 1.5rem;

  @media (min-width: 1441px) {
    font-size: 32px;
  }
`;

export const StyledBody = styled.div`
  width: 100%;
  padding: 0 ${props => (props.type === LAYOUT_TYPE_ACCOUNT ? '0px' : props.theme.spacing.sm)};

  @media (min-width: 768px) {
    width: ${props => (props.type === LAYOUT_TYPE_ACCOUNT ? '100%' : '80%')};
    margin: 0 auto;
  }
`;
