/* eslint-disable no-console */
// This is mainly for logging, so it is safe to disable no-console rule
const logLevels = {
  TRACE: 0,
  DEBUG: 1,
  INFO: 2,
  WARNING: 3,
  ERROR: 4,
  FATAL: 5
};

export const log = (message, level = logLevels.INFO) => {
  // TODO: Setup this properly
  if (level === logLevels.TRACE) {
    return;
  }

  if (message instanceof Error) {
    // For stack trace
    console.debug(message.stack);
    message = message.message;
  }

  message = JSON.stringify(message);

  if (level >= logLevels.ERROR) {
    console.error(message);
  } else if (level >= logLevels.WARNING) {
    console.warn(message);
  } else {
    console.log(message);
  }
};

export const logTrace = message => log(message, logLevels.TRACE);
export const logDebug = message => log(message, logLevels.DEBUG);
export const logInfo = message => log(message, logLevels.INFO);
export const logWarning = message => log(message, logLevels.WARNING);
export const logError = message => log(message, logLevels.ERROR);
export const logFatal = message => log(message, logLevels.FATAL);
