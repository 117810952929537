import React from 'react';

import { Text } from './Subtitle.styles';

const Subtitle = ({ children, type = 'default', newLine = true }) => {
  return (
    <Text type={type} newLine={newLine}>
      {children}
    </Text>
  );
};

export default Subtitle;
