/* ----------------------------constants---------------------------- */
export const CONTACT_NUMBER_REGEX = /^[+0-9][-\s0-9]*$/;
export const EMAIL_REGEX = /^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$/;
export const NRIC_REGEX = /^[0-9]{12}$/;
export const PASSWORD_REGEX = /^(?=.*[a-zA-Z])(?=.*[0-9]).{8,20}$/;

/* ----------------------------functions---------------------------- */
export const checkIsValidUrl = url => /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/.test(url);

export const checkIsUrlStartWithProtocol = url => /^https?:\/\//.test(url);
