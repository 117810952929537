import styled from '@emotion/styled';

import Font from '../Font';

const fontSizeForTitle = {
  xxs: '16px',
  xs: '20px',
  s: '24px',
  default: '32px',
  md: '40px'
};

const fontColorForTitle = {
  primary: '#17B6CC',
  secondary: '#FFCE38',
  default: '#05171D'
};

const fontWeightForTitle = {
  bold: 600,
  default: 700
};

export const Text = styled(Font)`
  color: ${props => fontColorForTitle[`${props.color}`]};
  font-size: ${props => fontSizeForTitle[`${props.type}`]};
  font-weight: ${props => fontWeightForTitle[`${props.weight}`]};
`;
