import api from './apiHelper';
import { useCustomQuery } from 'hooks/reactQuery';

const PROPERTY_ROUTE = '/property';

export const useGetPropertyListingsByQuery = (query = {}) => {
  const getPropertyListingByQuery = (key, query) => {
    return api.get(`${PROPERTY_ROUTE}/listing`, { params: { ...query, isOwnedByHas: true } });
  };

  return useCustomQuery('property', [query], getPropertyListingByQuery, {});
};

export const useGetPropertyById = (propertyId, query = {}, onError) => {
  const getPropertyById = (key, propertyId, query) => {
    return api.get(`${PROPERTY_ROUTE}/${propertyId}`, { params: { ...query } });
  };

  return useCustomQuery('property', [propertyId, query], getPropertyById, { onError, shouldFetch: !!propertyId });
};

export const useGetStateLists = () => {
  let result = new Promise((resolve, reject) => resolve());

  result.data = {
    stateLists: [
      {
        code: 16,
        label: 'Kuala Lumpur',
        image: 'https://images.unsplash.com/photo-1562060726-e47264af32bd?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1901&q=80',
        propertiesCount: 3
      },
      {
        code: 13,
        label: 'Langkawi',
        image:
          'https://images.unsplash.com/photo-1583316369024-8a4eb52ec261?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80',
        propertiesCount: 2
      },
      {
        code: 11,
        label: 'Penang',
        image: 'https://images.unsplash.com/photo-1585644198827-193583b91c36?ixlib=rb-1.2.1&auto=format&fit=crop&w=1868&q=80',
        propertiesCount: 4
      }
    ]
  };

  return result;
};
