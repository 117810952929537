import axios from 'axios';
import env from 'config/env';
import { getIsMemberLoggedIn, getAuthToken } from 'utils/auth';

const FIELDS_SEPERATOR = ',';
export const BOOKING_ENGINE_ROUTE = '/booking-engine';

const apiErrorHandler = e => {
  if (e.response && e.response.data) {
    if (e.response.data.code) {
      throw e.response.data;
    } else if (e.response.data.message) {
      throw new Error(e.response.data.message);
    } else {
      throw new Error(e.response.data);
    }
  } else if (e.message) {
    throw e;
  } else {
    throw new Error('Unexpected error occured when calling api. Please contact admin.');
  }
};

const constructAndMutateOptions = params => {
  // fields
  if (!!params && !!params.fields) {
    params._fields = params.fields;
    delete params.fields;
  }
};

const mutateParamsArrayToString = params => {
  if (!!params) {
    Object.entries(params).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        const valueInString = value.join(FIELDS_SEPERATOR);
        params[key] = valueInString;
      }
    });
  }
};

const getAuthHeader = () => {
  if (getIsMemberLoggedIn()) {
    return {
      Authorization: getAuthToken()
    };
  }
  return {};
};

export const updateApiHeaders = () => {
  api.defaults.headers = {
    'Content-Type': 'application/json',
    ...getAuthHeader()
  };
};

const api = axios.create({
  baseURL: env.REACT_APP_API_ENDPOINT,
  headers: {
    'Content-Type': 'application/json',
    ...getAuthHeader()
  }
});

api.interceptors.request.use(req => {
  constructAndMutateOptions(req.params);
  mutateParamsArrayToString(req.params);
  return req;
});
api.interceptors.response.use(res => res.data, apiErrorHandler);

export default api;

export const nonAuthApi = axios.create({
  baseURL: env.REACT_APP_API_ENDPOINT,
  headers: {
    'Content-Type': 'application/json'
  }
});

nonAuthApi.interceptors.response.use(res => res.data, apiErrorHandler);
