import { Col } from 'antd';
import React from 'react';
import { StyledSectionTitle, StyledSecondaryTitle } from './SectionTitle.styles';

const SectionTitle = ({ primary, secondary, paragraph, color }) => {
  return (
    <Col>
      <StyledSectionTitle>
        {' '}
        {primary} <span> {secondary} </span>
      </StyledSectionTitle>

      <StyledSecondaryTitle color={color}>{paragraph}</StyledSecondaryTitle>
    </Col>
  );
};

export default SectionTitle;
