import styled from '@emotion/styled';
import { Alert, Row, Button } from 'antd';
import ClickableTextButton from 'components/ClickableTextButton/ClickableTextButton';

import HostAStayBG from 'images/background.svg';

export const ForgetPasswordButton = styled(ClickableTextButton)`
  font-size: 12px;
  margin-bottom: 0px;
`;

export const TitleRow = styled(Row)`
  margin-bottom: ${props => props.theme.spacing.regular};
`;

export const TitleLabel = styled.label`
  font-size: 28px;
`;

export const LoginButton = styled(Button)`
  width: 100%;
`;

export const Backdrop = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-image: url(${HostAStayBG});
  border-radius: 4px 0px 0px 4px;
  font-size: 30px;
  color: ${props => props.theme.color.white};
  font-weight: ${props => props.theme.fontWeight.bold};
  align-items: center;
  text-align: center;

  padding: 24px 0;

  @media (min-width: 768px) {
    padding: 0;
  }
`;

export const RegisterButton = styled(ClickableTextButton)`
  height: auto;
  margin-left: 5px;
  font-size: 12px;
`;

export const ErrorMessage = styled(Alert)`
  color: #f5222d;
  margin-bottom: 10px;
`;
