import React from 'react';
import PropTypes from 'prop-types';
import { Input as AntdInput } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

import { MaxLengthDisplay } from './Input.styles';

const TYPE_INPUT = 'input';
const TYPE_TEXTAREA = 'textarea';
const TYPE_PASSWORD = 'password';

const generateInputComponent = type => {
  const defaultInput = AntdInput;
  const supportedInput = {
    [TYPE_INPUT]: AntdInput,
    [TYPE_PASSWORD]: AntdInput.Password,
    [TYPE_TEXTAREA]: AntdInput.TextArea
  };
  return supportedInput[type] || defaultInput;
};

const generateExtraInputPropsFromType = type => {
  const typeToProps = {
    [TYPE_INPUT]: {},
    [TYPE_PASSWORD]: { iconRender: visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />) },
    [TYPE_TEXTAREA]: { rows: 4 }
  };
  const typeProps = typeToProps[type] || {};

  return {
    ...typeProps
  };
};

const Input = ({
  type,
  placeholder,
  prefix,
  autoComplete,
  maxLength,
  size,
  value,

  isDisabled,

  onChange,
  updateFormValue,

  ...props
}) => {
  const handleOnChange = e => {
    const value = e.target.value;
    onChange(value);
    updateFormValue(value);
  };

  const extraProps = generateExtraInputPropsFromType(type);
  const InputComponent = generateInputComponent(type);

  return (
    <>
      {!isNaN(maxLength) && (
        <MaxLengthDisplay>
          Max {maxLength} letters, {maxLength - String(value || '').length} letters left
        </MaxLengthDisplay>
      )}
      <InputComponent
        type={type}
        value={value}
        autoComplete={autoComplete}
        placeholder={placeholder}
        prefix={prefix}
        disabled={isDisabled}
        maxLength={maxLength}
        size={size}
        onChange={handleOnChange}
        {...props}
        {...extraProps}
      />
    </>
  );
};

Input.propTypes = {
  type: PropTypes.string,
  placeholder: PropTypes.string,
  prefix: PropTypes.node,
  autoComplete: PropTypes.string,
  isDisabled: PropTypes.bool,
  maxLength: PropTypes.number,
  size: PropTypes.oneOf(['large', 'middle', 'small']),
  onChange: PropTypes.func,
  updateFormValue: PropTypes.func
};

Input.defaultProps = {
  type: undefined,
  placeholder: '',
  prefix: undefined,
  autoComplete: undefined,
  isDisabled: false,
  size: 'middle',
  onChange: () => {},
  updateFormValue: () => {}
};

export default Input;
