import React from 'react';

import { Text } from './TextSecondary.styles';

const TextSecondary = ({ children, type = 'default', newLine = false }) => {
  return (
    <Text type={type} newLine={newLine}>
      {children}
    </Text>
  );
};

export default TextSecondary;
