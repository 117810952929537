import styled from '@emotion/styled';
import { Col, Row } from 'antd';

export const RootContainer = styled(Row)`
  background-color: ${props => props.theme.color.white};
  box-shadow: 0 1px 2px 0 #00000012, 0 2px 2px 0 #0000001a;
  margin-bottom: ${props => props.theme.spacing.sm};
  width: 100%;
`;

export const CoverImageCol = styled(Col)`
  position: relative;
`;

export const CardTag = styled.div`
  background-color: ${props => props.theme.color.secondary};
  border-radius: 0 4px 4px 0;
  color: ${props => props.theme.color.primary};
  font-size: 12px;
  font-weight: ${props => props.theme.fontWeight.regular};
  padding: ${props => props.theme.spacing.xs} ${props => props.theme.spacing.regular};
  width: fit-content;
  position: absolute;
  top: ${props => props.theme.spacing.regular};
`;

export const CoverImageContainer = styled.div`
  height: 196px;

  @media (min-width: 992px) {
    height: 100%;
    max-height: ${props => props.imageMaxHeight};
  }
`;

export const ContentContainer = styled(Row)`
  padding: ${props => `${props.theme.spacing.regular} ${props.theme.spacing.sm}`};
`;

export const HostLogoContainer = styled(Col)`
  display: flex;
  justify-content: flex-end;
`;

export const FooterContainer = styled(Col)`
  border-top: 1px solid #d2d2d2;
  padding: 12px 0;
`;
