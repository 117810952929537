import styled from '@emotion/styled';
import { Col, Empty, Row, Skeleton } from 'antd';
import { Link } from 'react-router-dom';

export const StyledHeader = styled(Skeleton)`
  height: 35%;
`;

export const StyledBody = styled(Skeleton)`
  height: 65%;
`;

export const BodyContainer = styled(Row)``;

export const BannersRootContainer = styled(Row)`
  height: auto;
  padding-top: 32px !important;
  margin-bottom: 0px !important;
  display: flex;

  @media (min-width: 992px) {
    height: 100%;
  }
`;

export const BannerContainer = styled(Row)`
  position: relative;
  box-shadow: 2px 4px 16px #e0e0e0;
  height: 100%;
`;

export const CoverImageCol = styled(Col)``;

export const CoverImageContainer = styled.div`
  height: 100%;
  max-height: 160px;

  @media (min-width: 992px) {
    max-height: 160px;
  }
`;

const bannerTextPaddingBase = '24px';

export const BannerTextCol = styled(Col)`
  padding: 32px ${bannerTextPaddingBase} ${bannerTextPaddingBase};

  @media (min-width: 768px) {
    padding: ${bannerTextPaddingBase} ${bannerTextPaddingBase} 16px 32px;
  }

  @media (min-width: 992px) {
    padding: ${bannerTextPaddingBase} ${bannerTextPaddingBase} ${bannerTextPaddingBase} 32px;
  }
`;

export const BannerCtaLink = styled(Link)`
  text-decoration: underline;
  font-weight: ${props => props.theme.fontWeight.bold};
`;

export const BannerCtaExternalLink = styled.a`
  text-decoration: underline !important;
  font-weight: ${props => props.theme.fontWeight.bold};
`;

export const ListingMainTitle = styled(Row)`
  margin: 40px 0px 16px;
`;

export const ListingCardFooterCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
`;

export const StyledEmpty = styled(Empty)`
  margin-top: 80px;
`;
