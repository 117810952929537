import { nonAuthApi, BOOKING_ENGINE_ROUTE } from './apiHelper';

const AUTH_ROUTE = `${BOOKING_ENGINE_ROUTE}/auth`;

export const postMemberLogin = (username, password) => nonAuthApi.post(`${AUTH_ROUTE}/login`, { username, password });

export const postSignUp = payload => nonAuthApi.post(`${AUTH_ROUTE}/sign-up`, payload);

export const postForgetPassword = email => nonAuthApi.post(`${AUTH_ROUTE}/forget-password`, { email });

export const postResetPassword = (token, password) => nonAuthApi.post(`${AUTH_ROUTE}/reset-password/${token}`, { password });

export const postActivateMemberAccount = (token, password) => nonAuthApi.post(`${AUTH_ROUTE}/member/activate`, { firstTimeToken: token, password });
