import React from 'react';
import { Button, Col } from 'antd';

import ClickableTextButton from 'components/ClickableTextButton/ClickableTextButton';
import TextSecondary from 'components/Font/TextSecondary/TextSecondary';
import Title from 'components/Font/Title/Title';
import Subtitle from 'components/Font/Subtitle/Subtitle';
import Image from 'components/Image/BareImage/BareImage';

import { buildBaseUri } from 'utils/routes';

import BannerImg from './images/banner-image.png';
import { BannerContainer, TextRow, CoverImageCol, BannerTextCol } from './Banner.styles';

const Banner = () => {
  return (
    <BannerContainer justify="space-around">
      <Col span={24}>
        <ClickableTextButton text="View Other Stays Options" url={buildBaseUri()} />
        <br />
      </Col>
      <BannerTextCol span={24} md={12} lg={10}>
        <TextRow gutter={[0, 6]} justify="space-around">
          <Col span={24}>
            <Title>IT’S TIME FOR A HOLISTAY!</Title>
          </Col>
          <Col span={24} style={{ marginTop: '16px' }}>
            <Subtitle>What is a Holistay?</Subtitle>
          </Col>
          <Col span={24}>
            <TextSecondary>
              Basically, it means a holiday spent at home or a stay-at-home holiday.
              <br /> Are you stressed or tired of your own home? Make HostAStay your home today!
              <br /> <b>#Supportlocal</b>
            </TextSecondary>
          </Col>
          <Col span={24} style={{ marginTop: '16px' }}>
            <Button type="primary">
              <a href="#searchBarSection">Book rooms</a>
            </Button>
          </Col>
        </TextRow>
      </BannerTextCol>
      <CoverImageCol span={24} md={12} lg={14}>
        <Image image={BannerImg} alt="HoliStay" />
      </CoverImageCol>
    </BannerContainer>
  );
};

export default Banner;
