import React from 'react';
import { Col, Row } from 'antd';
import { Switch, useHistory, useLocation } from 'react-router-dom';

import { withAppContext } from 'contexts/AppContext/AppContext';

// import ClickableTextButton from 'components/ClickableTextButton/ClickableTextButton';
import Title from 'components/Font/Title/Title';

import { InnerRoute, buildBaseUri, buildMyAccountDetailsUri, buildMyAccountPackagesUri, buildMyAccountStaysUri } from 'utils/routes';

import NavBar from './components/NavBar/NavBar';
import Profile from './Profile/Profile';
import Stays from './Stays/Stays';
import Packages from './Packages/Packages';
import {
  Root,
  MainContainer,
  MemberAccPageLayout,
  MemberAccPageLayoutHeader,
  MemberAccPageLayoutSider,
  MemberAccPageLayoutContent,
  TitleContainer
} from './MyAccount.styles';

const getCurrentPageTitle = pathname => {
  const titleObj = {
    [`${buildMyAccountDetailsUri()}`]: 'My Profile Details',
    [`${buildMyAccountPackagesUri()}`]: 'My Packages',
    [`${buildMyAccountStaysUri()}`]: 'My Stays'
  };
  return titleObj[pathname];
};

const Content = ({ profileDetails }) => (
  <MemberAccPageLayoutContent>
    <Switch>
      <InnerRoute title="My Profile Details" exact path={buildMyAccountDetailsUri()}>
        <Profile memberData={profileDetails} />
      </InnerRoute>
      <InnerRoute title="My Stays" exact path={buildMyAccountStaysUri()}>
        <Stays />
      </InnerRoute>
      <InnerRoute title="My Packages" exact path={buildMyAccountPackagesUri()}>
        <Packages />
      </InnerRoute>
    </Switch>
  </MemberAccPageLayoutContent>
);

const MyAccount = ({ isMemberLoggedIn, member }) => {
  const history = useHistory();
  const location = useLocation();
  if (!isMemberLoggedIn) {
    history.push(`${buildBaseUri()}`);
  }

  return (
    <Root>
      <Row>
        {/* Note: Remember to add to both mobile view and desktop view for any changes */}
        <Col span={0} sm={24}>
          <MainContainer>
            <TitleContainer>
              <Title>{getCurrentPageTitle(location.pathname)}</Title>
            </TitleContainer>
            <MemberAccPageLayout>
              <MemberAccPageLayoutSider width="240px">
                <NavBar />
              </MemberAccPageLayoutSider>
              <Content profileDetails={member} />
            </MemberAccPageLayout>
          </MainContainer>
        </Col>
        <Col span={24} sm={0}>
          <MainContainer>
            <TitleContainer>
              <Title type="s">{getCurrentPageTitle(location.pathname)}</Title>
            </TitleContainer>
            <MemberAccPageLayout>
              <MemberAccPageLayoutHeader>
                <NavBar mode="horizontal" />
              </MemberAccPageLayoutHeader>
              <Content profileDetails={member} />
            </MemberAccPageLayout>
          </MainContainer>
        </Col>
      </Row>
    </Root>
  );
};

export default withAppContext(MyAccount);
