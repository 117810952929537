import React from 'react';

const Font = ({ className, children, type = 'default', newLine }) => {
  if (newLine) {
    return (
      <div className={className} type={type}>
        {children}
      </div>
    );
  } else {
    return (
      <span className={className} type={type}>
        {children}
      </span>
    );
  }
};

export default Font;
